import { __rest } from "tslib";
import moment from 'moment';
import * as React from 'react';
import DefaultTimePicker from './TimePicker';
const TimePicker = (props) => {
    const { input: { value, onChange }, className } = props, restProps = __rest(props, ["input", "className"]);
    const momentValue = value ? moment(value) : null;
    return (React.createElement(DefaultTimePicker, Object.assign({}, restProps, { value: momentValue, onChange: onChange, className: className })));
};
export default TimePicker;
