class EventStack {
    constructor() {
        this.stack = {};
        this.add = (target, type, listener) => {
            // if no events are there for current target, create a new namespace for target
            if (!(target in this.stack)) {
                this.stack[target] = {};
            }
            // create new stack if there is not any
            if (!(type in this.stack[target])) {
                this.stack[target][type] = [];
            }
            // remove previous stored listener
            if (this.stack[target][type].length > 0) {
                target.removeEventListener(type, this.stack[target][type][this.stack[target][type].length - 1]);
            }
            // add another event to the top of stack
            this.stack[target][type].push(listener);
            // add listener for this event
            target.addEventListener(type, listener);
        };
        this.remove = (target, type, listener) => {
            if (target in this.stack) {
                if (type in this.stack[target]) {
                    const count = this.stack[target][type].length;
                    this.stack[target][type] = this.stack[target][type].reduce((acc, storedListener, idx) => {
                        if (storedListener !== listener) {
                            acc.push(storedListener);
                        }
                        else if (idx === count - 1) {
                            // is same listener and is last
                            target.removeEventListener(type, storedListener);
                            // add listener to topmost stored listener
                            if (acc.length > 0) {
                                target.addEventListener(type, acc[acc.length - 1]);
                            }
                        }
                        return acc;
                    }, []);
                }
            }
        };
    }
}
export default new EventStack();
