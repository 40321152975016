import * as React from 'react';
import Button from '../../Button';
import { __ } from '../../utilities';
import styled from '../../Themes/styled-components';
const Wrapper = styled.div `
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  > * {
    width: 100%;
    margin: 4px;
  }
`;
class AspectRatioPicker extends React.PureComponent {
    constructor() {
        super(...arguments);
        this.renderOne = (aspectRatio, idx) => {
            const { value, onChange } = this.props;
            return (React.createElement(Button, { key: idx, primary: true, block: true, size: "xs", basic: value !== aspectRatio, onClick: () => onChange(aspectRatio) }, aspectRatio === null ? __('Voľný') : aspectRatio));
        };
    }
    render() {
        const { options } = this.props;
        return React.createElement(Wrapper, null, options.map(this.renderOne));
    }
}
export default AspectRatioPicker;
