import { rem } from 'polished';
import * as React from 'react';
import Icon from '../Icon';
import Loader from '../Loader';
import styled, { css, withTheme } from '../Themes/styled-components';
const Wrapper = styled.div `
  display: flex;
  border-top-width: 0;
  user-select: none;
`;
const IconWrapper = styled.div `
  position: relative;
  padding: ${rem(10)} ${rem(20)};
  height: ${rem(40)};
  font-size: ${rem(12)};
  color: ${({ theme }) => theme.paginator.textColor};
  background: ${({ theme }) => theme.paginator.backgroundColor};
  display: flex;
  justify-content: center;
  align-items: center;
  border: ${rem(1)} solid ${({ theme }) => theme.paginator.borderColor};
  :not(:last-child) {
    border-right-width: 0;
  }
  &:first-child {
    border-radius: ${rem(5)} 0 0 ${rem(5)};
  }
  &:nth-child(3) {
    min-width: ${rem(70)};
  }
  &:last-child {
    border-radius: 0 ${rem(5)} ${rem(5)} 0;
  }
`;
const Button = styled(IconWrapper) `
  padding: ${rem(10)};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  ${({ disabled, theme }) => {
    if (!disabled) {
        return css `
        :hover {
          background: ${theme.paginator.hoverBackgroundColor};
        }
      `;
    }
    return css ``;
}}
`;
const LoaderWrapper = styled('div') `
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.paginator.backgroundColor};
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translate(-50%, -50%);
`;
export default withTheme(React.memo(({ loading, offset, nextOffset, limit, total, onChangeOffset, theme, }) => {
    const page = total === 0 ? 0 : Math.ceil((offset + 1) / limit);
    let totalPages = 0;
    if (total) {
        totalPages = Math.ceil(total / limit);
    }
    const isAtTheBeggining = page === 1;
    const isAtTheEnd = (total && page >= totalPages) || (!total && !nextOffset);
    return (React.createElement(Wrapper, null,
        React.createElement(Button, { disabled: isAtTheBeggining, onClick: () => {
                if (!isAtTheBeggining) {
                    onChangeOffset(0);
                }
            }, "data-testid": "Paginator-Arrow-left-to-end" },
            React.createElement(Icon, { name: "arrow-left-to-end", color: isAtTheBeggining
                    ? theme.paginator.disabledColor
                    : theme.paginator.activeColor })),
        React.createElement(Button, { disabled: isAtTheBeggining, onClick: () => {
                if (!isAtTheBeggining) {
                    onChangeOffset(offset - limit > 0 ? offset - limit : 0);
                }
            }, "data-testid": "Paginator-arrow-left" },
            React.createElement(Icon, { name: "arrow-left", color: isAtTheBeggining
                    ? theme.paginator.disabledColor
                    : theme.paginator.activeColor })),
        React.createElement(IconWrapper, { "data-testid": "Paginator-Counter" },
            !!loading && (React.createElement(LoaderWrapper, null,
                React.createElement(Loader, { color: theme.paginator.activeColor }))),
            total ? `${page} z ${totalPages}` : page),
        React.createElement(Button, { disabled: isAtTheEnd, onClick: () => {
                if (!isAtTheEnd) {
                    onChangeOffset(page * limit);
                }
            }, "data-testid": "Paginator-arrow-right" },
            React.createElement(Icon, { name: "arrow-right", color: isAtTheEnd
                    ? theme.paginator.disabledColor
                    : theme.paginator.activeColor })),
        !!total && (React.createElement(Button, { disabled: isAtTheEnd, onClick: () => {
                if (!isAtTheEnd) {
                    onChangeOffset((totalPages - 1) * limit);
                }
            }, "data-testid": "Paginator-arrow-right-to-end" },
            React.createElement(Icon, { name: "arrow-right-to-end", color: isAtTheEnd
                    ? theme.paginator.disabledColor
                    : theme.paginator.activeColor })))));
}));
