import FormFieldRedux from '../../ui/FormField/redux-form';
import Button from '../../ui/Button';
import FormGroup from '../../ui/FormGroup';
import Col, { Row } from '../../ui/Grid';
import Input from '../../ui/Input/redux-form';
import Label from '../../ui/Label/Label';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Field, Form, isSubmitting, reduxForm } from 'redux-form';
import { __ } from '../../utilities';
import { isRequired } from '../../validation';

const FORM_NAME = 'LOGIN_FORM';

class LoginForm extends React.PureComponent {
  render() {
    const { handleSubmit, submitting } = this.props;
    return (
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col xs={12}>
            <Field
              component={FormFieldRedux}
              label={__('Emailová adresa')}
              required
              type="input"
              validate={[isRequired]}
              name="username"
            />
          </Col>
          <Col xs={12}>
            <FormGroup>
              <Label>{__('Heslo')}</Label>
              <div>
                <Field
                  component={Input}
                  type="password"
                  required
                  validate={[isRequired]}
                  name="password"
                />
              </div>
            </FormGroup>
          </Col>
          <Col xs={12}>
            <Button block primary loading={submitting}>
              {__('Prihlásiť sa')}
            </Button>
          </Col>
        </Row>
      </Form>
    );
  }
}

LoginForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
};

const connected = reduxForm({
  form: FORM_NAME,
  enableReinitialize: true,
})(LoginForm);

const mapStateToProps = (state) => ({
  submitting: isSubmitting(FORM_NAME)(state),
});

export default connect(mapStateToProps)(connected);
