import { __awaiter } from "tslib";
import * as React from 'react';
import config from '../config';
import { getMediaManagerUrl } from '../MediaManagerImage';
import PhotoEditor from '../PhotoEditor';
import { deNormalizePhotoDimensions, MAX_SIZE, normalizePhotoDimensions, } from './utils';
class MediaManagerPhotoEditor extends React.PureComponent {
    constructor(props) {
        super(props);
        this.handleSubmit = data => {
            const { naturalWidth, naturalHeight } = this.state;
            this.props.onSubmit(normalizePhotoDimensions(data, naturalWidth, naturalHeight));
        };
        this.state = {
            naturalWidth: null,
            naturalHeight: null,
        };
        this.cropper = null;
    }
    componentDidMount() {
        return __awaiter(this, void 0, void 0, function* () {
            let imageUrl = `${config.MEDIAMANAGER_URL}/media/${this.props.picture.uri}`;
            if (this.props.picture.public_url) {
                imageUrl = this.props.picture.public_url;
            }
            // @TODO otestovat chybovy stav
            yield this.loadImageDimensions(imageUrl);
        });
    }
    loadImageDimensions(imageUri) {
        return __awaiter(this, void 0, void 0, function* () {
            const img = new Image();
            img.src = imageUri;
            return new Promise(resolve => {
                img.addEventListener('load', (e) => {
                    const { target: { naturalHeight, naturalWidth }, } = e;
                    this.setState({ naturalHeight, naturalWidth }, () => {
                        resolve(null);
                    });
                });
            });
        });
    }
    render() {
        const { onClose, picture: { dimensions }, picture, availableAspectRatios, mask, } = this.props;
        const { naturalWidth, naturalHeight } = this.state;
        const pictureWithoutDimensions = Object.assign({}, picture);
        delete pictureWithoutDimensions['dimensions'];
        const deNormalizedDimensions = (() => {
            if (dimensions) {
                return deNormalizePhotoDimensions({
                    x: dimensions.X,
                    y: dimensions.Y,
                    width: dimensions.W,
                    height: dimensions.H,
                }, naturalWidth, naturalHeight);
            }
            return void 0;
        })();
        if (dimensions && !naturalWidth && !naturalHeight) {
            // deNormalizedDimensions are not yet available
            return null;
        }
        return (React.createElement(PhotoEditor, { src: getMediaManagerUrl(pictureWithoutDimensions, MAX_SIZE, MAX_SIZE, 'resize'), onClose: onClose, onSubmit: this.handleSubmit, viewMode: 1, data: deNormalizedDimensions, availableAspectRatios: availableAspectRatios, mask: mask }));
    }
}
MediaManagerPhotoEditor.defaultProps = {
    availableAspectRatios: ['2:1', '4:3', '16:9', '1:1', null],
};
export default MediaManagerPhotoEditor;
