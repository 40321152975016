import { __rest } from "tslib";
import moment from 'moment';
import * as React from 'react';
import DefaultDateTimePicker from './DateTimePicker';
const DateTimePicker = (props) => {
    const { input: { value, onChange } } = props, restProps = __rest(props, ["input"]);
    const momentValue = value ? moment(value) : null;
    return (React.createElement(DefaultDateTimePicker, Object.assign({}, restProps, { value: momentValue, onChange: onChange })));
};
export default DateTimePicker;
