import { em, rem } from 'polished';
import * as React from 'react';
import Button from '../Button';
import Input from '../Input';
import InputButton from '../Input/Button';
import Modal, { ModalActions } from '../Modal';
import styled from '../Themes/styled-components';
import { __ } from '../utilities';
import Option from './Options/Option';
import { containsExactMatch, getDefaultCreateOption, isOptionSelected, } from './utils';
const Options = styled('div') `
  font-size: ${em(18)};
  flex: 1;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  display: flex;
  flex-direction: column;
`;
const LargeInput = styled(Input) `
  font-size: 18px;
  height: ${rem(50)};
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border: 0 !important;
  border-bottom: 1px solid ${({ theme }) => theme.separatorColor} !important;
  & ~ button {
    border: 0 !important;
    border-radius: 0 !important;
    border-bottom: 1px solid ${({ theme }) => theme.separatorColor} !important;
  }
`;
const DisabledOption = styled('div') `
  display: flex;
  flex-direction: column;
  flex: 1 0 0;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-style: italic;
  background-color: ${({ theme }) => theme.separatorColor};
  color: ${({ theme }) => theme.inactiveColor};
`;
export default function (props) {
    const { inputValue, onChangeInput, opened, onClose, multiple, value, loading, onSelectOption, createNewOption, onChange, options, renderLabel, } = props;
    const [localValue, setLocalValue] = React.useState(value);
    const inputEl = React.useRef(null);
    React.useEffect(() => {
        if (opened && inputEl && inputEl.current) {
            inputEl.current.focus();
        }
    }, [opened]);
    React.useEffect(() => {
        setLocalValue(value);
    }, [value, opened]);
    const defaultCreateOption = React.useMemo(() => getDefaultCreateOption(inputValue), [inputValue]);
    function handleSelectOption(selectedOption) {
        if (multiple) {
            if (Array.isArray(localValue)) {
                const idx = localValue.findIndex(val => {
                    return val.value === selectedOption.value;
                });
                if (idx === -1) {
                    setLocalValue([...localValue, selectedOption]);
                }
                else {
                    setLocalValue(localValue.filter(v => v.value !== selectedOption.value));
                }
            }
        }
        else {
            onSelectOption(selectedOption);
            onClose();
        }
    }
    function renderOptions() {
        if (options.length > 0) {
            const allOptions = options.map((option, idx) => (React.createElement(Option, { key: idx, option: option, onSelectOption: handleSelectOption, useCheckbox: multiple, selected: isOptionSelected(option, localValue), renderLabel: renderLabel, idx: idx })));
            if (createNewOption &&
                inputValue &&
                !containsExactMatch(options, inputValue)) {
                const idx = options.length;
                allOptions.push(React.createElement(Option, { key: idx, idx: idx, option: defaultCreateOption, onSelectOption: (selectedOption) => {
                        if (multiple && Array.isArray(localValue)) {
                            const newOption = createNewOption(inputValue);
                            onChangeInput('');
                            setLocalValue([...localValue, newOption]);
                        }
                        else {
                            onSelectOption(selectedOption);
                            onClose();
                        }
                    } }));
            }
            return allOptions;
        }
        if (loading) {
            return (React.createElement(DisabledOption, null,
                __('Moment,'),
                React.createElement("br", null),
                __('načítavajú sa možnosti ...')));
        }
        return React.createElement(DisabledOption, null, __('Žiadne možnosti'));
    }
    return (React.createElement(Modal, { isOpen: opened, handleClose: onClose, size: "full" },
        React.createElement(LargeInput, { inputRef: inputEl, value: inputValue, onChange: (e) => {
                onChangeInput(e.target.value);
            }, autoComplete: "off" },
            React.createElement("input", null),
            inputValue ? (React.createElement(InputButton, { icon: "backspace", onClick: () => {
                    onChangeInput('');
                } })) : (React.createElement(InputButton, { icon: "close", onClick: onClose }))),
        React.createElement(Options, null, renderOptions()),
        React.createElement(ModalActions, null,
            React.createElement(Button, { onClick: onClose }, __('Zrušiť')),
            multiple && (React.createElement(Button, { primary: true, onClick: () => {
                    onChange(localValue);
                    onClose();
                } }, __('Potvrdiť'))))));
}
