import { __rest } from "tslib";
import * as PropTypes from 'prop-types';
import * as React from 'react';
import DefaultInput from './index';
import { smarttagsPropType } from '../InputSmarttag/propTypes';
const SmarttagControl = (props) => {
    const { input: { value, onChange }, id, availableSmarttags, className, meta: { touched, error }, getSmarttagsKeys, getSmarttagsValues, readOnly } = props, restProps = __rest(props, ["input", "id", "availableSmarttags", "className", "meta", "getSmarttagsKeys", "getSmarttagsValues", "readOnly"]);
    const arrayValue = Array.isArray(value) ? value : [];
    return (React.createElement(DefaultInput, Object.assign({ onChange: onChange, value: arrayValue, id: id, availableSmarttags: availableSmarttags, className: className, error: error && touched, getSmarttagsKeys: getSmarttagsKeys, getSmarttagsValues: getSmarttagsValues, readOnly: readOnly }, restProps)));
};
SmarttagControl.propTypes = {
    availableSmarttags: smarttagsPropType,
    className: PropTypes.string,
    id: PropTypes.string,
    input: PropTypes.shape({
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
        onChange: PropTypes.func.isRequired,
    }).isRequired,
    meta: PropTypes.shape({
        touched: PropTypes.bool.isRequired,
        error: PropTypes.string,
    }).isRequired,
    getSmarttagsKeys: PropTypes.func,
    getSmarttagsValues: PropTypes.func,
    readOnly: PropTypes.bool,
};
SmarttagControl.defaultProps = {
    availableSmarttags: [],
    className: '',
    id: null,
    getSmarttagsKeys: null,
    getSmarttagsValues: null,
    readOnly: false,
};
export default SmarttagControl;
