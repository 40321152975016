import { rem } from 'polished';
import PropTypes from 'prop-types';
import React from 'react';
import styled from '../Themes/styled-components';
export const CONTEXT_BAR_HEIGHT = 56;
const ItemWrapper = styled.div `
  height: 100%;
  margin: 0 ${rem(4)};
  display: flex;
  align-items: center;
  flex-shrink: 0;
`;
const Wrapper = styled.div `
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  height: ${({ theme }) => rem(theme.contextBar.height)};
  background-color: ${({ theme }) => theme.contextBar.backgroundColor};
  padding: ${rem(8)};
  display: flex;
  align-items: center;
  box-shadow: 0 -1px 2px rgba(0, 0, 0, 0.08);
  color: ${({ theme }) => theme.textColor};
  font-size: ${rem(14)};
  z-index: 1;
`;
const Spacer = styled.div `
  flex: 1 0 0;
`;
export const ContextBarItem = (props) => {
    return React.createElement(ItemWrapper, null, props.children ? props.children : React.createElement("div", null));
};
export const ContextBarSpacer = () => {
    return React.createElement(Spacer, null);
};
export const ContextBar = (props) => {
    return React.createElement(Wrapper, null, props.children);
};
ContextBar.propTypes = {
    children: PropTypes.node,
};
ContextBarItem.propTypes = {
    children: PropTypes.node,
};
ContextBar.defaultProps = {
    children: React.createElement("div", null),
};
ContextBarItem.defaultProps = {
    children: React.createElement("div", null),
};
ContextBar.Spacer = ContextBarSpacer;
ContextBar.Item = ContextBarItem;
export default ContextBar;
