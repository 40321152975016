import 'cropperjs/dist/cropper.min.css';
import * as React from 'react';
import Cropper from 'react-cropper';
import Button from '../Button';
import { ContextBar, ContextBarItem, ContextBarSpacer } from '../ContextBar';
import ScrollLayout from '../Layouts/ScrollLayout';
import { ContentLoader } from '../Loader';
import Modal from '../Modal';
import styled, { css } from '../Themes/styled-components';
import TitlePanel from '../TitlePanel';
import { __ } from '../utilities';
import AspectRatioPicker from './Settings/AspectRatioPicker';
const Editor = styled.div `
  display: flex;
  flex-direction: row;
`;
const Content = styled('div') `
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  width: 100%;

  ${({ mask }) => mask === 'ellipse' &&
    css `
      .cropper-view-box {
        border-radius: 50%;
      }
    `}
`;
const Sidebar = styled.div `
  width: 400px;
  padding: 4px;
`;
const PreviewContainer = styled.div `
  width: ${({ basew = 40 }) => `${basew}`}%;
  padding-top: ${({ ratio = 1, basew = 40 }) => `${basew / ratio}%`};
  background: #000;
  overflow: hidden;
  position: relative;
  margin: 4px;
  display: inline-block;
  box-sizing: border-box;
  strong {
    position: absolute;
    top: 8px;
    left: 8px;
    color: #fff;
    text-shadow: 0px 0px 3px #000;
  }
`;
const Preview = styled.img `
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
`;
const StyledContentLoader = styled(ContentLoader) `
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
`;
class PhotoEditor extends React.PureComponent {
    constructor() {
        super(...arguments);
        this.state = {
            initializing: true,
            settingsOpened: false,
            settings: {
                aspectRatio: this.props.availableAspectRatios[0],
            },
            preview: undefined,
        };
        this.cropper = null;
        this.previewDebounce = 0;
        this.handleSubmit = () => {
            const transformationData = this.cropper.getData(true);
            this.props.onSubmit(transformationData, new Promise(resolve => {
                this.cropper.getCroppedCanvas().toBlob((blob) => {
                    resolve(blob);
                });
            }));
        };
        this.componentWillUnmount = () => {
            if (this.cropper) {
                this.cropper.destroy();
            }
        };
        this.handleReady = (cropper) => {
            this.setState({ initializing: false }, () => {
                this.cropper = cropper;
                this.cropper.options = Object.assign(Object.assign({}, this.cropper.options), this.getCropperOptions());
                this.cropper.replace(this.props.src);
            });
        };
        this.getCropperOptions = () => {
            return {
                guides: true,
                center: true,
                autoCropArea: 0.5,
                data: this.props.data,
                viewMode: this.props.viewMode,
                aspectRatio: this.aspectRatio(this.state.settings.aspectRatio),
                dragMode: 'move',
            };
        };
        this.aspectRatio = (ratio) => {
            if (ratio === null) {
                return null;
            }
            const [x, y] = ratio.split(':');
            if (x && y) {
                return Number(x) / Number(y);
            }
            return null;
        };
    }
    render() {
        const { initializing, settings } = this.state;
        const { onClose, availableAspectRatios, mask } = this.props;
        return (React.createElement(React.Fragment, null,
            React.createElement(Modal, { isOpen: true, handleClose: onClose, size: "fullscreen" },
                React.createElement(ScrollLayout, { topFixed: React.createElement(TitlePanel, { title: "Obr\u00E1zkov\u00FD editor" }), bottomFixed: React.createElement(ContextBar, null,
                        React.createElement(ContextBarItem, null,
                            React.createElement(Button, { onClick: onClose }, __('Zahodiť'))),
                        React.createElement(ContextBarSpacer, null),
                        React.createElement(ContextBarItem, null,
                            React.createElement(Button, { primary: true, onClick: this.handleSubmit }, __('Hotovo')))) },
                    React.createElement(Editor, null,
                        React.createElement(Content, { mask: mask },
                            initializing && React.createElement(StyledContentLoader, { size: "xl" }),
                            React.createElement(Cropper, Object.assign({ onInitialized: this.handleReady, src: this.props.src, style: { height: '100%', width: '100%' }, initialAspectRatio: this.aspectRatio(settings.aspectRatio), crop: () => {
                                    if (this.previewDebounce) {
                                        window.clearTimeout(this.previewDebounce);
                                    }
                                    this.setState({ preview: null });
                                    this.previewDebounce = window.setTimeout(() => {
                                        // vytvorime nahlad
                                        this.setState({
                                            preview: this.cropper
                                                .getCroppedCanvas()
                                                .toDataURL('image/jpeg'),
                                        });
                                    }, 200);
                                } }, this.getCropperOptions()))),
                        React.createElement(Sidebar, null,
                            React.createElement(AspectRatioPicker, { options: availableAspectRatios || [], value: settings.aspectRatio, onChange: ar => {
                                    this.setState({
                                        settings: Object.assign(Object.assign({ initializing: true }, this.state.settings), { aspectRatio: ar }),
                                    }, () => {
                                        this.cropper.options = Object.assign(Object.assign({}, this.cropper.options), this.getCropperOptions());
                                        this.cropper.replace(this.props.src);
                                    });
                                } }),
                            [
                                [16, 9, 90],
                                [2, 1, 40],
                                [4, 3, 40],
                                [1, 1, 40],
                            ].map(([w, h, basew]) => {
                                return (React.createElement(PreviewContainer, { ratio: w / h, basew: basew, key: `preview-${w}-${h}` },
                                    this.state.preview && (React.createElement(Preview, { src: this.state.preview, alt: `preview-${w}-${h}` })),
                                    React.createElement("strong", null,
                                        w,
                                        ":",
                                        h)));
                            })))))));
    }
}
PhotoEditor.defaultProps = {
    data: undefined,
    availableAspectRatios: ['2:1', '4:3', '16:9', '1:1', null],
    viewMode: 0,
    mask: undefined,
};
export default PhotoEditor;
