import FormField from '../../ui/FormField/redux-form';
import Col, { Row } from '../../ui/Grid';
import * as React from 'react';
import { Field } from 'redux-form';
import { __ } from '../../utilities';
import { isRequired } from '../../validation';

class RecipientForm extends React.PureComponent {
  render() {
    const { disabled } = this.props;
    return (
      <Row>
        <Col xs={12} s={3}>
          <Field
            component={FormField}
            name="recipient.title"
            label={__('Titul')}
            readOnly={disabled}
          />
        </Col>
        <Col xs={12} s={4}>
          <Field
            component={FormField}
            name="recipient.name"
            required
            validate={[isRequired]}
            label={__('Meno')}
            readOnly={disabled}
          />
        </Col>
        <Col xs={12} s={5}>
          <Field
            component={FormField}
            name="recipient.surname"
            required
            validate={[isRequired]}
            label={__('Priezvisko')}
            readOnly={disabled}
          />
        </Col>
        <Col xs={12}>
          <Field
            component={FormField}
            name="recipient.phone"
            required
            validate={[isRequired]}
            label={__('Telefónne číslo')}
            readOnly={disabled}
          />
        </Col>
        <Col xs={12}>
          <Field
            component={FormField}
            name="recipient.address.formatted_address"
            required
            validate={[isRequired]}
            label={__('Adresa')}
            readOnly={disabled}
          />
        </Col>
      </Row>
    );
  }
}

export default RecipientForm;
