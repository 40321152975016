import Button from '../../ui/Button';
import FormField from '../../ui/FormField/redux-form';
import Col, { Row } from '../../ui/Grid';
import PropTypes from 'prop-types';
import * as React from 'react';
import { connect } from 'react-redux';
import { Field, Form, isSubmitting, reduxForm } from 'redux-form';
import { __ } from '../../utilities';
import { isEmail, isRequired } from '../../validation';

const FORM_NAME = 'REGISTRATION_FORM';

class RegistrationForm extends React.PureComponent {
  render() {
    const { handleSubmit, submitting } = this.props;
    return (
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col xs={12} s={4}>
            <Field name="title" label={__('Titul')} component={FormField} />
          </Col>
          <Col xs={12} s={8}>
            <Field
              name="name"
              required
              validate={[isRequired]}
              label={__('Meno')}
              component={FormField}
            />
          </Col>
          <Col xs={12} s={12}>
            <Field
              name="surname"
              required
              validate={[isRequired]}
              label={__('Priezvisko')}
              component={FormField}
            />
          </Col>
          <Col xs={12} s={12}>
            <Field
              name="email"
              required
              validate={[isEmail, isRequired]}
              label={__('E-mail')}
              component={FormField}
            />
          </Col>
          <Col xs={12} s={12}>
            <Field
              name="phone"
              label={__('Telefónne číslo')}
              component={FormField}
            />
          </Col>
          <Col xs={12} s={6}>
            <Field
              name="password"
              required
              validate={[isRequired]}
              type="password"
              label={__('Heslo')}
              component={FormField}
            />
          </Col>
          <Col xs={12} s={6}>
            <Field
              name="password_again"
              type="password"
              required
              validate={[isRequired]}
              label={__('Heslo znova')}
              component={FormField}
            />
          </Col>
          <Col xs={12}>
            <Field
              name="gdpr"
              type="checkbox"
              required
              validate={[isRequired]}
              component={FormField}
              label={
                <a href="/assets/gdpr.docx">
                  {__('Súhlasím so spracovaním osobných údajov')}
                </a>
              }
            />
          </Col>
          <Col xs={12}>
            <Button block primary loading={submitting}>
              {__('Zaregistrovať sa')}
            </Button>
          </Col>
        </Row>
      </Form>
    );
  }
}

RegistrationForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
};

const connected = reduxForm({
  form: FORM_NAME,
  enableReinitialize: true,
})(RegistrationForm);

const mapStateToProps = (state) => ({
  submitting: isSubmitting(FORM_NAME)(state),
});

export default connect(mapStateToProps)(connected);
