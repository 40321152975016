import { rem } from 'polished';
import * as React from 'react';
import Icon from '../Icon';
import styled, { withTheme } from '../Themes/styled-components';
import Breadcrumbs from './Breadcrumbs';
import User from './User';
import { mb } from '../Themes/utilities';
const AVATAR_SIZE = 32;
export const TITLE_PANEL_HEIGHT = 54;
export const TITLE_PANEL_HEIGHT_M = 64;
const Wrapper = styled('div') `
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: ${rem(TITLE_PANEL_HEIGHT)};
  font-family: ${({ theme }) => theme.fontFamily};
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.backgroundColor};
  padding: 0 ${rem(12)};
  border-bottom: solid 1px ${({ theme }) => theme.separatorColor};
  margin: 0;
  ${mb('m')} {
    height: ${rem(TITLE_PANEL_HEIGHT_M)};
  }
`;
const RightWrapper = styled.div `
  display: flex;
  align-items: center;
  justify-content: left;
  align-self: normal;
`;
const LeftWrapper = styled('div') `
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const Item = styled('div') `
  font-size: ${rem(14)};
  text-align: left;
  color: ${({ theme }) => theme.textColor};
  margin-left: ${({ theme }) => rem(theme.grid.gutterWidth)};
  display: flex;
  align-items: center;
  cursor: pointer;
`;
const Text = styled.div `
  margin-left: ${rem(5)};
  display: flex;
  flex-direction: column;
`;
const Title = styled.div `
  font-weight: bold;
  font-size: ${rem(18)};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;
const TitleNameWrapper = styled.div `
  display: flex;
  flex-direction: column;
`;
const ArrowWrapper = styled.div `
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 ${rem(3)};
  margin-right: ${rem(12)};
  height: ${rem(44)};
  cursor: pointer;
`;
const Logo = styled.img `
  width: ${rem(AVATAR_SIZE)};
  max-height: 100%;
  min-width: ${rem(AVATAR_SIZE)};
  margin-right: ${({ theme }) => rem(theme.grid.gutterWidth)};
`;
const FlexIcon = styled(Icon) `
  display: flex;
`;
const StatusBarAction = styled.div `
  display: flex;
  cursor: pointer;
  align-items: center;
  align-self: stretch;
  padding: 0 ${({ theme }) => rem(theme.grid.gutterWidth)};
  margin-left: 1px;
  :hover {
    background-color: ${({ theme }) => theme.separatorColor};
  }
`;
const StatusBarActionLabel = styled.div `
  margin-left: ${({ theme, withIcon }) => withIcon ? rem(theme.grid.gutterWidth) : 0};
`;
class TitlePanel extends React.PureComponent {
    constructor() {
        super(...arguments);
        this.handleArrowClick = () => {
            if (!this.props.menuButton) {
                return;
            }
            if (this.props.menuButton.opened) {
                this.props.menuButton.onClose();
            }
            else {
                this.props.menuButton.onOpen();
            }
        };
        this.renderTitle = () => {
            const { crumbs = [] } = this.props;
            return (React.createElement(React.Fragment, null,
                this.props.menuButton && (React.createElement(ArrowWrapper, { onClick: this.handleArrowClick, "data-testid": "Ui_Global_IconArrow_Menu" },
                    React.createElement(Icon, { size: "l", name: "burger", color: this.props.menuButton.opened
                            ? this.props.theme.color.primary
                            : this.props.theme.color.fadedText }))),
                this.props.logo && React.createElement(Logo, { alt: "logo", src: this.props.logo }),
                React.createElement(TitleNameWrapper, null,
                    React.createElement(Title, null, this.props.title),
                    React.createElement(Breadcrumbs, { crumbs: crumbs }))));
        };
    }
    render() {
        const { name, subname, avatarSrc, messages, onClickUser, experimentalStatusBarActions, } = this.props;
        return (React.createElement(Wrapper, null,
            React.createElement(LeftWrapper, null, this.renderTitle()),
            React.createElement(RightWrapper, null,
                experimentalStatusBarActions &&
                    experimentalStatusBarActions.map(action => {
                        return (React.createElement(StatusBarAction, { onClick: action.onClick },
                            action.icon,
                            action.label && (React.createElement(StatusBarActionLabel, { withIcon: !!action.icon }, action.label))));
                    }),
                (name || subname || avatarSrc) && (React.createElement(User, { name: name, subname: subname, notificationCount: messages, avatarSrc: avatarSrc, avatarSize: AVATAR_SIZE, onClick: onClickUser })),
                this.props.onLogout && (React.createElement(Item, { onClick: this.props.onLogout },
                    React.createElement(FlexIcon, { size: "l", name: "power-off" }),
                    React.createElement(Text, null, "Odhl\u00E1si\u0165 sa"))),
                this.props.onClose && (React.createElement(Item, { onClick: this.props.onClose },
                    React.createElement(FlexIcon, { size: "l", name: "close" }),
                    React.createElement(Text, null, "Ukon\u010Di\u0165"))))));
    }
}
TitlePanel.defaultProps = {
    crumbs: [],
};
export default withTheme(TitlePanel);
