import { __rest } from "tslib";
import { darken, rem } from 'polished';
import * as React from 'react';
import Icon from '../Icon';
import Loader from '../Loader';
import styled, { css, ThemeProvider, withTheme, } from '../Themes/styled-components';
const customDarken = (amount, color) => {
    if (color === 'transparent') {
        return `rgba(0, 0, 0, ${amount})`;
    }
    return darken(amount, color);
};
const Wrapper = styled('button') `
  margin: 0;
  position: relative;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  display: inline-flex;
  width: ${({ block }) => (block ? '100%' : 'auto')};
  align-items: center;
  justify-content: center;
  min-height: ${rem(40)};
  outline: 0;
  border: none;
  background: ${({ theme }) => theme.buttonBackgroundColor};
  color: ${({ theme }) => theme.buttonTextColor};
  vertical-align: baseline;
  padding: 0 ${rem(22)};
  ${({ withoutContent, icon }) => withoutContent &&
    icon &&
    css `
      padding: 0 ${rem(12)};
    `};
  text-transform: none;
  text-shadow: none;
  font-weight: 500;
  line-height: 1em;
  font-style: normal;
  font-size: ${rem(14)};
  text-decoration: none;
  border-radius: ${({ theme }) => theme.largeBorderRadius};
  border: ${rem(1)} solid ${({ theme }) => theme.buttonBorderColor};
  user-select: none;
  font-family: ${({ theme }) => theme.fontFamily};
  transition: opacity 0.1s ease, background-color 0.1s ease, color 0.1s ease,
    box-shadow 0.1s ease, background 0.1s ease;
  ${({ disabled, theme }) => disabled
    ? ''
    : css `
          &:hover {
            background: ${customDarken(0.05, theme.buttonBackgroundColor)};
          }
          &:focus {
            background: ${customDarken(0.1, theme.buttonBackgroundColor)};
          }
          &:active {
            background: ${customDarken(0.2, theme.buttonBackgroundColor)};
          }
        `};
`;
const StyledIcon = styled(Icon) `
  ${({ noMargin }) => !noMargin &&
    css `
      margin-right: ${rem(9)};
    `};
`;
const StyledLoader = styled(Loader) `
  position: absolute;
  left: 50%;
  top: 50%;
  transition: transform 0.2s, opacity 0.2s;
  transform-origin: left top;
  ${({ loading }) => {
    if (loading) {
        return css `
        opacity: 1;
        transform: scale(1) translate(-50%, -50%);
      `;
    }
    return css `
      opacity: 0;
      transform: scale(0) translate(-50%, -50%);
    `;
}};
`;
const Content = styled('div') `
  transition: opacity 0.2s;
  opacity: ${({ isLoading }) => (isLoading ? 0.25 : 1)};
  display: flex;
  align-items: center;
`;
class Button extends React.PureComponent {
    render() {
        const _a = this.props, { theme, primary, danger, success, warning, block, basic, loading, disabled, onClick, icon, type, className, children } = _a, restProps = __rest(_a, ["theme", "primary", "danger", "success", "warning", "block", "basic", "loading", "disabled", "onClick", "icon", "type", "className", "children"]);
        let buttonTheme = {
            buttonBackgroundColor: 'transparent',
            buttonTextColor: theme.textColor,
        };
        // basic states
        if (primary) {
            if (basic) {
                buttonTheme = Object.assign(Object.assign({}, buttonTheme), { buttonTextColor: theme.color.primary });
            }
            else {
                buttonTheme = Object.assign(Object.assign({}, buttonTheme), { buttonBackgroundColor: theme.color.primary, buttonTextColor: '#fff' });
            }
        }
        else if (danger) {
            if (basic) {
                buttonTheme = Object.assign(Object.assign({}, buttonTheme), { buttonTextColor: theme.color.danger });
            }
            else {
                buttonTheme = Object.assign(Object.assign({}, buttonTheme), { buttonBackgroundColor: theme.color.danger, buttonTextColor: '#fff' });
            }
        }
        else if (success) {
            if (basic) {
                buttonTheme = Object.assign(Object.assign({}, buttonTheme), { buttonTextColor: theme.color.success });
            }
            else {
                buttonTheme = Object.assign(Object.assign({}, buttonTheme), { buttonBackgroundColor: theme.color.success, buttonTextColor: '#fff' });
            }
        }
        else if (warning) {
            if (basic) {
                buttonTheme = Object.assign(Object.assign({}, buttonTheme), { buttonTextColor: theme.color.warning });
            }
            else {
                buttonTheme = Object.assign(Object.assign({}, buttonTheme), { buttonBackgroundColor: theme.color.warning, buttonTextColor: '#fff' });
            }
        }
        if (disabled) {
            if (basic) {
                buttonTheme = Object.assign(Object.assign({}, buttonTheme), { buttonTextColor: theme.inactiveColor });
            }
            else {
                buttonTheme = Object.assign(Object.assign({}, buttonTheme), { buttonBackgroundColor: theme.inactiveColor, buttonTextColor: '#fff' });
            }
        }
        // nastavime spravny border
        if (basic) {
            buttonTheme = Object.assign(Object.assign({}, buttonTheme), { buttonBorderColor: buttonTheme.buttonTextColor });
        }
        else {
            buttonTheme = Object.assign(Object.assign({}, buttonTheme), { buttonBorderColor: buttonTheme.buttonBackgroundColor });
        }
        return (React.createElement(ThemeProvider, { theme: buttonTheme },
            React.createElement(Wrapper, Object.assign({ className: `sportnetui-btn ${className}`, block: block, disabled: disabled, icon: icon, withoutContent: !children, type: type, onClick: (e) => {
                    if (!disabled && onClick) {
                        onClick(e);
                    }
                } }, restProps),
                React.createElement(Content, { isLoading: loading },
                    icon && (React.createElement(StyledIcon, { noMargin: !children, size: "l", name: icon, color: buttonTheme.buttonTextColor })),
                    children),
                React.createElement(StyledLoader, { size: "l", loading: loading, color: buttonTheme.buttonTextColor }))));
    }
}
Button.defaultProps = {
    className: '',
    children: null,
    primary: false,
    danger: false,
    success: false,
    warning: false,
    block: false,
    basic: false,
    loading: false,
    disabled: false,
    type: 'submit',
    onClick: () => { },
};
export default withTheme(Button);
