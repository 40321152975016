import { __rest } from "tslib";
import moment from 'moment';
import * as React from 'react';
import DefaultDatePicker from './DatePicker';
const DatePicker = (props) => {
    const { input: { value, onChange }, meta: { touched, error } } = props, restProps = __rest(props, ["input", "meta"]);
    const momentValue = value ? moment(value) : null;
    return (React.createElement(DefaultDatePicker, Object.assign({}, restProps, { value: momentValue, onChange: onChange, error: error && touched })));
};
export default DatePicker;
