import { __rest } from "tslib";
import { darken, em } from 'polished';
import * as React from 'react';
import Checkbox from '../../Checkbox';
import Icon from '../../Icon';
import styled, { css } from '../../Themes/styled-components';
import Label from '../components/Label';
const Option = styled('div') `
  font-size: ${em(14)};
  display: flex;
  width: 100%;
  position: relative;
  align-content: center;
  align-items: center;
  min-height: ${em(32)};
  flex-shrink: 0;
  padding: 0 ${({ theme }) => em(theme.grid.gutterWidth)};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  background-color: #fff;
  color: ${({ theme }) => theme.textColor};
  ${({ focused, theme }) => focused &&
    css `
      background-color: ${theme.color.primaryBg};
      color: ${theme.color.primary};
    `};
  ${({ highlighted, theme }) => highlighted &&
    css `
      cursor: default;
      background-color: ${theme.color.primary} !important;
      color: #fff !important;
    `};
`;
const InteractableOption = styled(Option) `
  cursor: pointer;
  :hover {
    background-color: ${({ theme }) => theme.color.primaryBg};
    color: ${({ theme }) => theme.color.primary};
  }
  :active {
    background-color: ${({ theme }) => darken(0.05, theme.color.primaryBg)};
    color: ${({ theme }) => theme.color.primary};
  }
`;
const ReturnIcon = styled(Icon) `
  margin-left: auto;
  padding-left: ${({ theme }) => em(theme.grid.gutterWidth)};
  min-width: 24px;
`;
export default React.forwardRef(function (props, ref) {
    const { option, onSelectOption, selected = false, focused = false, useCheckbox = false, iconSize = 14, renderLabel, idx } = props, restProps = __rest(props, ["option", "onSelectOption", "selected", "focused", "useCheckbox", "iconSize", "renderLabel", "idx"]);
    function handleMouseDown(e) {
        e.stopPropagation();
        e.preventDefault();
    }
    function handleClick(e) {
        e.stopPropagation();
        e.preventDefault();
        if (onSelectOption) {
            onSelectOption(option);
        }
    }
    const content = (React.createElement(React.Fragment, null,
        useCheckbox && React.createElement(Checkbox, { checked: selected, readOnly: true }),
        React.createElement(Label, { label: typeof renderLabel !== 'undefined'
                ? renderLabel(option, idx)
                : option.label, icon: option.icon, iconSize: iconSize, iconColor: !useCheckbox && selected ? '#fff' : undefined }),
        focused && (React.createElement(ReturnIcon, { color: !useCheckbox && selected ? '#fff' : undefined, name: "return" }))));
    const commonProps = Object.assign({ selected,
        focused, highlighted: !useCheckbox && selected, ref: ref, onMouseDown: handleMouseDown }, restProps);
    return onSelectOption ? (React.createElement(InteractableOption, Object.assign({ onClick: handleClick }, commonProps), content)) : (React.createElement(Option, Object.assign({}, commonProps), content));
});
