import { __rest } from "tslib";
import { rem } from 'polished';
import * as React from 'react';
import ReactDOM from 'react-dom';
import EventStack from '../EventStack';
import styled, { createGlobalStyle, css } from '../Themes/styled-components';
const uniqueId = require('lodash.uniqueid');
const BASE_ZINDEX = 100;
let zIndexCounter = 0;
const Backdrop = styled('div') `
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.25);
  transition: all 0.3s;
  ${({ isOpen }) => isOpen
    ? css `
          opacity: 1;
        `
    : css `
          pointer-events: none;
          opacity: 0;
        `};
`;
const ZIndexOverlay = styled('div') `
  position: fixed;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  ${({ isOpen }) => !isOpen &&
    css `
      pointer-events: none;
      overflow: hidden;
    `};
  z-index: ${({ zIndex }) => BASE_ZINDEX + zIndex};
`;
const Positioner = styled.div `
  position: absolute;
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => rem(theme.grid.gutterWidth)};
  z-index: 100;
  transition: all 0.3s;

  ${({ centered }) => centered &&
    css `
      position: relative;
      margin: auto;
    `}

  ${({ size, theme }) => {
    if (size === 'fullscreen') {
        return css `
        bottom: 0;
        top: 0;
        left: 0;
        right: 0;
        padding: 0;
        position: absolute;
      `;
    }
    else if (size === 'xs') {
        return css `
        top: 0;
        max-width: ${rem(350 + theme.grid.gutterWidth * 2)};
        width: 100%;
      `;
    }
    else if (size === 'full') {
        return css `
        bottom: 0;
        top: 0;
        left: 0;
        right: 0;
      `;
    }
    return css `
      top: 0;
      max-width: ${rem(800 + theme.grid.gutterWidth * 2)};
      width: 100%;
    `;
}};

  ${({ isOpen }) => isOpen
    ? css `
          transform: translateY(0);
          opacity: 1;
        `
    : css `
          transform: translateY(-50px);
          opacity: 0;
        `};
`;
export const ModalContent = styled.div `
  ${({ theme }) => css `
    padding: ${rem(theme.grid.gutterWidth * 2)} ${rem(theme.grid.gutterWidth)};
  `}
`;
const Wrapper = styled.div `
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100%;
  background: #fff;
  border-radius: ${rem(4)};
  box-shadow: 0 0 ${rem(40)} 0 rgba(0, 0, 0, 0.2);

  ${({ size }) => size === 'fullscreen' &&
    css `
      border-radius: 0;
      box-shadow: none;
      & > ${ModalContent} {
        overflow-y: auto;
      }
    `}
`;
export const ModalActions = styled.div `
  border-top: ${rem(1)} solid #eee;
  padding: ${({ theme }) => rem(theme.grid.gutterWidth)};
  display: flex;
  justify-content: space-between;
  flex-shrink: 0;
`;
let element = null;
let modalRoot;
if (typeof document !== 'undefined') {
    element = document.createElement('div');
    element.setAttribute('id', 'modal-root');
    modalRoot = document.body.appendChild(element);
}
const bodyOverflowHiddenClass = 'modal-opened';
const GlobalStyle = createGlobalStyle `
  body.${bodyOverflowHiddenClass} {
    overflow: hidden;
  }
`;
class Modal extends React.PureComponent {
    constructor(props) {
        super(props);
        this.init = () => {
            document.body.classList.add(`${bodyOverflowHiddenClass}-${this.uid}`);
            if (typeof document !== 'undefined') {
                this.setState({ zIndex: ++zIndexCounter });
                EventStack.add(document.body, 'keydown', this.handleKeyDown);
            }
        };
        this.destroy = () => {
            document.body.classList.remove(`${bodyOverflowHiddenClass}-${this.uid}`);
            if (typeof document !== 'undefined') {
                EventStack.remove(document.body, 'keydown', this.handleKeyDown);
            }
        };
        this.handleKeyDown = (e) => {
            const { handleClose } = this.props;
            if (e.key === 'Escape') {
                handleClose();
            }
        };
        this.uid = uniqueId();
        this.state = {
            zIndex: 0,
        };
        this.el = null;
        if (typeof document !== 'undefined') {
            this.el = document.createElement('div');
        }
    }
    componentDidMount() {
        modalRoot.appendChild(this.el);
        if (this.props.isOpen === true) {
            this.init();
        }
    }
    componentDidUpdate(prevProps) {
        if (typeof document !== 'undefined') {
            if (this.props.isOpen === true && prevProps.isOpen === false) {
                this.init();
            }
            if (this.props.isOpen === false && prevProps.isOpen === true) {
                this.destroy();
            }
        }
    }
    componentWillUnmount() {
        modalRoot.removeChild(this.el);
        this.destroy();
    }
    render() {
        const _a = this.props, { centered, size, isOpen, children, handleClose } = _a, restProps = __rest(_a, ["centered", "size", "isOpen", "children", "handleClose"]);
        const { zIndex } = this.state;
        return ReactDOM.createPortal(React.createElement(ZIndexOverlay, { isOpen: isOpen, zIndex: zIndex },
            React.createElement(Positioner, { centered: centered, size: size, isOpen: isOpen },
                React.createElement(Wrapper, Object.assign({ size: size }, restProps), children)),
            React.createElement(Backdrop, { isOpen: isOpen, onClick: handleClose }),
            React.createElement(GlobalStyle, null)), this.el);
    }
}
Modal.defaultProps = {
    size: 'm',
};
export default Modal;
