import format from 'date-fns/format';
import sk from 'date-fns/locale/sk';
export const __ = (toTranslate) => {
    return toTranslate;
};
export const formatDate = (date, formatStr) => {
    return format(new Date(date), formatStr, {
        locale: sk,
    });
};
export const emptyFn = () => { };
export const debounce = (func, timeout) => {
    let timer;
    return (...args) => {
        clearTimeout(timer);
        timer = setTimeout(() => {
            func(...args);
        }, timeout);
    };
};
export const createSizer = (sizes, defaultValue) => {
    return (size) => size in sizes ? sizes[size] : defaultValue;
};
export const isBrowser = () => typeof window !== 'undefined' &&
    typeof window.location !== 'undefined' &&
    typeof window.document !== 'undefined';
