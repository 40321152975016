import { readableColor, rem } from 'polished';
import * as React from 'react';
import { SketchPicker } from 'react-color';
import { DEFUALT_COLORS } from '../ColorPicker';
import Icon from '../Icon';
import Input from '../Input';
import styled, { withTheme } from '../Themes/styled-components';
const WrapperInput = styled('div') `
  position: relative;
  width: 100%;
  justify-content: center;
  border: 1px solid ${({ theme }) => theme.separatorColor};
  border-radius: ${({ theme }) => rem(theme.input.borderRadius)};
`;
const StyledInput = styled(Input) `
  background: ${({ background }) => background};
  color: ${({ color }) => color};
`;
const IconButton = styled('button') `
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  border: none;
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: ${rem(40)};
  right: 0;
  background: none;
  :disabled {
    -webkit-text-fill-color: ${({ theme }) => theme.input.disabledTextColor};
    cursor: ${({ theme }) => theme.input.disabledCursor};
  }
`;
const Cover = styled('div') `
  position: absolute;
  top: ${({ theme }) => rem(theme.input.height)};
  right: ${rem(0)};
  justify-content: center;
  z-index: 1;
`;
const Backdrop = styled('div') `
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;
function isValidColor(input) {
    return /(^#[0-9A-F]{6}$)|(^#[0-9A-F]{3}$)/i.test(input);
}
const C = (props) => {
    const { value, onChange, disabled, error = false, theme, colors = DEFUALT_COLORS, propagateChangesImmediately = false, } = props;
    const inputEl = React.useRef(null);
    const [visible, setVisible] = React.useState(false);
    const [localValue, setLocalValue] = React.useState(isValidColor(value) ? value : '');
    const isLocalValueValid = isValidColor(localValue);
    React.useEffect(() => {
        if (isValidColor(value)) {
            setLocalValue(value);
        }
    }, [value]);
    React.useEffect(() => {
        if ((isLocalValueValid || !localValue) && value !== localValue) {
            onChange(localValue);
        }
    }, [localValue, onChange, isLocalValueValid, value]);
    const handleChangeColor = (color) => {
        if (isValidColor(color.hex)) {
            setLocalValue(color.hex);
        }
    };
    const handleKeyDown = (event) => {
        if (event.key === 'Enter' || event.key === 'Escape') {
            setVisible(false);
            if (inputEl && inputEl.current) {
                inputEl.current.blur();
            }
        }
    };
    return (React.createElement(WrapperInput, null,
        React.createElement(StyledInput, { type: "text", value: localValue, onChange: (e) => setLocalValue(e.target.value), onFocus: () => {
                setVisible(!visible);
            }, onBlur: () => {
                if (!localValue) {
                    setLocalValue('');
                }
                else if (!isLocalValueValid) {
                    setLocalValue(value);
                }
            }, onKeyDown: handleKeyDown, background: isLocalValueValid ? localValue : '#fff', disabled: disabled, error: error, color: isLocalValueValid
                ? readableColor(localValue, theme.colorControl.darkColor, theme.colorControl.lightColor)
                : theme.colorControl.darkColor, inputRef: inputEl, autoComplete: "off" }),
        React.createElement(IconButton, { onClick: () => {
                setVisible(!visible);
            }, background: isLocalValueValid ? localValue : 'transparent', disabled: disabled },
            React.createElement(Icon, { name: "pallete", size: 24, color: isLocalValueValid
                    ? readableColor(localValue, theme.colorControl.darkColor, theme.colorControl.lightColor)
                    : theme.colorControl.darkColor })),
        visible && (React.createElement(Cover, null,
            React.createElement(Backdrop, { onClick: () => {
                    setVisible(false);
                } }),
            React.createElement(SketchPicker, { color: localValue, onChange: propagateChangesImmediately ? handleChangeColor : undefined, onChangeComplete: propagateChangesImmediately ? undefined : handleChangeColor, disableAlpha: true, presetColors: colors })))));
};
export default withTheme(C);
