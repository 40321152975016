import { __rest } from "tslib";
import { rem } from 'polished';
import * as React from 'react';
import FormGroupContext from '../FormGroup/FormGroupContext';
import styled from '../Themes/styled-components';
import InputButton from './Button';
import InputIcon from './Icon';
import InputLoading from './Loading';
import InputSelect from './Select';
import { getBorderFromProps } from './utils';
const Input = styled.input `
  width: 100%;
  flex: 1 0 0;
  border: 0;
  font-size: ${({ theme }) => rem(theme.input.fontSize)};
  padding: 0 ${({ theme }) => rem(theme.input.horizontalPadding)};
  height: ${({ theme }) => rem(theme.input.height)};
  color: ${({ theme }) => theme.textColor};
  font-family: ${({ theme }) => theme.fontFamily};
  outline: 0;
  background-color: ${({ theme }) => theme.input.backgroundColor};
  box-shadow: ${({ theme }) => theme.input.shadow};
  &::placeholder {
    color: ${({ theme }) => theme.form.placeholderColor};
  }
  &:read-only {
    cursor: ${({ theme }) => theme.input.readOnlyCursor};
  }
  &:disabled {
    -webkit-text-fill-color: ${({ theme }) => theme.input.disabledTextColor};
    background-color: ${({ theme }) => theme.input.disabledBackground};
    cursor: ${({ theme }) => theme.input.disabledCursor};
  }
`;
const Wrapper = styled('div') `
  position: relative;
  display: flex;
  flex-direction: row;
  border-left: 0;
  border-right: 0;
  background-color: ${({ theme }) => theme.input.backgroundColor};
  border-radius: ${({ theme }) => rem(theme.input.borderRadius)};
  > * {
    border-radius: 0;
  }
  > ${Input}, > ${InputButton} {
    border-top: ${props => getBorderFromProps(props)};
    border-bottom: ${props => getBorderFromProps(props)};
  }
  > :first-child {
    border-left: ${props => getBorderFromProps(props)};
    border-top-left-radius: ${({ theme }) => rem(theme.input.borderRadius)};
    border-bottom-left-radius: ${({ theme }) => rem(theme.input.borderRadius)};
    &${InputButton} {
      border-left: ${props => getBorderFromProps(props)};
    }
  }
  > :last-child {
    border-right: ${props => getBorderFromProps(props)};
    border-top-right-radius: ${({ theme }) => rem(theme.input.borderRadius)};
    border-bottom-right-radius: ${({ theme }) => rem(theme.input.borderRadius)};
    &${InputButton} {
      border-right: ${props => getBorderFromProps(props)};
    }
  }
  > ${InputIcon}, > ${InputLoading} {
    border-top: ${props => getBorderFromProps(props)};
    border-bottom: ${props => getBorderFromProps(props)};
  }
  > ${InputSelect} {
    border-top: ${props => getBorderFromProps(props)};
    border-bottom: ${props => getBorderFromProps(props)};
    padding-right: ${rem(5)};
    background: ${({ theme }) => theme.separatorColor};
    select {
      border: 0;
      height: 100%;
      background: transparent;
      font-size: 13px;
    }
  }
  > ${InputButton} {
    border-left: 0;
    border-right: 0;
  }
`;
class CompInput extends React.PureComponent {
    constructor() {
        super(...arguments);
        this.state = {
            focused: false,
        };
        this.handleFocus = (event) => {
            const { onFocus } = this.props;
            if (onFocus) {
                onFocus(event);
            }
            this.setState({ focused: true });
        };
        this.handleBlur = (event) => {
            const { onBlur } = this.props;
            if (onBlur) {
                onBlur(event);
            }
            this.setState({ focused: false });
        };
    }
    render() {
        const _a = this.props, { className, children } = _a, restProps = __rest(_a, ["className", "children"]);
        const { focused } = this.state;
        if (!children) {
            // no children basic input
            return (React.createElement(CompInput, Object.assign({}, this.props),
                React.createElement("input", null),
                !!this.props.icon && React.createElement(InputIcon, { name: this.props.icon }),
                !!this.props.loading && React.createElement(InputLoading, null)));
        }
        return (React.createElement(FormGroupContext.Consumer, null, value => (React.createElement(Wrapper, { focused: focused, readOnly: !!this.props.readOnly, error: !!restProps.error }, React.Children.map(children, (child) => {
            if (!child) {
                return child;
            }
            else if (child.type === 'input') {
                return (React.createElement(Input, Object.assign({}, restProps, { className: className, ref: this.props.inputRef, onFocus: this.handleFocus, onBlur: this.handleBlur, id: this.props.id || value.id })));
            }
            else if (child.type === InputButton ||
                child.type === InputIcon ||
                child.type === InputLoading ||
                child.type === InputSelect) {
                return React.cloneElement(child, {
                    error: restProps.error,
                });
            }
            return undefined;
        })))));
    }
}
CompInput.Button = InputButton;
CompInput.Icon = InputIcon;
CompInput.Loading = InputLoading;
CompInput.Select = InputSelect;
export default CompInput;
