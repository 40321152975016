import * as React from 'react';
import styled from '../../Themes/styled-components';
import { __ } from '../../utilities';
import Dropdown from '../components/Dropdown';
import { containsExactMatch, getDefaultCreateOption, isOptionSelected, } from '../utils';
import Option from './Option';
export const CREATE_OPTION_VALUE = '$create';
const DisabledOption = styled(Option) `
  justify-content: center;
  font-style: italic;
  background-color: ${({ theme }) => theme.separatorColor};
  color: ${({ theme }) => theme.inactiveColor};
`;
export default function (props) {
    const { options, focusedIdx, multiple, value, onSelectOption, loading, createNewOption, inputValue, renderLabel, } = props;
    const wrapperEl = React.useRef(null);
    const optionElements = React.useRef([]);
    React.useEffect(() => {
        const optionEl = optionElements.current[focusedIdx];
        if (wrapperEl && wrapperEl.current && optionEl) {
            wrapperEl.current.scrollTop =
                optionEl.offsetTop +
                    optionEl.getBoundingClientRect().height / 2 -
                    wrapperEl.current.getBoundingClientRect().height / 2;
        }
    }, [focusedIdx, options]);
    const defaultCreateOption = React.useMemo(() => getDefaultCreateOption(inputValue), [inputValue]);
    function renderOptions() {
        if (options.length > 0 || createNewOption) {
            const allOptions = options.map((option, idx) => (React.createElement(Option, { ref: (el) => {
                    optionElements.current[idx] = el;
                }, key: idx, option: option, onSelectOption: onSelectOption, focused: focusedIdx === idx, useCheckbox: multiple, renderLabel: renderLabel, selected: isOptionSelected(option, value), idx: idx })));
            if (createNewOption &&
                inputValue &&
                !containsExactMatch(options, inputValue)) {
                const idx = options.length;
                allOptions.push(React.createElement(Option, { ref: (el) => {
                        optionElements.current[idx] = el;
                    }, idx: idx, key: idx, option: defaultCreateOption, onSelectOption: onSelectOption, focused: focusedIdx === idx }));
            }
            return allOptions;
        }
        if (loading) {
            return (React.createElement(DisabledOption, { idx: 0, option: { value: '', label: __('Načítavam ...') } }));
        }
        return (React.createElement(DisabledOption, { idx: 0, option: { value: '', label: __('Žiadne možnosti') } }));
    }
    return React.createElement(Dropdown, { ref: wrapperEl }, renderOptions());
}
