/**
 * Utilitná funkcia pre vyber hodnout pod klucami v objekte do array-e
 * @param {object} - Objekt s hodnotami pod klucami
 * @param {string[] | number[]} - Array klucov
 * @returns {any[]} - Array hodnot
 * @example
 * ```javascript
 * import at from 'utilities/lib/at';
 *
 * const obj = {
 *  a: {
 *    _id: 'a',
 *  },
 *  b: {
 *    _id: 'b'
 *  }
 * }
 * console.log(at(obj, ['a', 'b'])); // [{_id: 'a'}, {_id: 'b'}]
 * ```
 */
export default function at(object, keys) {
    return keys.reduce((acc, key) => {
        const item = object[key];
        if (typeof item !== 'undefined' && item !== null) {
            acc.push(item);
        }
        return acc;
    }, []);
}
