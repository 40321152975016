import { __rest } from "tslib";
import { rem } from 'polished';
import * as React from 'react';
import styled, { withTheme } from '../Themes/styled-components';
const Wrapper = styled('div') `
  height: ${({ scSize }) => `${rem(scSize)}`};
  width: ${({ scSize }) => `${rem(scSize)}`};
`;
const Inner = styled('div') `
  height: 100%;
  width: 100%;
  animation: rotate 1s infinite linear;
  border: ${({ theme, scSize, scColor }) => `${rem(scSize / 6.5)} solid ${scColor !== null ? scColor : theme.color.primary}`};
  border-right-color: transparent;
  border-radius: 50%;

  @keyframes rotate {
    0% {
      transform: rotate(360deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
`;
const ContentWrapper = styled('div') `
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const defaultProps = {
    size: 'm',
    color: null,
    className: '',
};
export class Loader extends React.PureComponent {
    render() {
        const { size, className, color } = this.props;
        const sizeMapping = {
            m: 16,
            l: 24,
            xl: 40,
            xxl: 110,
        };
        const sizeName = size in sizeMapping ? sizeMapping[size] : 16;
        return (React.createElement(Wrapper, { className: className, scSize: sizeName, "data-testid": "Loader" },
            React.createElement(Inner, { scSize: sizeName, scColor: color })));
    }
}
Loader.defaultProps = defaultProps;
export class ContentLoader extends React.PureComponent {
    render() {
        const _a = this.props, { className } = _a, restProps = __rest(_a, ["className"]);
        return (React.createElement(ContentWrapper, { className: className },
            React.createElement(Loader, Object.assign({}, restProps))));
    }
}
export default withTheme(Loader);
