export const getBorder = (theme, error, focused, readOnly) => {
    if (error) {
        return `1px solid ${theme.color.danger}`;
    }
    if (focused) {
        return `1px solid ${theme.color.primary}`;
    }
    if (readOnly) {
        return `1px solid ${theme.input.borderColor}`;
    }
    return `1px solid ${theme.input.borderColor}`;
};
export const getBorderFromProps = (props) => {
    return getBorder(props.theme, props.error, props.focused, props.readOnly);
};
