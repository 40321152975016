import { __rest } from "tslib";
import { rem } from 'polished';
import * as React from 'react';
import styled from '../../Themes/styled-components';
import InputSmarttag from '../InputSmarttag';
// import { smarttagsPropType } from '../InputSmarttag/propTypes';
import SmarttagTags from '../SmarttagTags';
const Wrapper = styled.div ``;
const SmarttagTagsWrapper = styled('div') `
  margin-top: ${rem(10)};
`;
class SmarttagControl extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        const _a = this.props, { value, onChange, disabled, availableSmarttags, getSmarttagsKeys, getSmarttagsValues, readOnly, defaultInputValue } = _a, restProps = __rest(_a, ["value", "onChange", "disabled", "availableSmarttags", "getSmarttagsKeys", "getSmarttagsValues", "readOnly", "defaultInputValue"]);
        return (React.createElement(Wrapper, null,
            !readOnly && (React.createElement(InputSmarttag, Object.assign({ disabled: disabled, defaultInputValue: defaultInputValue, onAdd: (newSmarttag) => {
                    let smarttags = [...value];
                    const smarttagIndex = smarttags.findIndex(stag => stag.key === newSmarttag.key);
                    if (smarttagIndex === -1) {
                        // we don't have this smarttag group
                        smarttags.push(newSmarttag);
                    }
                    else {
                        const smarttag = Object.assign({}, smarttags[smarttagIndex]);
                        const idx = smarttag.values.findIndex((val) => val.key === newSmarttag.values[0].key);
                        if (idx === -1) {
                            smarttag.values = [...smarttag.values, newSmarttag.values[0]];
                        }
                        smarttags = [
                            ...smarttags.slice(0, smarttagIndex),
                            smarttag,
                            ...smarttags.slice(smarttagIndex + 1),
                        ];
                    }
                    onChange([...smarttags]);
                }, availableSmarttags: availableSmarttags, getSmarttagsKeys: getSmarttagsKeys, getSmarttagsValues: getSmarttagsValues, readOnly: readOnly }, restProps))),
            React.createElement(SmarttagTagsWrapper, null,
                React.createElement(SmarttagTags, { onChange: readOnly
                        ? null
                        : newSmarttags => {
                            onChange(newSmarttags);
                        }, items: value, disabled: disabled }))));
    }
}
SmarttagControl.defaultProps = {
    availableSmarttags: [],
    value: [],
    getSmarttagsKeys: null,
    getSmarttagsValues: null,
    onChange: () => { },
    readOnly: false,
    defaultInputValue: '',
    disabled: false,
};
// SmarttagControl.propTypes = {
//   availableSmarttags: smarttagsPropType,
//   getSmarttagsKeys: PropTypes.func,
//   getSmarttagsValues: PropTypes.func,
//   value: smarttagsPropType,
//   onChange: PropTypes.func,
//   readOnly: PropTypes.bool,
//   defaultInputValue: PropTypes.string,
//   disabled: PropTypes.bool,
// };
export default SmarttagControl;
