import { __rest } from "tslib";
import * as React from 'react';
import TheSelect from '../TheSelect';
import { useTheSelectOptions } from '../TheSelect/utils';
const TheSelectSimple = (_a) => {
    var { value, onChange } = _a, props = __rest(_a, ["value", "onChange"]);
    const [, selectedOption] = useTheSelectOptions(props.options, a => a, value, [props.options], {
        multiple: props.multiple,
    });
    const handleChange = React.useCallback((value) => {
        if (!value) {
            onChange(value);
        }
        else if (Array.isArray(value)) {
            onChange(value.map(v => v.value));
        }
        else {
            onChange(value.value);
        }
    }, [onChange]);
    return (React.createElement(TheSelect, Object.assign({}, props, { value: selectedOption, onChange: handleChange })));
};
export default TheSelectSimple;
