import { __rest } from "tslib";
import * as React from 'react';
import styled, { withTheme } from '../Themes/styled-components';
import icons from './icons';
const Wrapper = styled.span `
  display: inline-flex;
  align-items: center;
  flex-shrink: 0;
`;
const defaultProps = {
    size: 'm',
};
class Icon extends React.PureComponent {
    sizeToPx(size) {
        const sizeMapping = {
            xs: 8,
            s: 12,
            m: 16,
            l: 24,
            xl: 40,
            xxl: 110,
        };
        return size in sizeMapping ? sizeMapping[size] : 16;
    }
    /**
     * Method resolves deprecated names for icons
     */
    getName(nameFromProps) {
        switch (nameFromProps) {
            case 'remove-smarttag':
                return 'close';
            case 'arrow-down-small':
            case 'chevron':
                return 'arrow-down';
            case 'arrow':
                return 'arrow-right';
            default:
                return nameFromProps;
        }
    }
    render() {
        const _a = this.props, { size, color, className, name: nameFromProps } = _a, restProps = __rest(_a, ["size", "color", "className", "name"]);
        const name = this.getName(nameFromProps);
        const iconSize = typeof size === 'string' ? this.sizeToPx(size) : size;
        const iconColor = this.props.color ? color : this.props.theme.textColor;
        return (React.createElement(Wrapper, Object.assign({ className: `icon ${className}` }, restProps), name in icons ? icons[name](iconSize, iconColor) : null));
    }
}
Icon.defaultProps = defaultProps;
export default withTheme(Icon);
export { default as icons } from './icons';
