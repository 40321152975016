import { __rest } from "tslib";
import { rem } from 'polished';
import * as React from 'react';
import FormGroupContext from '../FormGroup/FormGroupContext';
import { getBorderFromProps } from '../Input/utils';
import styled, { css } from '../Themes/styled-components';
const Textarea = styled.textarea `
  ${({ resizable }) => css `
    resize: ${resizable ? 'vertical' : 'none'};
  `}
  width: 100%;
  min-height: ${({ theme }) => rem(theme.input.height)};

  border-radius: ${({ theme }) => rem(theme.input.borderRadius)};
  font-size: ${({ theme }) => rem(theme.input.fontSize)};
  padding: ${rem(10)} ${({ theme }) => rem(theme.input.horizontalPadding)};
  color: ${({ theme }) => theme.textColor};
  font-family: ${({ theme }) => theme.fontFamily};
  outline: 0;
  background-color: ${({ theme }) => theme.input.backgroundColor};
  box-shadow: ${({ theme }) => theme.input.shadow};
  border: ${props => getBorderFromProps(props)};
  &::placeholder {
    color: ${({ theme }) => theme.form.placeholderColor};
  }
  &:read-only {
    cursor: ${({ theme }) => theme.input.readOnlyCursor};
  }
  &:disabled {
    -webkit-text-fill-color: ${({ theme }) => theme.input.disabledTextColor};
    background-color: ${({ theme }) => theme.input.disabledBackground};
    cursor: ${({ theme }) => theme.input.disabledCursor};
  }
  &:focus {
    border: ${props => getBorderFromProps(Object.assign(Object.assign({}, props), { focused: true }))};
  }
`;
export default (props) => {
    const value = React.useContext(FormGroupContext);
    const { inputRef, readOnly, id, className, resizable, rows = 8, error } = props, restProps = __rest(props, ["inputRef", "readOnly", "id", "className", "resizable", "rows", "error"]);
    return (React.createElement(Textarea, Object.assign({}, restProps, { className: className, ref: inputRef, readOnly: !!readOnly, id: id || value.id, resizable: resizable, rows: rows, error: error })));
};
