import Segment from '../../ui/Segment';
import { rem } from 'polished';
import PropTypes from 'prop-types';
import * as React from 'react';
import styled from 'styled-components';

const BoxHeader = styled.div`
  padding: ${rem(0)} ${rem(15)};
  background: white;
  width: 100%;
  display: flex;
  cursor: ${({ closable }) => (closable ? 'pointer' : 'default')};
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${rem(10)};
`;
const Title = styled.div`
  padding-top: ${rem(5)};
  display: flex;
  align-items: center;
  & > h2 {
    font-weight: 600;
    font-size: ${rem(14)};
    padding-left: ${rem(20)};
    color: #3e3e3e;
  }
  &:before {
    content: '';
    width: ${rem(5)};
    height: ${rem(20)};
    position: absolute;
    background: ${({ color }) => color};
  }
`;
const BoxContent = styled.div`
  display: ${({ closed }) => (closed ? 'none' : 'block')}
  padding: ${rem(10)};
`;
const LoaderContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Dimmer = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  background: rgba(255, 255, 255, 0.6);
  z-index: 1;
`;

const Loader = () => (
  <svg
    version="1.1"
    id="L3"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    style={{
      width: rem(60),
      height: rem(60),
      margin: rem(20),
      zIndex: 2,
      display: 'inline-block',
    }}
    y="0px"
    viewBox="0 0 100 100"
  >
    <circle
      fill="none"
      stroke="#006ADC"
      strokeWidth="4"
      cx="50"
      cy="50"
      r="44"
      style={{ opacity: 0.5 }}
    />
    <circle fill="#fff" stroke="#006ADC" strokeWidth="3" cx="8" cy="54" r="6">
      <animateTransform
        attributeName="transform"
        dur="1s"
        type="rotate"
        from="0 50 48"
        to="360 50 52"
        repeatCount="indefinite"
      />
    </circle>
  </svg>
);

class Box extends React.PureComponent {
  state = {
    closed: false,
  };
  componentDidMount() {
    if (this.props.closable) {
      this.setState({
        closed: true,
      });
    }
  }
  render() {
    const { header, children, buttons, color, loading, closable } = this.props;
    return (
      <Segment raised>
        {loading && (
          <LoaderContainer>
            <Loader />
            <Dimmer />
          </LoaderContainer>
        )}
        <BoxHeader
          closable={closable}
          onClick={() => {
            if (closable) {
              this.setState({
                closed: !this.state.closed,
              });
            }
          }}
        >
          <Title color={color}>
            <h2>{header}</h2>
          </Title>
          {buttons}
        </BoxHeader>
        <BoxContent closed={this.state.closed}>{children}</BoxContent>
      </Segment>
    );
  }
}

Box.propTypes = {
  header: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  buttons: PropTypes.arrayOf(PropTypes.node),
  color: PropTypes.string.isRequired,
  loading: PropTypes.bool,
};

Box.defaultProps = {
  buttons: [],
  loading: false,
};

export default Box;
