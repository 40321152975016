import moment from 'moment';
import Button from '../../ui/Button';
import Col, { Row } from '../../ui/Grid';
import Message from '../../ui/Message';
import Segment from '../../ui/Segment';
import cookies from 'js-cookie';
import { rem } from 'polished';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import Api from '../../Api';
import Logo from '../../Assets/logo_kuriereu.png';
import { __ } from '../../utilities';
import { fetchUser } from '../ApplicationWrapper/actions';
import LoginForm from './form';

export const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  background: ${({ theme }) => theme.color.primary};
`;
export const ModalWrapper = styled.div`
  position: relative;
`;
export const Modal = styled.div`
  transform: translate(-50%, -50%);
  position: fixed;
  width: 100%;
  top: 50%;
  left: 50%;
`;
export const FrontModal = styled.div`
  max-width: ${rem(400)};
  background: white;
  width: 100%;
  padding: ${rem(80)} ${rem(40)} 0 ${rem(40)};
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  border-radius: ${rem(10)};
`;
export const BackgroundModal = styled.div`
  background: #efefef;
  max-width: ${rem(450)};
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 90%;
  z-index: -1;
  border-radius: ${rem(10)};
`;
export const Brand = styled.img``;
export const FormWrapper = styled.div`
  padding-top: ${rem(40)};
  width: 100%;
`;
export const Buttons = styled.div`
  padding: ${rem(15)} 0;
  border-top: 1px solid #ebebeb;
  margin: ${rem(15)} 0;
  width: 100%;
`;

class Login extends React.PureComponent {
  submit = async (data) => {
    try {
      const { token } = await Api.authorize({ data });
      Api.setToken(token);
      cookies.set('access_token', token, {
        expires: 7,
      });
      this.props.dispatch(fetchUser(token));
    } catch (e) {
      console.error(e);
      if (e.details.statusCode === 404) {
        alert(__('Nesprávny email alebo heslo.'));
      }
    }
  };
  render() {
    return (
      <Wrapper>
        <Segment>
          {moment().isAfter(moment('2024-12-05')) &&
          moment().isBefore(moment('2024-12-26')) ? (
            <Message danger title="Objednávky dočasne pozastavené">
              Ospravedlňujeme sa, ale pre zvýšenú vyťaženosť sme nútení
              objednávanie do 26.12.2024 pozastaviť. Za pochopenie Vám ďakujeme.
            </Message>
          ) : (
            <Message
              danger
              title="POZOR - ZMENA CIEN ZA PREPRAVU PLATNÁ OD 1.12.2023"
            >
              Prípadné clo, DPH a poplatky za colné úkony budú účtované navyše v
              prípade, že bude zásielka podliehať colnému konaniu
            </Message>
          )}
        </Segment>
        <ModalWrapper>
          <Modal>
            <FrontModal>
              <Brand src={Logo} />
              <FormWrapper>
                <LoginForm onSubmit={this.submit} />
              </FormWrapper>
              <Buttons>
                <Row>
                  <Col xs={12} s={6}>
                    <Button
                      type="button"
                      success
                      onClick={(e) => {
                        e.preventDefault();
                        this.props.history.push('/register');
                      }}
                    >
                      {__('Registrovať sa')}
                    </Button>
                  </Col>
                  <Col xs={12} s={6}>
                    <Button
                      type="button"
                      danger
                      onClick={(e) => {
                        e.preventDefault();
                        this.props.history.push('/forgot-password');
                      }}
                    >
                      {__('Zabudnuté heslo')}
                    </Button>
                  </Col>
                </Row>
              </Buttons>
            </FrontModal>
            <BackgroundModal>&nbsp;</BackgroundModal>
          </Modal>
        </ModalWrapper>
      </Wrapper>
    );
  }
}

const mapStateToProps = () => ({});

Login.propTypes = {
  dispatch: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default connect(mapStateToProps)(Login);
