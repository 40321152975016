import { __rest } from "tslib";
import * as PropTypes from 'prop-types';
import * as React from 'react';
import InputAutocomplete from './index';
const InputAutocompleteRedux = (props) => {
    const { input, type, id, className, placeholder, meta: { error, touched } } = props, restProps = __rest(props, ["input", "type", "id", "className", "placeholder", "meta"]);
    return (React.createElement(InputAutocomplete, Object.assign({}, input, restProps, { id: id, className: className, placeholder: placeholder, onSelectItem: input.onChange, error: error && touched })));
};
InputAutocompleteRedux.propTypes = {
    className: PropTypes.string,
    id: PropTypes.string,
    placeholder: PropTypes.string,
    input: PropTypes.shape({
        value: PropTypes.string.isRequired,
        onChange: PropTypes.func.isRequired,
    }).isRequired,
};
InputAutocompleteRedux.defaultProps = {
    className: '',
    id: null,
    placeholder: '',
};
export default InputAutocompleteRedux;
