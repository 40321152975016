import { __rest } from "tslib";
import * as React from 'react';
import Select from '../TheSelect';
export default (props) => {
    const { defaultOptions, loadOptions, value, onChange, onBlur } = props, restProps = __rest(props, ["defaultOptions", "loadOptions", "value", "onChange", "onBlur"]);
    const [inputValue, setInputValue] = React.useState('');
    const [optionsByQuery, setOptionsByQuery] = React.useState({});
    const typingDebounce = React.useRef();
    function handleChangeInputValue(newInputValue) {
        setInputValue(newInputValue);
        setOptionsByQuery((prevState) => (Object.assign(Object.assign({}, prevState), { [newInputValue]: Object.assign(Object.assign({}, prevState[newInputValue]), { isFetching: true }) })));
        window.clearTimeout(typingDebounce.current);
        typingDebounce.current = window.setTimeout(() => {
            if (loadOptions) {
                loadOptions(newInputValue).then(loadedOptions => {
                    setOptionsByQuery((prevState) => (Object.assign(Object.assign({}, prevState), { [newInputValue]: Object.assign(Object.assign({}, prevState[newInputValue]), { isFetching: false, options: loadedOptions }) })));
                });
            }
        }, 200);
    }
    let options = defaultOptions || [];
    let isFetching = false;
    if (inputValue) {
        if (optionsByQuery[inputValue]) {
            options = optionsByQuery[inputValue].options || [];
            if (optionsByQuery[inputValue].isFetching) {
                isFetching = true;
            }
        }
    }
    return (React.createElement(Select, Object.assign({ value: value, onChange: onChange, onBlur: () => {
            setInputValue('');
            if (onBlur) {
                onBlur();
            }
        }, loading: isFetching, options: options, onChangeInput: handleChangeInputValue }, restProps)));
};
