import Button from '../../../ui/Button';
import FormField from '../../../ui/FormField';
import Col, { Row } from '../../../ui/Grid';
import Segment from '../../../ui/Segment';
import Select from '../../../ui/Select';
import * as moment from 'moment';
import { rem } from 'polished';
import PropTypes from 'prop-types';
import Message from '../../../ui/Message';
import withQueryHoc, { QueryHocTypes } from '../../../query-hoc/lib';
import * as React from 'react';
import { connect } from 'react-redux';
import { commit, getListNextOffset, initialize, setParams } from 'redux-list';
import { getProp } from '../../../utilities';
import styled, { css, withTheme } from 'styled-components';
import Api from '../../../Api';
import Box from '../../../Components/Box';
import InfoBlock from '../../../Components/InfoBlock';
import { __ } from '../../../utilities';
import { aclSelector } from '../../ApplicationWrapper/selectors';
import { getMyOrders } from '../actions';
import { userDefinition } from '../definitions';
import {
  appUserDataSelector,
  appUserIsFetchingSelector,
  ordersCurrentListSelector,
  ordersStatistics,
} from '../selectors';

export const TableWrapper = styled.div`
  overflow-x: scroll;
`;
export const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
`;
export const Th = styled.th`
  font-size: ${rem(13)};
  text-align: left;
  background: #f5f5f5;
  border-bottom: 1px solid #dedede;
  color: #555555;
  padding: ${rem(10)};
`;
export const Td = styled.td`
  color: #555555;
  padding: ${rem(10)};
  font-size: ${rem(13)};
`;

const Paginator = styled.div`
  display: flex;
`;
const PaginatorArrow = styled.div`
  padding: 8px 16px;
  text-decoration: none;
  transition: background-color 0.3s;
  border: 1px solid #ddd;
  background: white;
  ${({ disabled }) => {
    if (disabled) {
      return css`
        cursor: not-allowed;
        color: #ddd;
      `;
    }
    return css`
      color: black;
      cursor: pointer;
    `;
  }};
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Status = styled.div`
  background: ${({ background }) => background};
  padding: 7px;
  text-align: center;
  color: white;
  font-weight: bold;
  font-size: 13px;
  cursor: ${({ clickable }) => (clickable ? 'pointer' : 'normal')};
`;

export const Tr = styled.tr`
  &:hover {
    cursor: pointer;
    td {
      background: #f5f5f5;
    }
  }
`;

const LIST_LIMIT = 10;
const LIST_NAME = 'ME_ORDERS_LIST';

class Dashboard extends React.PureComponent {
  state = {
    isFetching: false,
    routeStatusFilter: 'WAITING',
    changingRouteStatus: {},
    routes: [],
    orders: {
      WAITING: [],
      ONGOING: [],
      DELIVERED: [],
    },
  };
  componentDidMount() {
    const { dispatch, acl } = this.props;
    dispatch(
      initialize({
        listName: LIST_NAME,
        initialParams: {
          offset: 0,
        },
      }),
    );
    if (acl && acl.adminGetOrders) {
      this.getAdminOrders();
    } else if (acl && acl.meOrders) {
      this.getOrdersList();
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.serializedQuery !== nextProps.serializedQuery) {
      setParams({
        listName: LIST_NAME,
        parameters: nextProps.query,
      });
      this.getOrdersList(nextProps);
    }

    if (
      (!this.props.acl || !this.props.acl.meOrders) &&
      nextProps.acl &&
      nextProps.acl.meOrders
    ) {
      this.getOrdersList(nextProps);
    }

    if (
      (!this.props.acl || !this.props.acl.adminGetOrders) &&
      nextProps.acl &&
      nextProps.acl.adminGetOrders
    ) {
      this.getAdminOrders(nextProps);
    }
  }

  getAdminOrders = async () => {
    this.setState({
      isFetching: true,
    });
    const { orders: WAITING } = await Api.adminGetOrders({ status: 'WAITING' });
    const { orders: ONGOING } = await Api.adminGetOrders({ status: 'ONGOING' });
    // const { orders: DELIVERED } = await Api.adminGetOrders({ status: 'DELIVERED' });
    const { routes } = await Api.getRoutes({
      status: this.state.routeStatusFilter,
    });
    this.setState({
      isFetching: false,
      orders: {
        WAITING,
        ONGOING,
        // DELIVERED
      },
      routes,
    });
  };

  getAdminRoutes = async () => {
    const { routes } = await Api.getRoutes({
      status: this.state.routeStatusFilter,
    });
    this.setState({
      routes,
    });
  };

  getOrdersList = (props = this.props) => {
    const {
      dispatch,
      query: { offset },
    } = props;
    dispatch(
      commit.action({
        listName: LIST_NAME,
        load: async () => {
          const response = await dispatch(getMyOrders(offset, LIST_LIMIT));
          return {
            total: response.total,
            results: response.orders.map((i) => i._id),
            nextOffset: response.next_offset,
          };
        },
      }),
    );
  };

  formatName = (data) =>
    `${data.title ? `${data.title} ` : ''}${data.name} ${data.surname}`;

  stornoOrder = async (orderId) => {
    if (window.confirm(__('Skutočne si želáte stornovať objednávku?'))) {
      await Api.meStornoOrder(orderId);
      this.getOrdersList();
    }
  };

  adminStornoOrder = async (orderId) => {
    if (window.confirm(__('Skutočne si želáte stornovať objednávku?'))) {
      await Api.adminStornoOrder(orderId);
      this.getAdminOrders();
    }
  };

  changeRouteStatus = async (routeId, status) => {
    this.setState({
      changingRouteStatus: {
        ...this.state.changingRouteStatus,
        [routeId]: true,
      },
    });
    await Api.changeRouteStatus(routeId, status);
    await this.getAdminOrders();
    this.setState({
      changingRouteStatus: {
        ...this.state.changingRouteStatus,
        [routeId]: false,
      },
    });
  };

  renderStatus = (status) => {
    switch (status) {
      case 'WAITING':
        return (
          <Status background={this.props.theme.color.warning}>
            {__('Čaká na prevzatie')}
          </Status>
        );
      case 'ONGOING':
        return (
          <Status background={this.props.theme.color.primary}>
            {__('V preprave')}
          </Status>
        );
      case 'DELIVERED':
        return (
          <Status background={this.props.theme.color.success}>
            {__('Doručená')}
          </Status>
        );
      case 'STORNO':
        return (
          <Status background={this.props.theme.color.danger}>
            {__('Stornovaná')}
          </Status>
        );
      default:
        throw new Error(__('Neznámy stav objednávky'));
    }
  };

  renderTable = (orders) => {
    return (
      <Table>
        <thead>
          <Tr>
            <Th>{__('Dátum zaevidovania')}</Th>
            <Th>{__('Orientačný dátum prevzatia')}</Th>
            <Th>{__('Odosielateľ')}</Th>
            <Th>{__('Prijímateľ')}</Th>
            <Th />
          </Tr>
        </thead>
        <tbody>
          {orders.map((order) => (
            <Tr
              onClick={(e) => {
                e.stopPropagation();
                this.props.history.push(`/order/${order._id}`);
              }}
            >
              <Td>{moment(order.createdDate).format('DD.MM.YYYY o HH:mm')}</Td>
              <Td>{moment(order.delivery.date).format('DD.MM.YYYY')}</Td>
              <Td>
                {`${order.user.name} ${order.user.surname}`}
                <br />
                <a
                  onClick={(e) => e.stopPropagation()}
                  href={`tel:${order.user.phone}`}
                >
                  {order.user.phone}
                </a>
                <br />
                <b>ID: {order.user.identifier}</b>
                <br />
                {!!order.address && order.address.address}
              </Td>
              <Td>
                {`${order.recipient.name} ${order.recipient.surname}`}
                <br />
                <a
                  onClick={(e) => e.stopPropagation()}
                  href={`tel:${order.recipient.phone}`}
                >
                  {order.recipient.phone}
                </a>
                <br />
                {order.recipient.address.formatted_address}
              </Td>
              <Td>
                {order.status === 'WAITING' && (
                  <Status
                    onClick={(e) => {
                      e.stopPropagation();
                      this.adminStornoOrder(order._id);
                    }}
                    clickable
                    background={this.props.theme.color.danger}
                  >
                    {__('Stornovať')}
                  </Status>
                )}
              </Td>
            </Tr>
          ))}
        </tbody>
      </Table>
    );
  };

  render() {
    const {
      profile = {},
      nextOffset,
      query,
      statistics,
      isFetchingProfile = false,
      orders,
      theme,
      acl,
    } = this.props;
    return (
      <Wrapper>
        {profile.role === 'client' &&
        moment().isAfter(moment('2024-12-05')) &&
        moment().isBefore(moment('2024-12-26')) ? (
          <Segment noBottomGutter>
            <Message danger title="Objednávky dočasne pozastavené">
              Ospravedlňujeme sa, ale pre zvýšenú vyťaženosť sme nútení
              objednávanie do 26.12.2024 pozastaviť. Za pochopenie Vám ďakujeme.
            </Message>
          </Segment>
        ) : (
          <React.Fragment>
            <Segment>
              <div
                style={{
                  padding: '20px 10px',
                  background: theme.color.warning,
                  color: 'white',
                  textAlign: 'center',
                  marginBottom: '10px',
                  fontSize: '17px',
                  borderRadius: '5px',
                }}
              >
                Upozorňujeme Vás, že <b>NEPREVÁŽAME</b> cigarety a alkohol
              </div>
              <div
                style={{
                  padding: '20px 10px',
                  background: theme.color.danger,
                  color: 'white',
                  textAlign: 'center',
                  marginBottom: '10px',
                  fontSize: '17px',
                  borderRadius: '5px',
                }}
              >
                <div>POZOR - ZMENA CIEN ZA PREPRAVU PLATNÁ OD 1.12.2023</div>
                <div style={{ fontSize: '13px' }}>
                  Prípadné clo, DPH a poplatky za colné úkony budú účtované
                  navyše v prípade, že bude zásielka podliehať colnému konaniu
                </div>
              </div>
              <Box
                closable
                header={
                  <div>
                    <div>
                      Nový cenník prepravných služieb platný od 1.12.2023
                    </div>
                    <div
                      style={{ textTransform: 'uppercase', fontSize: '12px' }}
                    >
                      Poplatky za colné úkony
                    </div>
                  </div>
                }
                color={theme.color.primary}
              >
                <Row>
                  <Col xs={12}>
                    <Table>
                      <tbody>
                        <tr>
                          <Td colSpan={2}>
                            <b>
                              Prípadné clo a DPH budú vyrúbené colnicou v
                              prípade, že bude zásielka podliehať colnému
                              konaniu. Balík bude uvoľnený k doručeniu po úhrade
                              colného dlhu.
                            </b>
                          </Td>
                        </tr>
                        <tr>
                          <Td colSpan={2}>
                            <b>Poplatky za colné úkony:</b>
                          </Td>
                        </tr>
                        <Tr>
                          <Td>Hodnota zásielky do 150 EUR</Td>
                          <Td>10 GBP / 12 EUR</Td>
                        </Tr>
                        <Tr>
                          <Td>Hodnota zásielky od 151 EUR do 700 EUR</Td>
                          <Td>20 GBP / 24 EUR</Td>
                        </Tr>
                        <Tr>
                          <Td>Hodnota zásielky od 701 EUR do 23 000 EUR</Td>
                          <Td>70 GBP / 84 EUR</Td>
                        </Tr>
                        <Tr>
                          <Td>
                            Zmena alebo zrušenie colnej deklarácie po jej
                            vystavení
                          </Td>
                          <Td>20 GBP / 24 EUR</Td>
                        </Tr>
                        <Tr>
                          <Td>V cene sú zahrnuté max. 2 položky.</Td>
                          <Td>
                            Každá ďalšia položka + 2 GBP / 2,50 EUR (zatiaľ
                            neúčtujeme)
                          </Td>
                        </Tr>
                      </tbody>
                    </Table>
                  </Col>
                </Row>
              </Box>
              <Box
                closable
                header={
                  <div>
                    <div>
                      Cenník pre hlavnú trasu Folkestone-London-Coventry a
                      blízke okolie
                    </div>
                    <div
                      style={{ textTransform: 'uppercase', fontSize: '12px' }}
                    >
                      Minimálna cena zásielky - 30 GBP
                    </div>
                  </div>
                }
                color={theme.color.primary}
              >
                <Row>
                  <Col xs={12}>
                    <Table>
                      <tbody>
                        <tr>
                          <Td colSpan={2}>
                            <b>Balíky</b>
                          </Td>
                        </tr>
                        <Tr>
                          <Td>minimálna cena zásielky</Td>
                          <Td>30 GBP (pri váhe do 18kg vrátane)</Td>
                        </Tr>
                        <Tr>
                          <Td>
                            bežné balíkové zásielky (do váhy 200kg vrátane)
                          </Td>
                          <Td>1,80 GBP za 1kg</Td>
                        </Tr>
                        <Tr>
                          <Td>bežné balíkové zásielky (pri váhe nad 200kg)</Td>
                          <Td> 1,50 GBP za 1kg</Td>
                        </Tr>
                        <tr>
                          <Td colSpan={2}>
                            <b>Televízory</b>
                          </Td>
                        </tr>
                        <Tr>
                          <Td>Televízor - do 40″</Td>
                          <Td>40 £</Td>
                        </Tr>
                        <Tr>
                          <Td>Televízor - nad 40″</Td>
                          <Td>50 £</Td>
                        </Tr>
                        <tr>
                          <Td colSpan={2}>
                            <b>Matrace</b>
                          </Td>
                        </tr>
                        <Tr>
                          <Td>Matrac Single</Td>
                          <Td>45 £</Td>
                        </Tr>
                        <Tr>
                          <Td>Matrac Double</Td>
                          <Td>65 £</Td>
                        </Tr>
                        <Tr>
                          <Td>Matrac King Size</Td>
                          <Td>85 £</Td>
                        </Tr>
                        <Tr>
                          <Td>Matrac King Size Double</Td>
                          <Td>95 £</Td>
                        </Tr>
                        <tr>
                          <Td colSpan={2}>
                            <b>Bicykle</b>
                          </Td>
                        </tr>
                        <Tr>
                          <Td>Detský bicykel</Td>
                          <Td>20 £</Td>
                        </Tr>
                        <Tr>
                          <Td>Juniorský bicykel</Td>
                          <Td>35 £</Td>
                        </Tr>
                        <Tr>
                          <Td>Bicykel</Td>
                          <Td>45 £</Td>
                        </Tr>
                        <Tr>
                          <Td>E-Bike, elektrické autíčka</Td>
                          <Td>60 £</Td>
                        </Tr>
                        <tr>
                          <Td colSpan={2}>
                            <b>
                              Motocykle - tovar podlieha colnému konaniu, ktoré
                              musí zabezpečiť odosielateľ.
                            </b>
                          </Td>
                        </tr>
                        <Tr>
                          <Td>Motocykel do 125 cm3</Td>
                          <Td>podľa váhy</Td>
                        </Tr>
                        <Tr>
                          <Td>Motocykel 125-249 cm3</Td>
                          <Td>270 £</Td>
                        </Tr>
                        <Tr>
                          <Td>Motocykel 250-599 cm3</Td>
                          <Td>330 £</Td>
                        </Tr>
                        <Tr>
                          <Td>Motocykel 600-999 cm3</Td>
                          <Td>400 £</Td>
                        </Tr>
                        <Tr>
                          <Td>Motocykel nad 1000 cm3</Td>
                          <Td>500 £</Td>
                        </Tr>
                        <tr>
                          <Td colSpan={2}>
                            <b>Spotrebiče</b>
                          </Td>
                        </tr>
                        <Tr>
                          <Td>
                            Kuchynské spotrebiče/biela technika (bežná
                            chladnička, pračka, sušička, umývačka riadu, sporák)
                          </Td>
                          <Td>75 £ za kus</Td>
                        </Tr>
                        <Tr>
                          <Td>Americká chladnička</Td>
                          <Td>150 £</Td>
                        </Tr>
                        <tr>
                          <Td colSpan={2}>
                            <b>Objemný náklad</b>
                          </Td>
                        </tr>
                        <Tr>
                          <Td>
                            Skrine a iné objemné veci (cena platí aj pre objemné
                            veci nízkej váhy)
                          </Td>
                          <Td>90 £/1m3</Td>
                        </Tr>
                        <Tr>
                          <Td>Stoličky</Td>
                          <Td>12 £/ks</Td>
                        </Tr>
                        <tr>
                          <Td colSpan={2}>
                            <b>Sťahovanie</b>
                          </Td>
                        </tr>
                        <Tr>
                          <Td>
                            Sťahovanie - 5-paletová skriňová dodávka - 17 m3 /
                            1100kg max
                            <div
                              style={{ fontSize: '12px', fontStyle: 'italic' }}
                            >
                              (v cenách sú už zahrnuté poplatky za colné úkony)
                            </div>
                          </Td>
                          <Td>1350 £</Td>
                        </Tr>
                        <Tr>
                          <Td>
                            Sťahovanie - 8-paletová plachtová dodávka - 21 m3 /
                            1100kg max
                            <div
                              style={{ fontSize: '12px', fontStyle: 'italic' }}
                            >
                              (v cenách sú už zahrnuté poplatky za colné úkony)
                            </div>
                          </Td>
                          <Td>1450 £</Td>
                        </Tr>
                        <Tr>
                          <Td>
                            Sťahovanie - 10-paletová plachtová dodávka - 25 m3 /
                            1100kg max
                            <div
                              style={{ fontSize: '12px', fontStyle: 'italic' }}
                            >
                              (v cenách sú už zahrnuté poplatky za colné úkony)
                            </div>
                          </Td>
                          <Td>1550 £</Td>
                        </Tr>
                      </tbody>
                    </Table>
                  </Col>
                </Row>
              </Box>
              <Box
                closable
                header={
                  <div>
                    <div>
                      Cenník pre vzdialenejšie destinácie - na základe dohody a
                      dostupnosti áut v danej oblasti
                    </div>
                    <div
                      style={{ textTransform: 'uppercase', fontSize: '12px' }}
                    >
                      Minimálna cena zásielky - 55 GBP
                    </div>
                  </div>
                }
                color={theme.color.primary}
              >
                <Row>
                  <Col xs={12}>
                    <Table>
                      <tbody>
                        <tr>
                          <Td colSpan={2}>
                            <b>Balíky</b>
                          </Td>
                        </tr>
                        <Tr>
                          <Td>minimálna cena zásielky</Td>
                          <Td>55 GBP (pri váhe do 25kg vrátane)</Td>
                        </Tr>
                        <Tr>
                          <Td>
                            bežné balíkové zásielky (do váhy 200kg vrátane)
                          </Td>
                          <Td>2,00 GBP za 1kg</Td>
                        </Tr>
                        <Tr>
                          <Td>bežné balíkové zásielky (pri váhe nad 200kg)</Td>
                          <Td>1,80 GBP za 1kg</Td>
                        </Tr>
                        <tr>
                          <Td colSpan={2}>
                            <b>Televízory</b>
                          </Td>
                        </tr>
                        <Tr>
                          <Td>Televízor - do 40″</Td>
                          <Td>50 £</Td>
                        </Tr>
                        <Tr>
                          <Td>Televízor - nad 40″</Td>
                          <Td>65 £</Td>
                        </Tr>
                        <tr>
                          <Td colSpan={2}>
                            <b>Matrace</b>
                          </Td>
                        </tr>
                        <Tr>
                          <Td>Matrac Single</Td>
                          <Td>55 £</Td>
                        </Tr>
                        <Tr>
                          <Td>Matrac Double</Td>
                          <Td>70 £</Td>
                        </Tr>
                        <Tr>
                          <Td>Matrac King Size</Td>
                          <Td>95 £</Td>
                        </Tr>
                        <Tr>
                          <Td>Matrac King Size Double</Td>
                          <Td>105 £</Td>
                        </Tr>
                        <tr>
                          <Td colSpan={2}>
                            <b>Bicykle</b>
                          </Td>
                        </tr>
                        <Tr>
                          <Td>Detský bicykel</Td>
                          <Td>25 £</Td>
                        </Tr>
                        <Tr>
                          <Td>Juniorský bicykel</Td>
                          <Td>35 £</Td>
                        </Tr>
                        <Tr>
                          <Td>Bicykel</Td>
                          <Td>50 £</Td>
                        </Tr>
                        <Tr>
                          <Td>E-Bike, elektrické autíčka</Td>
                          <Td>65 £</Td>
                        </Tr>
                        <tr>
                          <Td colSpan={2}>
                            <b>
                              Motocykle - tovar podlieha colnému konaniu, ktoré
                              musí zabezpečiť odosielateľ.
                            </b>
                          </Td>
                        </tr>
                        <Tr>
                          <Td>Motocykel do 125 cm3</Td>
                          <Td>podľa váhy</Td>
                        </Tr>
                        <Tr>
                          <Td>Motocykel 125-249 cm3</Td>
                          <Td>300 £</Td>
                        </Tr>
                        <Tr>
                          <Td>Motocykel 250-599 cm3</Td>
                          <Td>350 £</Td>
                        </Tr>
                        <Tr>
                          <Td>Motocykel 600-999 cm3</Td>
                          <Td>440 £</Td>
                        </Tr>
                        <Tr>
                          <Td>Motocykel nad 1000 cm3</Td>
                          <Td>540 £</Td>
                        </Tr>
                        <tr>
                          <Td colSpan={2}>
                            <b>Spotrebiče</b>
                          </Td>
                        </tr>
                        <Tr>
                          <Td>
                            Kuchynské spotrebiče/biela technika (bežná
                            chladnička, pračka, sušička, umývačka riadu, sporák)
                          </Td>
                          <Td>90 £ za kus</Td>
                        </Tr>
                        <Tr>
                          <Td>Americká chladnička</Td>
                          <Td>160 £</Td>
                        </Tr>
                        <tr>
                          <Td colSpan={2}>
                            <b>Objemný náklad</b>
                          </Td>
                        </tr>
                        <Tr>
                          <Td>
                            Skrine a iné objemné veci (cena platí aj pre objemné
                            veci nízkej váhy)
                          </Td>
                          <Td>100 £/1m3</Td>
                        </Tr>
                        <Tr>
                          <Td>Stoličky</Td>
                          <Td>16 £/ks</Td>
                        </Tr>
                        <tr>
                          <Td colSpan={2}>
                            <b>Sťahovanie</b>
                          </Td>
                        </tr>
                        <Tr>
                          <Td>
                            Sťahovanie - 5-paletová skriňová dodávka - 17 m3 /
                            1100kg max
                            <div
                              style={{ fontSize: '12px', fontStyle: 'italic' }}
                            >
                              (v cenách sú už zahrnuté poplatky za colné úkony)
                            </div>
                          </Td>
                          <Td>1400 £</Td>
                        </Tr>
                        <Tr>
                          <Td>
                            Sťahovanie - 8-paletová plachtová dodávka - 21 m3 /
                            1100kg max
                            <div
                              style={{ fontSize: '12px', fontStyle: 'italic' }}
                            >
                              (v cenách sú už zahrnuté poplatky za colné úkony)
                            </div>
                          </Td>
                          <Td>1500 £</Td>
                        </Tr>
                        <Tr>
                          <Td>
                            Sťahovanie - 10-paletová plachtová dodávka - 25 m3 /
                            1100kg max
                            <div
                              style={{ fontSize: '12px', fontStyle: 'italic' }}
                            >
                              (v cenách sú už zahrnuté poplatky za colné úkony)
                            </div>
                          </Td>
                          <Td>1600 £</Td>
                        </Tr>
                      </tbody>
                    </Table>
                  </Col>
                </Row>
              </Box>
            </Segment>
          </React.Fragment>
        )}
        {profile.role === 'client' && (
          <Segment>
            <Row>
              <Col xs={12} s={6} m={3}>
                <InfoBlock
                  color={theme.color.success}
                  icon="check"
                  count={getProp(statistics || {}, ['DELIVERED'], 0)}
                  label={__('doručených zásielok')}
                />
              </Col>
              <Col xs={12} s={6} m={3}>
                <InfoBlock
                  color={theme.color.warning}
                  icon="clock"
                  count={getProp(statistics || {}, ['WAITING'], 0)}
                  label={__('čakajúcich zásielok')}
                />
              </Col>
              <Col xs={12} s={6} m={3}>
                <InfoBlock
                  color={theme.color.primary}
                  icon="truck"
                  count={getProp(statistics || {}, ['ONGOING'], 0)}
                  label={__('zásielok v preprave')}
                />
              </Col>
              <Col xs={12} s={6} m={3}>
                <InfoBlock
                  color={theme.color.danger}
                  icon="close"
                  count={getProp(statistics || {}, ['STORNO'], 0)}
                  label={__('stornovaných zásielok')}
                />
              </Col>
            </Row>
          </Segment>
        )}
        <Segment>
          <Box
            color={({ theme }) => theme.color.primary}
            header={__('Môj profil')}
            loading={isFetchingProfile}
            buttons={[
              <Button
                primary
                onClick={() => {
                  this.props.history.push('/me/profile');
                }}
                icon="pencil"
              >
                {__('Upraviť profil')}
              </Button>,
            ]}
          >
            <Row>
              <Col xs={12} s={2}>
                <FormField label={__('Titul')} readOnly value={profile.title} />
              </Col>
              <Col xs={12} s={5}>
                <FormField label={__('Meno')} readOnly value={profile.name} />
              </Col>
              <Col xs={12} s={5}>
                <FormField
                  label={__('Priezvisko')}
                  readOnly
                  value={profile.surname}
                />
              </Col>
              <Col xs={12} s={5}>
                <FormField
                  label={__('E-mail')}
                  readOnly
                  value={profile.email}
                />
              </Col>
              <Col xs={12} s={5}>
                <FormField
                  label={__('Telefónne číslo')}
                  readOnly
                  value={profile.phone}
                />
              </Col>
            </Row>
          </Box>
        </Segment>
        {!!acl && !!acl.getRoutes && (
          <React.Fragment>
            <Segment>
              <Box
                loading={this.state.isFetching}
                buttons={
                  !!acl &&
                  !!acl.postRoute && [
                    <div style={{ display: 'flex' }}>
                      <Select
                        onChange={(e) => {
                          this.setState(
                            {
                              routeStatusFilter: e.target.value,
                            },
                            () => {
                              this.getAdminRoutes();
                            },
                          );
                        }}
                        value={this.state.routeStatusFilter}
                      >
                        <option value="WAITING">{__('Prebiehajúce')}</option>
                        <option value="DELIVERED">{__('Dokončené')}</option>
                      </Select>
                      &nbsp;
                      <Button
                        onClick={() => {
                          this.props.history.push('/route');
                        }}
                        icon="plus"
                        success
                      >
                        {__('Pridať trasu')}
                      </Button>
                    </div>,
                  ]
                }
                color={({ theme }) => theme.color.success}
                header={__('Trasy')}
              >
                <TableWrapper>
                  <Table>
                    <thead>
                      <Tr>
                        <Th>{__('Dátum')}</Th>
                        <Th>{__('Vodič')}</Th>
                        <Th width={200} />
                      </Tr>
                    </thead>
                    <tbody>
                      {this.state.routes.map((route) => (
                        <Tr
                          onClick={(e) => {
                            e.stopPropagation();
                            this.props.history.push(`/route/${route._id}`);
                          }}
                        >
                          <Td>{moment(route.date).format('DD.MM.YYYY')}</Td>
                          <Td>{`${route.driver ? route.driver.name : ''} ${
                            route.driver ? route.driver.surname : ''
                          }`}</Td>
                          <Td>
                            {!!acl &&
                              !!acl.changeRouteStatus &&
                              this.state.routeStatusFilter === 'WAITING' && (
                                <Button
                                  loading={
                                    !!this.state.changingRouteStatus[route._id]
                                  }
                                  type="button"
                                  icon="check"
                                  success
                                  onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    if (
                                      window.confirm(
                                        __(
                                          'Skutočne si želáte označiť trasu ako ukončenú?',
                                        ),
                                      )
                                    ) {
                                      this.changeRouteStatus(
                                        route._id,
                                        'DELIVERED',
                                      );
                                    }
                                  }}
                                >
                                  {__('Označiť ako ukončenú')}
                                </Button>
                              )}
                          </Td>
                        </Tr>
                      ))}
                    </tbody>
                  </Table>
                </TableWrapper>
              </Box>
            </Segment>
            {!!this.state.orders.WAITING.length && (
              <Segment>
                <Box
                  color={({ theme }) => theme.color.warning}
                  header={__('Nepriradené zásielky')}
                >
                  <TableWrapper>
                    {this.renderTable(
                      this.state.orders.WAITING.filter((i) => !i.routeId),
                    )}
                  </TableWrapper>
                </Box>
              </Segment>
            )}
            {!!this.state.orders.WAITING.length && (
              <Segment>
                <Box
                  color={({ theme }) => theme.color.danger}
                  header={__('Čakajúce zásielky')}
                >
                  <TableWrapper>
                    {this.renderTable(
                      this.state.orders.WAITING.filter((i) => i.routeId),
                    )}
                  </TableWrapper>
                </Box>
              </Segment>
            )}
            {!!this.state.orders.ONGOING.length && (
              <Segment>
                <Box
                  color={({ theme }) => theme.color.primary}
                  header={__('Zásielky v preprave')}
                >
                  <TableWrapper>
                    {this.renderTable(this.state.orders.ONGOING)}
                  </TableWrapper>
                </Box>
              </Segment>
            )}
            {/* {!!this.state.orders.DELIVERED.length && (
              <Segment>
                <Box
                  color={({ theme }) => theme.color.success} header={__("Doručené zásielky")}>
                  <TableWrapper>
                    {this.renderTable(this.state.orders.DELIVERED)}
                  </TableWrapper>
                </Box>
              </Segment>
            )} */}
          </React.Fragment>
        )}
        {(!acl || (!!acl && !acl.adminGetOrders)) && (
          <Segment>
            <Box
              color={({ theme }) => theme.color.success}
              header={__('Zoznam zásielok')}
              buttons={[
                <Button
                  onClick={() => {
                    this.props.history.push('/order');
                  }}
                  icon="plus"
                  success
                  disabled={
                    profile.role === 'client' &&
                    moment().isAfter(moment('2024-12-05')) &&
                    moment().isBefore(moment('2024-12-26'))
                  }
                >
                  {__('Pridať zásielku')}
                </Button>,
              ]}
            >
              {!!orders.length && (
                <React.Fragment>
                  <TableWrapper>
                    <Table>
                      <thead>
                        <Tr>
                          <Th>{__('Dátum zaevidovania')}</Th>
                          <Th>{__('Príjemca')}</Th>
                          <Th>{__('Cena')}</Th>
                          <Th>{__('Dátum prevzatia')}</Th>
                          <Th>{__('Dátum dodania')}</Th>
                          <Th>{__('Stav')}</Th>
                          <Th />
                        </Tr>
                      </thead>
                      <tbody>
                        {orders.map((order) => (
                          <Tr
                            onClick={(e) => {
                              e.stopPropagation();
                              this.props.history.push(`/order/${order._id}`);
                            }}
                          >
                            <Td>
                              {moment(order.createdDate).format(
                                'DD.MM.YYYY o HH:mm',
                              )}
                            </Td>
                            <Td>{this.formatName(order.recipient)}</Td>
                            <Td />
                            <Td />
                            <Td />
                            <Td>{this.renderStatus(order.status)}</Td>
                            <Td>
                              {order.status === 'WAITING' && (
                                <Status
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    this.stornoOrder(order._id);
                                  }}
                                  clickable
                                  background={theme.color.danger}
                                >
                                  {__('Stornovať')}
                                </Status>
                              )}
                            </Td>
                          </Tr>
                        ))}
                      </tbody>
                    </Table>
                  </TableWrapper>
                  <Paginator>
                    <PaginatorArrow
                      onClick={() => {
                        if (query.offset > 0) {
                          this.props.setParameter({
                            offset: query.offset - LIST_LIMIT,
                          });
                        }
                      }}
                      disabled={query.offset === 0}
                    >
                      ❮
                    </PaginatorArrow>
                    <PaginatorArrow
                      onClick={() => {
                        if (nextOffset) {
                          this.props.setParameter({ offset: nextOffset });
                        }
                      }}
                      disabled={!nextOffset}
                    >
                      ❯
                    </PaginatorArrow>
                  </Paginator>
                </React.Fragment>
              )}
            </Box>
          </Segment>
        )}
        <div style={{ width: '1px' }} />
      </Wrapper>
    );
  }
}

Dashboard.propTypes = {
  dispatch: PropTypes.func.isRequired,
  profile: userDefinition.isRequired,
  isFetchingProfile: PropTypes.bool.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  profile: appUserDataSelector(state),
  isFetchingProfile: appUserIsFetchingSelector(state),
  orders: ordersCurrentListSelector(LIST_NAME)(state),
  nextOffset: getListNextOffset(LIST_NAME)(state),
  statistics: ordersStatistics(state),
  acl: aclSelector(state),
});

export default connect(mapStateToProps)(
  withQueryHoc({
    parameters: {
      offset: {
        type: QueryHocTypes.Number,
        defaultValue: 0,
      },
    },
  })(withTheme(Dashboard)),
);
