import { __rest } from "tslib";
import * as React from 'react';
import styled from '../Themes/styled-components';
const Label = styled.label `
  display: flex;
  align-items: center;
`;
const Input = styled.input `
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;

  &:checked + span {
    background-color: #4a9dff;

    &::before {
      transform: translate(16px, -50%);
    }
  }

  &:disabled + span {
    opacity: 0.5;
    cursor: not-allowed;

    &::before {
      background-color: rgba(32, 37, 50, 0.14);
    }
  }
`;
const Toggle = styled.span `
  box-sizing: border-box;
  margin: 0 9px;
  width: 36px;
  height: 22px;
  border-radius: 30px;
  background-color: rgba(32, 37, 50, 0.14);
  box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.2);
  display: inline-block;
  position: relative;
  cursor: pointer;

  &::before {
    transition: transform 75ms ease-in-out;
    content: '';
    display: block;
    width: 18px;
    height: 18px;
    background-color: #fff;
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.25),
      0 2px 8px 0 rgba(32, 37, 50, 0.16);
    position: absolute;
    border-radius: 100%;
    top: 11px;
    transform: translate(2px, -50%);
  }
`;
class Switch extends React.PureComponent {
    render() {
        const _a = this.props, { checked, disabled } = _a, restProps = __rest(_a, ["checked", "disabled"]);
        return (React.createElement(Label, null,
            React.createElement(Input, Object.assign({}, restProps, { type: "checkbox", checked: checked, disabled: disabled })),
            React.createElement(Toggle, null)));
    }
}
export default Switch;
