import { rem } from 'polished';
import * as React from 'react';
import styled from '../Themes/styled-components';
const Wrapper = styled.div `
  font-size: ${rem(14)};
  margin-top: ${rem(2)};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
const Breadcrumb = styled('span') `
  color: ${({ theme, active }) => active ? theme.textColor : theme.inactiveColor};
  a {
    cursor: pointer;
    color: ${({ theme, active }) => active ? theme.textColor : theme.inactiveColor};
    text-decoration: none;
  }
`;
class Breadcrumbs extends React.PureComponent {
    render() {
        const { crumbs } = this.props;
        const numberOfCrumbs = crumbs.length;
        if (numberOfCrumbs === 0) {
            return null;
        }
        return (React.createElement(Wrapper, null, crumbs.map((crumb, idx) => (React.createElement(Breadcrumb, { key: idx.toString(), active: idx === numberOfCrumbs - 1 },
            crumb,
            idx !== numberOfCrumbs - 1 && ' / ')))));
    }
}
export default Breadcrumbs;
