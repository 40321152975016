export const MAX_SIZE = 2000;
export const normalizePhotoDimensions = (dimensions, naturalWidth, naturalHeight) => {
    if (naturalHeight === null || naturalWidth === null) {
        return dimensions;
    }
    if (naturalHeight > MAX_SIZE || naturalWidth > MAX_SIZE) {
        const normalizedDimensions = Object.assign({}, dimensions);
        let MAX_WIDTH = MAX_SIZE;
        let MAX_HEIGHT = MAX_SIZE;
        if (naturalWidth >= naturalHeight) {
            const ratio = naturalHeight / naturalWidth;
            MAX_HEIGHT = MAX_SIZE * ratio;
        }
        else {
            const ratio = naturalWidth / naturalHeight;
            MAX_WIDTH = MAX_SIZE * ratio;
        }
        // potrebujeme urobit prepocet, inak vyrez nebude sediet
        normalizedDimensions.x = Math.round((normalizedDimensions.x * naturalWidth) / MAX_WIDTH);
        normalizedDimensions.y = Math.round((normalizedDimensions.y * naturalHeight) / MAX_HEIGHT);
        normalizedDimensions.width = Math.round((normalizedDimensions.width * naturalWidth) / MAX_WIDTH);
        normalizedDimensions.height = Math.round((normalizedDimensions.height * naturalHeight) / MAX_HEIGHT);
        return normalizedDimensions;
    }
    return dimensions;
};
export const deNormalizePhotoDimensions = (dimensions, naturalWidth, naturalHeight) => {
    if (naturalHeight === null || naturalWidth === null) {
        return dimensions;
    }
    if (naturalHeight > MAX_SIZE || naturalWidth > MAX_SIZE) {
        const normalizedDimensions = Object.assign({}, dimensions);
        let MAX_WIDTH = MAX_SIZE;
        let MAX_HEIGHT = MAX_SIZE;
        if (naturalWidth >= naturalHeight) {
            const ratio = naturalHeight / naturalWidth;
            MAX_HEIGHT = MAX_SIZE * ratio;
        }
        else {
            const ratio = naturalWidth / naturalHeight;
            MAX_WIDTH = MAX_SIZE * ratio;
        }
        // potrebujeme urobit prepocet, inak vyrez nebude sediet
        normalizedDimensions.x = Math.round((normalizedDimensions.x / naturalWidth) * MAX_WIDTH);
        normalizedDimensions.y = Math.round((normalizedDimensions.y / naturalHeight) * MAX_HEIGHT);
        normalizedDimensions.width = Math.round((normalizedDimensions.width / naturalWidth) * MAX_WIDTH);
        normalizedDimensions.height = Math.round((normalizedDimensions.height / naturalHeight) * MAX_HEIGHT);
        return normalizedDimensions;
    }
    return dimensions;
};
