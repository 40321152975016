import FormField from '../../ui/FormField/redux-form';
import Col, { Row } from '../../ui/Grid';
import PropTypes from 'prop-types';
import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { __ } from '../../utilities';
import { isRequired } from '../../validation';

export const FORM_NAME = 'ROUTE_FORM';

class RouteForm extends React.PureComponent {
  render() {
    return (
      <Row>
        <Col xs={12}>
          <Field
            component={FormField}
            name="date"
            type="date"
            label={__('Dátum odchodu')}
            validate={[isRequired]}
          />
        </Col>
        <Col xs={12}>
          <Field
            component={FormField}
            name="driverId"
            type="select"
            label={__('Vodič')}
            validate={[isRequired]}
          >
            <option hidden />
            {this.props.drivers.map((driver) => (
              <option
                value={driver._id}
              >{`${driver.name} ${driver.surname}`}</option>
            ))}
          </Field>
        </Col>
      </Row>
    );
  }
}

RouteForm.propTypes = {
  drivers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default reduxForm({
  form: FORM_NAME,
  enableReinitialize: true,
})(RouteForm);
