import { __rest } from "tslib";
import { em, rem } from 'polished';
import * as React from 'react';
import Icon from '../Icon';
import Separator from '../Separator';
import styled, { css } from '../Themes/styled-components';
import { createSizer } from '../utilities';
const getIconSize = createSizer({
    xs: 20,
    s: 24,
    m: 28,
    l: 32,
    xl: 36,
}, 28);
const commonStyle = (theme) => `
  padding: 0;
  margin: 0;
  color: ${theme.textColor};
  font-weight: 500;
`;
// H1
const StyledH1 = styled.h1 `
  ${({ theme }) => commonStyle(theme)}
  font-size: ${rem(32)};
`;
// H2
const StyledH2 = styled.h2 `
  ${({ theme }) => commonStyle(theme)}
  font-size: ${rem(28)};
`;
// H3
const StyledH3 = styled.h3 `
  ${({ theme }) => commonStyle(theme)}
  font-size: ${rem(24)};
`;
// H4
const StyledH4 = styled.h4 `
  ${({ theme }) => commonStyle(theme)}
  font-size: ${rem(20)};
`;
// H5
const StyledH5 = styled.h5 `
  ${({ theme }) => commonStyle(theme)}
  font-size: ${rem(16)};
`;
const Wrapper = styled('div') `
  ${({ withSeparator, noBottomGutter }) => {
    if (!withSeparator && !noBottomGutter) {
        return css `
        margin-bottom: ${rem(15)};
      `;
    }
    return css ``;
}}
  display: flex;
  width: 100%;
`;
const InnerWrapper = styled.div `
  width: 100%;
`;
const InnerWrapperContext = styled.div `
  display: flex;
`;
const InnerWrapperIcon = styled.div `
  display: flex;
  align-items: center;
`;
const InnerWrapperHeader = styled.div `
  width: 100%;
`;
const StyledIcon = styled(Icon) `
  margin-right: ${rem(16)};
`;
const Subtitle = styled('div') `
  ${({ size }) => {
    switch (size) {
        case 'xs': {
            return css `
          font-size: ${rem(12)};
        `;
        }
        case 's': {
            return css `
          font-size: ${rem(13)};
        `;
        }
        case 'm': {
            return css `
          font-size: ${rem(14)};
        `;
        }
        case 'l': {
            return css `
          font-size: ${rem(16)};
        `;
        }
        case 'xl': {
            return css `
          font-size: ${rem(20)};
        `;
        }
        default: {
            return css `
          font-size: ${rem(26)};
        `;
        }
    }
}}
`;
const StyledSeparator = styled(Separator) `
  margin-bottom: ${em(4)};
  margin-top: ${em(4)};
`;
const SeparatorSizer = styled.div `
  padding: ${rem(5)} 0;
  display: flex;
  ${({ size }) => {
    switch (size) {
        case 'xs': {
            return css `
          font-size: ${rem(16)};
        `;
        }
        case 's': {
            return css `
          font-size: ${rem(20)};
        `;
        }
        case 'l': {
            return css `
          font-size: ${rem(24)};
        `;
        }
        case 'xl': {
            return css `
          font-size: ${rem(28)};
        `;
        }
        default: {
            return css `
          font-size: ${rem(32)};
        `;
        }
    }
}}
`;
const SizedHeader = ({ size, className, children, }) => {
    switch (size) {
        case 'xs': {
            return React.createElement(StyledH5, { className: className }, children);
        }
        case 's': {
            return React.createElement(StyledH4, { className: className }, children);
        }
        case 'l': {
            return React.createElement(StyledH2, { className: className }, children);
        }
        case 'xl': {
            return React.createElement(StyledH1, { className: className }, children);
        }
        default: {
            return React.createElement(StyledH3, { className: className }, children);
        }
    }
};
const defaultProps = {
    size: 'm',
    className: '',
    children: '',
    withSeparator: false,
    noBottomGutter: false,
};
class Header extends React.PureComponent {
    render() {
        const _a = this.props, { size, className, children, icon, subtitle, withSeparator, noBottomGutter } = _a, restProps = __rest(_a, ["size", "className", "children", "icon", "subtitle", "withSeparator", "noBottomGutter"]);
        return (React.createElement(Wrapper, Object.assign({ withSeparator: withSeparator, noBottomGutter: noBottomGutter }, restProps),
            React.createElement(InnerWrapper, null,
                React.createElement(InnerWrapperContext, null,
                    React.createElement(InnerWrapperIcon, null, icon && (React.createElement(StyledIcon, { size: getIconSize(size || defaultProps.size), name: icon }))),
                    React.createElement(InnerWrapperHeader, null,
                        React.createElement(SizedHeader, { size: size, className: className }, children),
                        subtitle && React.createElement(Subtitle, { size: size }, subtitle))),
                withSeparator && (React.createElement(SeparatorSizer, { size: size },
                    React.createElement(StyledSeparator, null))))));
    }
}
Header.defaultProps = defaultProps;
export default Header;
