import { __rest } from "tslib";
import * as PropTypes from 'prop-types';
import * as React from 'react';
import DefaultTextarea from './index';
const Textarea = (props) => {
    const { input, meta: { touched, error } } = props, restProps = __rest(props, ["input", "meta"]);
    return React.createElement(DefaultTextarea, Object.assign({}, input, restProps, { error: error && touched }));
};
Textarea.propTypes = {
    className: PropTypes.string,
    id: PropTypes.string,
    placeholder: PropTypes.string,
    input: PropTypes.shape({
        value: PropTypes.string.isRequired,
        onChange: PropTypes.func.isRequired,
    }).isRequired,
    meta: PropTypes.shape({
        touched: PropTypes.bool.isRequired,
        error: PropTypes.string,
    }).isRequired,
    resizable: PropTypes.bool,
};
Textarea.defaultProps = {
    className: '',
    id: null,
    resizable: false,
    placeholder: '',
};
export default Textarea;
