import { __rest } from "tslib";
import * as PropTypes from 'prop-types';
import * as React from 'react';
import getProp from '../utilities/getProp';
import AutocompleteSelect from '.';
class AutocompleteSelectRedux extends React.Component {
    render() {
        const _a = this.props, { name, disabled, items, input } = _a, restProps = __rest(_a, ["name", "disabled", "items", "input"]);
        return (React.createElement(AutocompleteSelect, Object.assign({}, restProps, { input: Object.assign(Object.assign({}, input), { onChange: (e) => {
                    input.onChange(e.value);
                } }), value: getProp(input, ['value']), name: name, disabled: disabled, items: items, isRedux: true })));
    }
}
AutocompleteSelectRedux.propTypes = {
    name: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
            .isRequired,
        value: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
            PropTypes.bool,
        ]).isRequired,
    })).isRequired,
    disabled: PropTypes.bool,
    input: PropTypes.shape({
        onChange: PropTypes.func.isRequired,
        value: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
            PropTypes.bool,
        ]),
    }).isRequired,
};
AutocompleteSelectRedux.defaultProps = {
    disabled: false,
};
export default AutocompleteSelectRedux;
