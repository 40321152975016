import { __rest } from "tslib";
import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { getColorFromProps } from '../Text';
import styled, { css, withTheme } from '../Themes/styled-components';
const commonStyles = css `
  cursor: pointer;
  text-decoration: none;
  :hover,
  :active,
  :focus {
    text-decoration: underline;
  }
`;
const ButtonLink = styled('button') `
  /* resets button styles */
  padding: 0;
  border: none;
  font: inherit;
  color: ${({ color }) => color};
  background-color: transparent;
  text-align: inherit;

  ${commonStyles}
`;
const A = styled('a') `
  color: ${({ color }) => color};

  ${commonStyles}
`;
const StyledRouterLink = styled(RouterLink) `
  color: ${({ color }) => color};

  ${commonStyles}
`;
class Link extends React.PureComponent {
    render() {
        const _a = this.props, { href, to, onClick, children, target, theme, primary, danger, success, warning, faded } = _a, restProps = __rest(_a, ["href", "to", "onClick", "children", "target", "theme", "primary", "danger", "success", "warning", "faded"]);
        const color = getColorFromProps(theme, this.props);
        if (href) {
            return (React.createElement(A, Object.assign({ href: href, color: color }, (!!target && { target }), { onClick: onClick }, restProps), children));
        }
        if (to) {
            return (React.createElement(StyledRouterLink, Object.assign({ to: to, color: color, onClick: onClick }, restProps), children));
        }
        return (React.createElement(ButtonLink, Object.assign({ type: "button", color: color, onClick: onClick }, restProps), children));
    }
}
Link.defaultProps = {
    className: '',
    children: null,
    primary: false,
    danger: false,
    success: false,
    warning: false,
    faded: false,
};
export default withTheme(Link);
