import { rem } from 'polished';
import * as React from 'react';
import FormGroupContext from '../FormGroup/FormGroupContext';
import styled, { withTheme } from '../Themes/styled-components';
const IconWrapper = styled.span `
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  border: ${rem(1)} solid
    ${({ theme, error }) => error ? theme.color.danger : theme.form.placeholderColor};
`;
const Wrapper = styled('label') `
  display: inline-block;
  position: relative;
  min-width: ${rem(16)};
  height: ${rem(16)};
  cursor: pointer;
  margin-right: ${rem(9)};

  input[type='radio'] {
    display: none;
    &:disabled ~ ${IconWrapper}:after {
      content: '';
      background: ${({ theme }) => theme.checkbox.disabledBackground};
      width: calc(100% - ${rem(4)});
      height: calc(100% - ${rem(4)});
      border-radius: 100%;
      cursor: ${({ theme }) => theme.input.disabledCursor};
    }
    &:checked ~ ${IconWrapper}:after {
      content: '';
      background: ${({ theme }) => theme.color.primary};
      width: calc(100% - ${rem(4)});
      height: calc(100% - ${rem(4)});
      border-radius: 100%;
    }
  }
`;
const Radio = (props) => {
    const value = React.useContext(FormGroupContext);
    return (React.createElement(Wrapper, { className: props.className, error: !!props.error },
        React.createElement("input", Object.assign({}, props, { type: "radio", id: props.id || value.id })),
        React.createElement(IconWrapper, null)));
};
export default withTheme(Radio);
