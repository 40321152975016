import { __rest } from "tslib";
import moment from 'moment';
import * as React from 'react';
import DatePicker from 'react-datepicker';
import DatePickerWrapper, { Input } from './DatePickerWrapper';
export default class CustomDateTimePicker extends React.Component {
    constructor() {
        super(...arguments);
        this.handleChangeRaw = (e) => {
            const { dateFormat } = this.props;
            const date = moment(e.target.value, dateFormat || 'DD.MM.YYYY HH:mm');
            if (date.isValid()) {
                this.props.onChange(date);
            }
        };
    }
    render() {
        const _a = this.props, { disabled, showYearDropdown, value, onChange, timeIntervals, dateFormat } = _a, other = __rest(_a, ["disabled", "showYearDropdown", "value", "onChange", "timeIntervals", "dateFormat"]);
        return (React.createElement(DatePickerWrapper, null,
            React.createElement(DatePicker, Object.assign({ dateFormat: dateFormat, timeFormat: "HH:mm", placeholderText: "DD.MM.YYYY HH:mm", timeIntervals: timeIntervals, showTimeSelect: true, selected: value, onChange: onChange, onChangeRaw: this.handleChangeRaw, disabled: disabled, isClearable: !disabled, showYearDropdown: showYearDropdown, customInput: React.createElement(Input, Object.assign({}, other)), timeCaption: "\u010Cas" }, other))));
    }
}
CustomDateTimePicker.defaultProps = {
    disabled: false,
    isClearable: true,
    showYearDropdown: true,
    value: null,
    timeIntervals: 5,
    dateFormat: 'DD.MM.YYYY HH:mm',
};
