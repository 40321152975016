import { __rest } from "tslib";
import * as PropTypes from 'prop-types';
import * as React from 'react';
import getProp from '../utilities/getProp';
import InputAutocomplete from '../InputAutocomplete';
class AsyncInputAutocomplete extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            itemsByQuery: {},
        };
        this.getData = this.getData.bind(this);
    }
    componentDidMount() {
        this.getData(this.props);
    }
    componentDidUpdate(prevProps) {
        if (prevProps.value !== this.props.value) {
            this.getData(this.props);
        }
    }
    getData(props) {
        const { value } = props;
        // when cache is true and data for current value exist don't make new reuqest
        if (props.cache && this.state.itemsByQuery[value])
            return;
        this.setState({
            itemsByQuery: Object.assign(Object.assign({}, this.state.itemsByQuery), { [value]: { itemsByQuery: [], isFetching: true } }),
        });
        props.getItems(value).then((resp) => {
            this.setState({
                itemsByQuery: Object.assign(Object.assign({}, this.state.itemsByQuery), { [value]: { itemsByQuery: resp, isFetching: false } }),
            });
        });
    }
    render() {
        const _a = this.props, { value, onChange, onSelectItem } = _a, otherProps = __rest(_a, ["value", "onChange", "onSelectItem"]);
        return (React.createElement(InputAutocomplete, Object.assign({}, otherProps, { value: value, onChange: onChange, items: getProp(this.state, ['itemsByQuery', value, 'itemsByQuery'], []), onSelectItem: onSelectItem, loading: getProp(this.state, ['itemsByQuery', value, 'isFetching'], false), onFocus: () => this.getData(this.props) })));
    }
}
AsyncInputAutocomplete.propTypes = {
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    getItemLabel: PropTypes.func,
    onSelectItem: PropTypes.func.isRequired,
    getItems: PropTypes.func.isRequired,
    cache: PropTypes.bool,
};
AsyncInputAutocomplete.defaultProps = {
    getItemLabel: null,
    cache: false,
};
export default AsyncInputAutocomplete;
