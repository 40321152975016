import { em } from 'polished';
import * as React from 'react';
import styled, { withTheme, css } from '../../Themes/styled-components';
import Icon from '../../Icon';
const Wrapper = styled('div') `
  display: inline-block;
  position: relative;
  color: ${({ theme }) => theme.color.primary};
  padding: ${em(5)};
  border-radius: ${({ theme }) => theme.baseBorderRadius};
  background-color: ${({ theme }) => theme.color.primaryBg};
  margin-right: ${em(7)};
  margin-bottom: ${em(7)};
  font-size: ${em(14)};
  line-height: 1;
  border: 1px solid ${({ theme }) => theme.color.primary};
  ${({ onClick }) => onClick &&
    css `
      cursor: pointer;
    `}
  ${({ removable }) => removable &&
    css `
      padding-right: ${em(23)};
    `}
`;
const Title = styled.div `
  font-size: 0.65em;
`;
const Subtitle = styled.div ``;
const StyledIcon = styled(Icon) `
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  padding: ${em(5)};
`;
const A = styled.a `
  &:hover {
    cursor: pointer;
  }
`;
class Tag extends React.PureComponent {
    render() {
        const { onClick, onClickRemove, removable, title, subtitle, children, showWhitespace, disabled, } = this.props;
        return (React.createElement(Wrapper, { onClick: typeof onClick === 'function' ? onClick : undefined, removable: removable },
            children ? (children) : (React.createElement(React.Fragment, null,
                React.createElement(Title, null, showWhitespace ? title.replace(/\s/g, '·') : title),
                React.createElement(Subtitle, null, showWhitespace ? subtitle.replace(/\s/g, '·') : subtitle))),
            removable && !disabled && (React.createElement(A, { onClick: onClickRemove },
                React.createElement(StyledIcon, { name: "close", size: "xs", color: this.props.theme.color.primary })))));
    }
}
Tag.defaultProps = {
    onClick: null,
    title: '',
    subtitle: '',
    onClickRemove: () => { },
    removable: true,
    showWhitespace: false,
    disabled: false,
};
// Tag.propTypes = {
//   onClick: PropTypes.func,
//   children: PropTypes.node,
//   title: PropTypes.string,
//   subtitle: PropTypes.string,
//   onClickRemove: PropTypes.func,
//   theme: PropTypes.shape({
//     color: PropTypes.shape({
//       primary: PropTypes.string.isRequired,
//     }),
//   }).isRequired,
//   removable: PropTypes.bool,
//   showWhitespace: PropTypes.bool,
//   disabled: PropTypes.bool,
// };
export default withTheme(Tag);
