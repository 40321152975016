import * as PropTypes from 'prop-types';
import * as React from 'react';
import { smarttagsPropType } from '../InputSmarttag/propTypes';
import Tag from './Tag';
const SmarttagTags = (props) => {
    const { items, onChange, onClickTag, showWhitespace, disabled } = props;
    const renderTag = (item, smarttagName, smarttagTitle) => {
        return (React.createElement(Tag, { key: item.key, showWhitespace: showWhitespace, disabled: disabled, onClick: onClickTag &&
                (() => {
                    onClickTag(smarttagName, item.key);
                }), onClickRemove: (e) => {
                e.stopPropagation();
                onChange(items.reduce((prev, smarttag) => {
                    if (smarttag.key === smarttagName) {
                        const values = smarttag.values.filter((val) => {
                            return val.key !== item.key;
                        });
                        if (values.length > 0) {
                            return [...prev, Object.assign(Object.assign({}, smarttag), { values })];
                        }
                        return prev;
                    }
                    return [...prev, smarttag];
                }, []));
            }, removable: typeof onChange === 'function', title: smarttagTitle || smarttagName, subtitle: item.title || item.key }));
    };
    const renderGroup = (item) => {
        return item.values.map((value) => renderTag(value, item.key, item.title));
    };
    if (items.length > 0) {
        return items.map(renderGroup);
    }
    return null;
};
SmarttagTags.propTypes = {
    className: PropTypes.string,
    onChange: PropTypes.func,
    onClickTag: PropTypes.func,
    items: smarttagsPropType.isRequired,
    showWhitespace: PropTypes.bool,
    disabled: PropTypes.bool,
};
SmarttagTags.defaultProps = {
    className: null,
    onChange: null,
    onClickTag: null,
    showWhitespace: false,
    disabled: false,
};
export default SmarttagTags;
