import { rem } from 'polished';
import * as React from 'react';
import FormGroupContext from '../FormGroup/FormGroupContext';
import Icon from '../Icon';
import styled, { withTheme } from '../Themes/styled-components';
const IconWrapper = styled.span `
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Wrapper = styled('label') `
  display: inline-block;
  position: relative;
  min-width: ${rem(16)};
  height: ${rem(16)};
  cursor: pointer;
  margin-right: ${rem(9)};

  input[type='checkbox'] {
    cursor: pointer;
    margin: 0;
    padding: 0;
    opacity: 0;

    & ~ ${IconWrapper} {
      border-radius: ${({ theme }) => theme.checkbox.borderRadius};
      border: ${rem(1)} solid
        ${({ theme, error }) => error ? theme.color.danger : theme.form.placeholderColor};
      transition: background 0.2s, border 0.2s;
      svg {
        position: relative;
        top: ${rem(1)};
        transform: scale(0);
        transition: transform 0.2s ease-out;
      }
    }

    &:checked ~ ${IconWrapper} {
      border: ${rem(1)} solid
        ${({ theme }) => theme.checkbox.checkedBackgroundColor};
      background: ${({ theme }) => theme.checkbox.checkedBackgroundColor};
      svg {
        transform: scale(1);
      }
    }
    &:disabled ~ ${IconWrapper} {
      border: ${rem(1)} solid
        ${({ theme }) => theme.checkbox.disabledBackground};
      background-color: ${({ theme }) => theme.checkbox.disabledBackground};
      cursor: ${({ theme }) => theme.input.disabledCursor};
    }
  }
`;
const Checkbox = (props) => {
    const { theme: { checkbox: { disabledCheckColor, checkColor }, }, } = props;
    const value = React.useContext(FormGroupContext);
    return (React.createElement(Wrapper, { className: props.className, error: !!props.error },
        React.createElement("input", Object.assign({}, props, { type: "checkbox", id: props.id || value.id })),
        React.createElement(IconWrapper, null,
            React.createElement(Icon, { name: "check", color: !!props.disabled ? disabledCheckColor : checkColor, size: "s" }))));
};
export default withTheme(Checkbox);
