import { __rest } from "tslib";
import { rem } from 'polished';
import * as React from 'react';
import styled, { css } from '../Themes/styled-components';
export const DEFUALT_COLORS = [
    '#f44336',
    '#e91e63',
    '#9c27b0',
    '#673ab7',
    '#3f51b5',
    '#2196f3',
    '#03a9f4',
    '#00bcd4',
    '#009688',
    '#4caf50',
    '#8bc34a',
    '#cddc39',
    '#ffeb3b',
    '#ffc107',
    '#ff9800',
    '#ff5722',
    '#795548',
    '#607d8b',
];
const Wrapper = styled.div `
  margin: 0 ${rem(5)};
  display: flex;
  flex-wrap: wrap;
`;
const DotWrapper = styled.div `
  margin: ${rem(5)};
  display: flex;
  justify-content: center;
  align-items: center;
  transform: scale(1);
  transition: transform 100ms ease;
  width: ${rem(40)};
  height: ${rem(40)};
`;
export const Dot = styled('div') `
  border-radius: 50%;
  transition: 0.1s;
  transition: 0.1s;
  width: ${rem(35)};
  height: ${rem(35)};

  &:hover {
    width: ${rem(40)};
    height: ${rem(40)};
    cursor: pointer;
  }

  ${props => {
    if (props.color) {
        if (props.selected) {
            return css `
          border: ${rem(3)} solid ${props.color};
        `;
        }
        return css `
        background-color: ${props.color};
      `;
    }
    return css `
      background-color: ${({ theme }) => theme.form.labelColor};
    `;
}};
`;
export default (props) => {
    const { className, value, onChange, colors = DEFUALT_COLORS } = props, otherProps = __rest(props, ["className", "value", "onChange", "colors"]);
    return (React.createElement(Wrapper, Object.assign({ className: className }, otherProps), colors.map(color => {
        return (React.createElement(DotWrapper, { key: color },
            React.createElement(Dot, { color: color, selected: value === color, onClick: () => onChange(color) })));
    })));
};
