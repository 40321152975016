import { __rest } from "tslib";
import * as React from 'react';
import DatePicker from 'react-datepicker';
import DatePickerWrapper, { Input } from './DatePickerWrapper';
export default class CustomTimePicker extends React.Component {
    render() {
        const _a = this.props, { disabled, showYearDropdown, value, onChange, timeIntervals, customInput, className } = _a, other = __rest(_a, ["disabled", "showYearDropdown", "value", "onChange", "timeIntervals", "customInput", "className"]);
        return (React.createElement(DatePickerWrapper, null,
            React.createElement(DatePicker, Object.assign({ dateFormat: "HH:mm", timeFormat: "HH:mm", placeholderText: "HH:mm", timeIntervals: timeIntervals, showTimeSelect: true, showTimeSelectOnly: true, selected: value, onChange: onChange, disabled: disabled, isClearable: !disabled, showYearDropdown: showYearDropdown, timeCaption: "\u010Cas", customInput: customInput, className: className }, other))));
    }
}
CustomTimePicker.defaultProps = {
    disabled: false,
    showYearDropdown: true,
    value: null,
    timeIntervals: 5,
    customInput: React.createElement(Input, null),
};
