import { __rest } from "tslib";
import { rem } from 'polished';
import * as React from 'react';
import styled from '../../Themes/styled-components';
const Wrapper = styled('span') `
  position: relative;
`;
const Input = styled('input') `
  font-size: ${({ theme }) => rem(theme.input.fontSize)};
  padding: 0;
  margin: 0;
  font-family: ${({ theme }) => theme.fontFamily};
  &::placeholder {
    color: ${({ theme }) => theme.form.placeholderColor};
  }
`;
const MIN_WIDTH = 2;
const AutosizeInput = (_a) => {
    var { value, onChange, placeholder, className = '', inputRef } = _a, restProps = __rest(_a, ["value", "onChange", "placeholder", "className", "inputRef"]);
    const inputEl = React.useRef(null);
    const inputShadowEl = React.useRef(null);
    const inputPlaceholderShadowEl = React.useRef(null);
    const [width, setWidth] = React.useState(0);
    React.useEffect(() => {
        if (inputPlaceholderShadowEl &&
            inputPlaceholderShadowEl.current &&
            inputShadowEl &&
            inputShadowEl.current) {
            const shadowWidth = inputShadowEl.current.getBoundingClientRect().width;
            const placeholderShadowWidth = inputPlaceholderShadowEl.current.getBoundingClientRect().width;
            let targetWidth = shadowWidth;
            if (!value) {
                targetWidth = Math.max(shadowWidth, placeholderShadowWidth);
            }
            setWidth(targetWidth > MIN_WIDTH ? targetWidth + MIN_WIDTH : MIN_WIDTH);
        }
    }, [value, placeholder]);
    let inputStyles = {};
    if (inputEl && inputEl.current) {
        inputStyles = window.getComputedStyle(inputEl.current);
    }
    const styleCopy = {
        fontSize: inputStyles.fontSize,
        fontFamily: inputStyles.fontFamily,
        fontWeight: inputStyles.fontWeight,
        fontStyle: inputStyles.fontStyle,
        letterSpacing: inputStyles.letterSpacing,
        textTransform: inputStyles.textTransform,
        padding: inputStyles.padding,
        border: inputStyles.border,
        whiteSpace: 'pre',
        visibility: 'hidden',
        position: 'absolute',
    };
    return (React.createElement(Wrapper, null,
        React.createElement(Input, Object.assign({ value: value, ref: (e) => {
                if (inputRef) {
                    inputRef.current = e;
                }
                inputEl.current = e;
            }, style: {
                width: `${width}px`,
            }, className: className, onChange: (e) => {
                onChange(e.target.value);
            }, placeholder: placeholder }, restProps)),
        React.createElement("span", { ref: inputShadowEl, style: styleCopy, className: className }, value),
        React.createElement("span", { ref: inputPlaceholderShadowEl, style: styleCopy, className: className }, placeholder)));
};
export default AutosizeInput;
