import * as React from 'react';
import { rem } from 'polished';
import styled, { withTheme, css } from '../../Themes/styled-components';
import Label from '../components/Label';
import Icon from '../../Icon';
const Wrapper = styled('span') `
  display: inline-flex;
  background: ${({ theme }) => theme.color.primaryBg};
  color: ${({ theme }) => theme.color.primary};
  font-size: ${rem(12)};
  padding: 0.2em 0.8em;
  align-items: center;
  border-radius: ${rem(4)};
  max-width: 100%;
  vertical-align: middle;
  margin: ${({ theme }) => rem(theme.select.padding)};
  ${({ disabled }) => disabled &&
    css `
      background: ${({ theme }) => theme.separatorColor};
    `}
`;
const RemoveBtn = styled('button') `
  border: none;
  background: none;
  cursor: pointer;
  padding: 0;
  font-size: 0;
  padding-left: 3px;
`;
export default withTheme((props) => {
    return (React.createElement(Wrapper, { disabled: props.disabled },
        React.createElement(Label, { label: props.value.label, icon: props.value.icon, iconSize: 12 }),
        props.onRemove && (React.createElement(RemoveBtn, { onClick: props.onRemove },
            React.createElement(Icon, { name: "close", color: props.theme.color.primary })))));
});
