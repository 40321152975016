import BasicTable from '../../ui/BasicTable';
import Input from '../../ui/Input';
import Paginator from '../../ui/Paginator';
import Segment from '../../ui/Segment';
import { rem } from 'polished';
import * as React from 'react';
import styled from 'styled-components';
import Api from '../../Api';
import { __ } from '../../utilities';

const LIMIT = 20;

const Wrapper = styled.div`
  width: 100%;
  margin: ${rem(16)};
`;

const Separator = styled.div`
  margin: ${rem(16)} 0;
`;

const C = () => {
  const [isFetching, setIsFetching] = React.useState(false);
  const [clients, setClients] = React.useState([]);
  const [nextOffset, setNextOffset] = React.useState(null);

  const [isFetchingByQ, setIsFetchingByQ] = React.useState(false);
  const [clientsByQ, setClientsByQ] = React.useState({});
  const [nextOffsetByQ, setNextOffsetByQ] = React.useState({});

  const [q, setQ] = React.useState('');
  const [offset, setOffset] = React.useState(0);

  React.useEffect(() => {
    setIsFetching(true);
    if (q) {
      setIsFetchingByQ((prevState) => ({ ...prevState, [q]: true }));
    }
    Api.adminGetClients({
      offset,
      limit: LIMIT,
      q,
    })
      .then(({ clients: users, nextOffset: newNextOffset }) => {
        if (q) {
          setClientsByQ((prevState) => ({ ...prevState, [q]: users }));
          setNextOffsetByQ((prevState) => ({
            ...prevState,
            [q]: newNextOffset,
          }));
        } else {
          setClients(users);
          setNextOffset(newNextOffset);
        }
      })
      .catch((e) => {
        alert(__('Nepodarilo sa získať zoznam klientov'));
      })
      .finally(() => {
        setIsFetching(false);
        if (q) {
          setIsFetchingByQ((prevState) => ({ ...prevState, [q]: false }));
        }
      });
  }, [q, offset]);

  React.useEffect(() => {
    setOffset(0);
  }, [q]);

  return (
    <Wrapper>
      <Segment raised loading={q ? isFetchingByQ[q] : isFetching}>
        <Input value={q} onChange={(e) => setQ(e.target.value)} />
        <Separator />
        <BasicTable
          renderRow={(user) => [
            user.identifier,
            `${user.name} ${user.surname}`,
            <a href={`tel:${user.phone}`}>{user.phone}</a>,
            <a href={`mailto:${user.email}`}>{user.email}</a>,
          ]}
          rowKey="_id"
          rows={q ? clientsByQ[q] || [] : clients}
          columns={[
            { header: __('Identifikátor') },
            { header: __('Meno a priezvisko') },
            { header: __('Telefón') },
            { header: __('Email') },
          ]}
        />
        <Separator />
        <Paginator
          limit={LIMIT}
          offset={offset}
          nextOffset={q ? nextOffsetByQ[q] : nextOffset}
          onChangeOffset={setOffset}
        />
      </Segment>
    </Wrapper>
  );
};

export default C;
