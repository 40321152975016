import { darken, lighten, readableColor, rem } from 'polished';
export const mediaSizes = {
    xxs: 320,
    xs: 480,
    s: 600,
    m: 768,
    l: 992,
    xl: 1100,
    xxl: 1200,
    xxxl: 1350,
};
/**
 * Utility function to generate media queries
 *
 * Sizes: { xxs: 320, xs: 480, s: 600, m: 768, l: 992, xl: 1100, xxl: 1200, xxxl: 1350 }
 *
 * @param from "xxs" | "xs" | "s" | "m" | "l" | "xl" | "xxl" | "xxxl" | number
 * @example
 * ```javascript
 * const Wrapper = styled('div')`
 *   display: block;
 *   ${mb('m')} {
 *     display: none;
 *   }
 * `;
 * ```
 */
export function mb(from) {
    if (typeof from === 'string') {
        if (from in mediaSizes) {
            return `@media (min-width: ${rem(mediaSizes[from])})`;
        }
        else {
            throw new Error(`mb: Media size "${from}" is not defined. Use one of [${Object.keys(mediaSizes)}]`);
        }
    }
    if (typeof from !== 'number') {
        throw new Error(`mb: Invalid media breakpoint argument "${from}"`);
    }
    return `@media (min-width: ${rem(from)})`;
}
/**
 * Utility function for emphasizing color by amount
 *
 * Dark color will be darker and light color will be lighter
 * @param {number} amount how strong the effect should be <0, 1>
 * @param {string} color base color
 * @param {boolean} reverse if true effect will be reversed
 */
export const emphasizeColor = (amount, color, reverse = false) => {
    const isColorDark = readableColor(color, '#fff', '#000', false) === '#000';
    if (isColorDark && reverse) {
        return lighten(amount, color);
    }
    else if (isColorDark) {
        return darken(amount, color);
    }
    else if (!isColorDark && reverse) {
        return darken(amount, color);
    }
    else {
        return lighten(amount, color);
    }
};
