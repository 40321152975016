import * as React from 'react';
import styled, { withTheme } from '../Themes/styled-components';
import Modal, { ModalActions, ModalContent } from '../Modal';
import Icon from '../Icon';
import Button from '..//Button';
const CenteredModalContent = styled(ModalContent) `
  text-align: center;
`;
class AlertModal extends React.PureComponent {
    render() {
        const { isOpen, children, handleClose, theme } = this.props;
        return (React.createElement(Modal, { centered: true, size: "xs", isOpen: isOpen, handleClose: handleClose },
            React.createElement(CenteredModalContent, null,
                React.createElement(Icon, { name: "error", size: "xl", color: theme.color.danger }),
                React.createElement("br", null),
                children),
            React.createElement(ModalActions, null,
                React.createElement("div", null),
                React.createElement(Button, { onClick: handleClose }, "OK"))));
    }
}
export default withTheme(AlertModal);
