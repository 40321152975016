import { rem } from 'polished';
import * as React from 'react';
import styled, { css } from '../Themes/styled-components';
const Wrapper = styled.div `
  display: flex;
  margin-top: ${rem(10)};
  margin-bottom: ${rem(18)};
  width: 100%;
`;
const StyledLine = styled('hr') `
  display: block;
  height: ${rem(1)};
  border: 0;
  border-top: ${rem(1)} solid
    ${({ primary, theme }) => primary ? theme.color.primary : theme.separatorColor};
  margin: 0;
  padding: 0;
  width: 100%;
  max-width: 100%;
  ${({ primary }) => primary &&
    css `
      flex-shrink: 0;
      min-width: ${rem(75)};
      width: 12%;
    `}
`;
class Separator extends React.PureComponent {
    render() {
        const { className } = this.props;
        return (React.createElement(Wrapper, { className: className },
            React.createElement(StyledLine, { primary: true }),
            React.createElement(StyledLine, null)));
    }
}
export default Separator;
