import { __rest } from "tslib";
import * as React from 'react';
import DatePicker from 'react-datepicker';
import DatePickerWrapper, { Input } from './DatePickerWrapper';
export default class CustomDatePicker extends React.Component {
    render() {
        const _a = this.props, { disabled = false, readOnly = false, showYearDropdown = true, value = null, onChange, timeIntervals = 5, error } = _a, other = __rest(_a, ["disabled", "readOnly", "showYearDropdown", "value", "onChange", "timeIntervals", "error"]);
        return (React.createElement(DatePickerWrapper, null,
            React.createElement(DatePicker, Object.assign({ dateFormat: "DD.MM.YYYY", timeIntervals: timeIntervals, selected: value, onChange: onChange, disabled: disabled, readOnly: readOnly, isClearable: !disabled && !readOnly, showYearDropdown: showYearDropdown, placeholderText: "DD.MM.YYYY", timeCaption: "\u010Cas", customInput: React.createElement(Input, Object.assign({ error: error }, other, { type: "text" })) }, other))));
    }
}
