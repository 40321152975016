import Button from '../../ui/Button';
import Col, { Row } from '../../ui/Grid';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { getProp } from '../../utilities';
import Api from '../../Api';
import Logo from '../../Assets/logo_kuriereu.png';
import { __ } from '../../utilities';
import {
  BackgroundModal,
  Brand,
  Buttons,
  FormWrapper,
  FrontModal,
  Modal,
  ModalWrapper,
  Wrapper,
} from '../Login';
import ForgotPasswordForm from './form';

class ForgotPassword extends React.PureComponent {
  submit = async (data) => {
    try {
      const { usermail } = data;
      await Api.forgotPassword({ data: { usermail } });
      alert(__(`Nové heslo bolo zaslané na emailovú adresu ${usermail}`));
      this.props.history.push('/');
      return true;
    } catch (e) {
      if (getProp(e, ['details', 'description']) === 'USER_NOT_FOUND') {
        alert(__('Pre zadanú e-mailovú adresu neexistuje žiaden účet.'));
      } else {
        alert(__('Ľutujeme, vyskytla sa neočakávaná chyba.'));
      }
    }
    return true;
  };
  render() {
    return (
      <Wrapper>
        <ModalWrapper>
          <Modal>
            <FrontModal>
              <Brand src={Logo} />
              <FormWrapper>
                <ForgotPasswordForm onSubmit={this.submit} />
              </FormWrapper>
              <Buttons>
                <Row>
                  <Col xs={12} s={6}>
                    <Button
                      type="button"
                      danger
                      onClick={(e) => {
                        e.preventDefault();
                        this.props.history.push('/');
                      }}
                    >
                      {__('Spät')}
                    </Button>
                  </Col>
                </Row>
              </Buttons>
            </FrontModal>
            <BackgroundModal>&nbsp;</BackgroundModal>
          </Modal>
        </ModalWrapper>
      </Wrapper>
    );
  }
}

ForgotPassword.propTypes = {
  // dispatch: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default connect()(ForgotPassword);
