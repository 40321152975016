import { rem } from 'polished';
import * as React from 'react';
import styled, { css } from '../Themes/styled-components';
export const Td = styled('td') `
  padding: ${rem(4)} ${rem(13)};
  height: ${({ theme }) => {
    return `${rem(theme.table.cellHeight)}`;
}};
  font-size: ${rem(14)};
  font-family: ${({ theme }) => theme.fontFamily};
  text-align: left;
  ${props => {
    if (props.center) {
        return `text-align: center;`;
    }
    if (props.right) {
        return `text-align: right;`;
    }
    return ``;
}};

  .sportnetui-btn {
    padding: 0 ${rem(10)};
    font-size: ${rem(12)};
    min-height: ${rem(26)};
    .icon {
      height: ${rem(12)};
      width: ${rem(12)};
    }
  }
`;
export const Th = styled('th') `
  padding: ${rem(4)} ${rem(13)};
  font-size: ${rem(12)};
  text-transform: uppercase;
  text-align: left;
  ${props => {
    if (props.width) {
        return `width: ${rem(props.width)}`;
    }
    return ``;
}}
  ${props => {
    if (props.center) {
        return `text-align: center;`;
    }
    if (props.right) {
        return `text-align: right;`;
    }
    return ``;
}};
  font-weight: bold;
  color: ${({ theme }) => theme.color.base};
`;
export const Tr = styled('tr') `
  outline: none;
  border-bottom: ${rem(1)} solid ${({ theme }) => theme.separatorColor};
  &:hover > td {
    background: ${({ theme }) => theme.table.hover};
  }
  ${props => {
    if (props.onClick) {
        return css `
        &:hover > td {
          cursor: pointer;
        }
      `;
    }
    return ``;
}};
`;
export const Tbody = styled.tbody ``;
export const Thead = styled.thead ``;
const TableWrapper = styled.div `
  overflow-x: auto;
  width: 100%;
`;
const TableComponent = styled.table `
  width: 100%;
  font-family: ${({ theme }) => theme.fontFamily};
  border-collapse: collapse;
`;
export const Table = (props) => (React.createElement(TableWrapper, null,
    React.createElement(TableComponent, null, props.children)));
export default { Table, Tbody, Thead, Tr, Th, Td };
