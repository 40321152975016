import { rem } from 'polished';
import * as React from 'react';
import Image from '../Image';
import styled, { css } from '../Themes/styled-components';
import { mb } from '../Themes/utilities';
const DefaultAvatar = require('../assets/DefaultAvatar.svg');
const Wrapper = styled('div') `
  display: flex;
  flex-direction: row;
  ${({ onClick }) => typeof onClick === 'function' &&
    css `
      border-radius: 100px;
      cursor: pointer;
      &:active,
      &:focus,
      &:hover {
        text-decoration: underline;
      }
    `};
`;
const Avatar = styled.div `
  position: relative;
  min-width: ${({ avatarSize }) => avatarSize}px;
  width: ${({ avatarSize }) => avatarSize}px;
  height: ${({ avatarSize }) => avatarSize}px;
  ${({ noLeftGutter }) => {
    if (noLeftGutter) {
        return css ``;
    }
    return css `
      margin-left: ${({ theme }) => rem(theme.grid.gutterWidth)};
    `;
}}
`;
const NotificationCount = styled.div `
  background: ${({ theme }) => theme.color.primary};
  border-radius: 4px;
  color: #fff;
  position: absolute;
  top: -8px;
  right: -7px;
  font-size: 10px;
  font-weight: bold;
  line-height: 19px;
  text-align: center;
  white-space: nowrap;
  padding: 0px 5px;
  min-width: 17px;
`;
const Info = styled.div `
  display: none;
  margin-left: ${({ theme }) => rem(theme.grid.gutterWidth)};
  flex-direction: column;
  justify-content: center;
  ${mb('m')} {
    display: flex;
  }
`;
const Name = styled.div `
  font-size: ${rem(14)};
  white-space: nowrap;
`;
const Subname = styled.div `
  font-size: ${rem(10)};
  white-space: nowrap;
`;
const AvatarImage = styled(Image) `
  border-radius: 50%;
`;
class User extends React.PureComponent {
    render() {
        const { avatarSrc, notificationCount, name, avatarSize, subname, onClick, noLeftGutter, } = this.props;
        return (React.createElement(Wrapper, { onClick: onClick },
            React.createElement(Avatar, { avatarSize: avatarSize || 32, noLeftGutter: noLeftGutter },
                React.createElement(AvatarImage, { src: avatarSrc || DefaultAvatar, width: avatarSize, height: avatarSize, fallbackSrc: DefaultAvatar }),
                !!notificationCount && (React.createElement(NotificationCount, null, notificationCount >= 100 ? '99+' : notificationCount))),
            (name || subname) && (React.createElement(Info, null,
                name && React.createElement(Name, null, name),
                subname && React.createElement(Subname, null, subname)))));
    }
}
export default User;
