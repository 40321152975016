import { __rest } from "tslib";
import * as React from 'react';
import styled, { css, withTheme } from '../Themes/styled-components';
const Wrapper = styled('span') `
  ${({ color }) => color &&
    css `
      color: ${color};
    `};
`;
export function getColorFromProps(theme, props) {
    if (props.danger) {
        return theme.color.danger;
    }
    else if (props.success) {
        return theme.color.success;
    }
    else if (props.warning) {
        return theme.color.warning;
    }
    else if (props.faded) {
        return theme.color.fadedText;
    }
    else if (props.primary) {
        return theme.color.primary;
    }
    return 'inherit';
}
class Text extends React.PureComponent {
    render() {
        const _a = this.props, { color, primary, danger, success, warning, faded, theme } = _a, restProps = __rest(_a, ["color", "primary", "danger", "success", "warning", "faded", "theme"]);
        let textColor = '';
        if (color) {
            textColor = color;
        }
        else {
            textColor = getColorFromProps(theme, this.props);
        }
        return (React.createElement(Wrapper, Object.assign({ color: textColor }, restProps), this.props.children));
    }
}
Text.defaultProps = {
    className: '',
    children: null,
    primary: false,
    danger: false,
    success: false,
    warning: false,
    faded: false,
};
export default withTheme(Text);
