import { __rest } from "tslib";
import { em, rem } from 'polished';
import * as PropTypes from 'prop-types';
import * as React from 'react';
import Input from '../Input';
import styled, { css } from '../Themes/styled-components';
const Wrapper = styled.div `
  position: relative;
`;
const ContainerWrapper = styled.div `
  position: relative;
  width: 100%;
  z-index: 8;
`;
const Items = styled.ul `
  position: absolute;
  z-index: 6;
  max-height: ${rem(300)};
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  list-style: none;
  margin: 0;
  padding: 0;
  border: 1px solid ${({ theme }) => theme.separatorColor};
  border-radius: ${({ theme }) => theme.largeBorderRadius};
`;
const StyledInput = styled(Input) `
  position: relative;
`;
const Item = styled('li') `
  font-size: ${em(14)};
  display: flex;
  width: 100%;
  position: relative;
  align-content: center;
  align-items: center;
  min-height: ${em(32)};
  flex-shrink: 0;
  padding: 0 ${({ theme }) => em(theme.grid.gutterWidth)};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  background-color: #fff;
  ${({ active, theme }) => {
    if (active) {
        return css `
        background-color: ${theme.color.primaryBg};
        color: ${theme.color.primary};
      `;
    }
    return '';
}};
`;
const ItemLabel = styled.span `
  text-overflow: ellipsis;
  overflow: hidden;
`;
class InputAutocomplete extends React.Component {
    constructor(props) {
        super(props);
        this.handleSubmit = () => {
            this.selectItem(-1);
        };
        this.state = {
            focused: false,
            focusItemIndex: -1,
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleFocus = this.handleFocus.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.renderItem = this.renderItem.bind(this);
        this.selectItem = this.selectItem.bind(this);
        this.inputEl = null;
        console.warn('InputAutocomplete is DEPRECATED. Use TheSelect instead.');
    }
    /**
     * Soon to be deprecated in favor of TheSelect
     */
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.items !== this.props.items) {
            this.setState({ focusItemIndex: -1 });
        }
    }
    handleFocus() {
        const { onFocus } = this.props;
        if (onFocus) {
            onFocus();
        }
        this.setState({ focused: true, focusItemIndex: -1 });
    }
    handleBlur() {
        const { onBlur } = this.props;
        if (onBlur) {
            onBlur();
        }
        this.setState({ focused: false });
    }
    handleKeyDown(event) {
        const { items } = this.props;
        const { focusItemIndex } = this.state;
        if (event.key === 'ArrowDown') {
            event.preventDefault();
            this.setState({
                focusItemIndex: (focusItemIndex + 1) % items.length,
            });
        }
        else if (event.key === 'ArrowUp') {
            event.preventDefault();
            this.setState({
                focusItemIndex: (focusItemIndex + items.length - 1) % items.length,
            });
        }
        else if (event.key === 'Enter') {
            this.selectItem(focusItemIndex);
        }
        else if (event.key === 'Escape') {
            this.inputEl.blur();
        }
    }
    handleChange(e) {
        this.props.onChange(e.target.value);
    }
    selectItem(idx) {
        const { onSelectItem, items } = this.props;
        const value = idx in items ? items[idx] : null;
        if (onSelectItem(value) !== false) {
            this.inputEl.blur();
        }
    }
    renderItem(item, idx) {
        const { getItemLabel } = this.props;
        const { focusItemIndex } = this.state;
        let label = null;
        if (typeof item === 'string') {
            label = item;
        }
        else if (typeof getItemLabel === 'function') {
            label = getItemLabel(item);
        }
        else {
            throw Error('Invalid item. Supply either string, or define getItemLabel() function');
        }
        return (React.createElement(Item, { key: idx, active: idx === focusItemIndex, onMouseDown: (e) => {
                e.preventDefault();
                this.selectItem(idx);
            }, onMouseEnter: () => {
                this.setState({ focusItemIndex: idx });
            } },
            React.createElement(ItemLabel, null, label)));
    }
    render() {
        const _a = this.props, { valid, items, disabled, submitAllowed, onSelectItem, getItemLabel, loading } = _a, inputProps = __rest(_a, ["valid", "items", "disabled", "submitAllowed", "onSelectItem", "getItemLabel", "loading"]);
        return (React.createElement(Wrapper, null,
            React.createElement(StyledInput, Object.assign({}, inputProps, { onChange: this.handleChange, onFocus: this.handleFocus, onBlur: this.handleBlur, disabled: disabled, onKeyDown: this.handleKeyDown, inputRef: (el) => {
                    this.inputEl = el;
                }, autoComplete: "off" }),
                React.createElement("input", null),
                loading && React.createElement(Input.Loading, null),
                submitAllowed && (React.createElement(Input.Button, { primary: true, icon: "check", onClick: this.handleSubmit, disabled: !valid || disabled }))),
            React.createElement(ContainerWrapper, null, (this.state.focused || this.state.mouseDownOnItem) &&
                !!items &&
                items.length > 0 && React.createElement(Items, null, items.map(this.renderItem)))));
    }
}
InputAutocomplete.propTypes = {
    valid: PropTypes.bool,
    items: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string])).isRequired,
    onSelectItem: PropTypes.func,
    onChange: PropTypes.func.isRequired,
    getItemLabel: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    disabled: PropTypes.bool,
    submitAllowed: PropTypes.bool,
    loading: PropTypes.bool,
};
InputAutocomplete.defaultProps = {
    valid: true,
    onSelectItem: () => { },
    getItemLabel: null,
    onBlur: null,
    onFocus: null,
    disabled: false,
    submitAllowed: false,
    loading: false,
};
export default InputAutocomplete;
