import { em, rem } from 'polished';
import * as React from 'react';
import Icon from '../../Icon';
import styled, { css } from '../../Themes/styled-components';
const OptionIcon = styled('span') `
  height: ${({ iconSize }) => `${iconSize}px`};
  width: ${({ iconSize }) => `${iconSize}px`};
  min-width: ${({ iconSize }) => `${iconSize}px`};
  margin-right: ${({ iconSize }) => em(iconSize / 2)};
  ${({ disabled }) => disabled &&
    css `
      cursor: not-allowed;
      opacity: 0.5;
    `};
`;
const Label = styled('span') `
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: ${({ labelSize }) => rem(labelSize)};
  ${({ disabled }) => disabled &&
    css `
      cursor: not-allowed;
      opacity: 0.5;
    `};
`;
const DEFAULT_ICON_SIZE = 14;
const DEFAULT_LABEL_SIZE = 12;
function LabelIcon(props) {
    const { icon, iconColor, iconSize, disabled } = props;
    if (typeof icon === 'string') {
        return (React.createElement(OptionIcon, { iconSize: iconSize || DEFAULT_ICON_SIZE, disabled: disabled, "data-testid": "TheSelect-LabelIconString" },
            React.createElement(Icon, { name: icon, size: iconSize || DEFAULT_ICON_SIZE, color: iconColor })));
    }
    if (typeof icon === 'object') {
        return (React.createElement(OptionIcon, { iconSize: iconSize || DEFAULT_ICON_SIZE, disabled: disabled, "data-testid": "TheSelect-LabelIconObject" }, icon));
    }
    return null;
}
export default (props) => {
    const { label, labelSize, disabled } = props;
    return (React.createElement(React.Fragment, null,
        React.createElement(LabelIcon, Object.assign({}, props)),
        React.createElement(Label, { labelSize: labelSize || DEFAULT_LABEL_SIZE, disabled: disabled }, label)));
};
