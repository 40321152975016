import { darken, desaturate, lighten, rem } from 'polished';
export const getTheme = (override = {}) => {
    const color = Object.assign({ base: '#000', primary: '#5283e7', primaryBg: '#f1f6ff', danger: '#e94141', warning: '#ca9727', success: '#5bd084', successBg: '#e8fff0', fadedText: '#888' }, override.color);
    const input = Object.assign({ height: 40, fontSize: 16, horizontalPadding: 12, backgroundColor: '#fff', disabledTextColor: color.fadedText, disabledBackground: '#F9F9F9', disabledCursor: 'not-allowed', readOnlyCursor: 'not-allowed', borderRadius: 4, borderColor: '#bbb', shadow: '0 1px 2px rgba(0, 0, 0, 0.08)' }, override.input);
    const accordion = Object.assign({ contentBackground: '#fff', spacing: rem(20), itemBackground: '#fff', activeItemColor: color.primary, onHoverTextColor: color.primary }, override.accordion);
    const form = Object.assign({ labelColor: '#555', placeholderColor: color.fadedText }, override.form);
    const formGroup = Object.assign({ labelGutter: 5, bottomMargin: 10 }, override.formGroup);
    const menu = Object.assign({ spacing: rem(20), listBackground: '#fff', onHoverBackgroundColor: color.primary, onHoverTextColor: '#fff', activeBackgroundColor: color.primary, activeTextColor: color.primary }, override.menu);
    const responsiveMenuPrimary = override.responsiveMenuPrimary || color.primary;
    const responsiveMenu = Object.assign({ backgroundColor: responsiveMenuPrimary, backdropColor: 'rgba(0, 0, 0, 0.25)', linkColor: lighten(0.9, responsiveMenuPrimary), linkColorHighlighted: desaturate(0.1, lighten(0.5, responsiveMenuPrimary)), linkHoverColor: darken(0.02, responsiveMenuPrimary), siteUrlColor: desaturate(0.3, lighten(0.2, responsiveMenuPrimary)), siteUrlBorder: desaturate(0.3, lighten(0.2, responsiveMenuPrimary)), closeButtonColor: '#fff' }, override.responsiveMenu);
    const select = Object.assign({ placeholderColor: color.fadedText, darkerBg: '#F9F9F9', padding: 2 }, override.select);
    const table = Object.assign({ headerBg: '#f9f9f9', evenRow: color.base, evenRowBg: '#f9f9f9', oddRow: color.base, oddRowBg: '#ffffff', hover: '#f2f2f2', activeRow: color.base, activeRowBg: color.primaryBg, highlightRow: color.base, highlightRowBg: color.successBg, cellHeight: 42 }, override.table);
    const grid = Object.assign({ gutterWidth: 12 }, override.grid);
    const card = Object.assign({ contentBg: '#fff', secondaryContentBg: '#f9f9f9', shadow: `0px ${rem(5)} ${rem(20)} 0 rgba(0, 0, 0, 0.1)`, secondaryShadow: `0px ${rem(1)} ${rem(5)} 0 rgba(0, 0, 0, 0.1)` }, override.card);
    const tabber = Object.assign({ active: color.primary, activeLight: color.primaryBg, inactive: color.fadedText, activeText: color.base, borderColor: color.fadedText }, override.tabber);
    const colorControl = Object.assign({ darkColor: color.base, lightColor: '#fff' }, override.colorControl);
    const theme = {
        textColor: override.textColor || color.base,
        inactiveColor: override.inactiveColor || '#b5b5b5',
        separatorColor: override.separatorColor || '#eee',
        baseBorderRadius: override.baseBorderRadius || rem(2),
        largeBorderRadius: override.largeBorderRadius || rem(4),
        fontFamily: override.fontFamily || "'Barlow', sans-serif",
        backgroundColor: override.backgroundColor || '#fff',
        shadow: override.shadow || `0 0 ${rem(40)} 0 rgba(0, 0, 0, 0.2)`,
    };
    const paginator = Object.assign({ backgroundColor: theme.backgroundColor, hoverBackgroundColor: darken(0.03, theme.backgroundColor), activeColor: color.primary, disabledColor: color.fadedText, borderColor: theme.separatorColor, textColor: theme.textColor }, override.paginator);
    const checkbox = Object.assign({ checkedBackgroundColor: color.primary, checkColor: '#fff', disabledBackground: '#f7f7f7', disabledCheckColor: color.fadedText, borderRadius: 2 }, override.checkbox);
    const tooltip = Object.assign({ textColor: '#fff', backgroundColor: '#333', borderRadius: 4 }, override.tooltip);
    const navigation = Object.assign({ backgroundColor: theme.backgroundColor, itemTextColor: theme.textColor, subItemTextColor: theme.textColor, iconColor: color.primary, 
        // active
        activeItemTextColor: color.primary, activeItemBackgroundColor: 'transparent', activeSubItemTextColor: color.primary, activeSubItemBackgroundColor: 'transparent', 
        // activeItemIconColor: color.primary,
        // focused + hovered
        focusedItemTextColor: '', focusedItemBackgroundColor: color.primaryBg, focusedSubItemTextColor: '', focusedSubItemBackgroundColor: color.primaryBg, 
        // focusedItemIconColor: theme.textColor,
        width: 240, shrunkWidth: 48 + 1, itemHeight: 48, subItemHeight: 32, verticalPadding: 8 }, override.navigation);
    const headerBar = Object.assign({ height: 56, backgroundColor: theme.backgroundColor }, override.headerBar);
    const contextBar = Object.assign({ height: 56, backgroundColor: theme.backgroundColor }, override.contextBar);
    return Object.assign(Object.assign({}, theme), { accordion,
        color,
        colorControl,
        form,
        formGroup,
        table,
        tooltip,
        select,
        input,
        checkbox,
        grid,
        card,
        tabber,
        responsiveMenuPrimary,
        responsiveMenu,
        menu,
        paginator,
        navigation,
        headerBar,
        contextBar });
};
export default getTheme();
