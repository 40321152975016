import { rem } from 'polished';
import * as React from 'react';
import Icon from '../Icon';
import { getBorderFromProps } from '../Input/utils';
import Link from '../Link';
import Loader from '../Loader';
import MediaManagerImage from '../MediaManagerImage';
import MediaManagerPhotoEditor from '../MediaManagerPhotoEditor';
import styled, { css, withTheme } from '../Themes/styled-components';
import { __ } from '../utilities';
/**
 * It should be as tall as three inputs
 * @param theme
 */
const calcHeight = (theme) => {
    let height = theme.input.height * 3; // height of inputs
    height += theme.formGroup.bottomMargin * 2; // height of two form group margins
    height += theme.formGroup.labelGutter * 2; // height of two form group label margins
    height += 14 * 2; // height of two form group label font-sizes
    return height;
};
const IMAGE_HEIGHT = 165;
const Wrapper = styled.div `
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: ${({ theme }) => rem(calcHeight(theme))};
`;
const ImageContainer = styled.div `
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  flex: 1 0 0;
  width: 100%;

  border-radius: ${({ theme }) => rem(theme.input.borderRadius)};
  padding: ${rem(10)} ${({ theme }) => rem(theme.input.horizontalPadding)};
  background-color: ${({ theme }) => theme.input.backgroundColor};
  border: 1px dashed ${({ theme }) => theme.input.borderColor};

  ${({ isImageSelected, theme }) => isImageSelected &&
    css `
      box-shadow: ${theme.input.shadow};
      border: ${props => getBorderFromProps(props)};
      background-color: #f9f9f9;
    `}

  ${({ error }) => error &&
    css `
      border: ${props => getBorderFromProps(props)};
    `}

  ${({ disabled, theme }) => disabled &&
    css `
      -webkit-text-fill-color: ${theme.input.disabledTextColor};
      background-color: ${theme.input.disabledBackground};
      cursor: ${theme.input.disabledCursor};
    `}
`;
const Disabled = styled.div `
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.7);
  cursor: ${({ theme }) => theme.input.disabledCursor};
`;
const Controls = styled.div `
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: ${rem(4)} ${rem(-6)} 0 ${rem(-6)};
  max-width: ${rem(288)};
  width: 100%;
  flex-shrink: 0;
`;
const Control = styled(Link) `
  display: flex;
  align-items: center;
  font-size: ${rem(14)};
  font-family: ${({ theme }) => theme.fontFamily};
  & > *:first-child {
    margin-right: ${rem(4)};
  }
  margin: ${rem(6)};
`;
const NoImageControl = styled(Control) `
  font-size: ${rem(16)};
  margin: 0;
  & > *:first-child {
    margin-right: ${rem(6)};
  }
`;
const StyledMediaManagerImage = styled(MediaManagerImage) `
  max-width: 100%;
  max-height: 100%;
  min-width: auto;
  min-height: auto;
`;
const ImageControl = ({ error, disabled, auth, value, onChange, onBlur, theme, }) => {
    const [photoEditorOpened, setPhotoEditorOpened] = React.useState(false);
    const [mediamanagerOpened, setMediamanagerOpened] = React.useState(false);
    const closePhotoEditor = React.useCallback(() => {
        setPhotoEditorOpened(false);
        if (onBlur) {
            onBlur(typeof value === 'undefined' ? null : value);
        }
    }, [onBlur, value]);
    const closeMediaManager = React.useCallback(() => {
        setMediamanagerOpened(false);
        if (onBlur) {
            onBlur(typeof value === 'undefined' ? null : value);
        }
    }, [onBlur, value]);
    const openMediaManager = React.useCallback((e) => {
        e.stopPropagation();
        setMediamanagerOpened(true);
    }, []);
    const openPhotoEditor = React.useCallback(() => {
        setPhotoEditorOpened(true);
    }, []);
    const handleMediaManagerFilesSelect = React.useCallback(files => {
        if (files.length > 0) {
            const file = files[0];
            closeMediaManager();
            if (onChange) {
                const nextValue = {
                    media_url: file.media_url,
                    public_url: file.public_url,
                };
                if (file.author) {
                    nextValue.author = file.author;
                }
                if (file.source) {
                    nextValue.source = file.source;
                }
                if (file.smarttags) {
                    nextValue.smarttags = file.smarttags;
                }
                if (file.description) {
                    nextValue.description = file.description;
                }
                onChange(nextValue);
                if (onBlur) {
                    onBlur(nextValue);
                }
            }
        }
    }, [onChange, onBlur, closeMediaManager]);
    const photoEditorSubmit = React.useCallback((data) => {
        if (onChange && value) {
            const nextValue = Object.assign(Object.assign({}, value), { dimensions: {
                    X: data.x,
                    Y: data.y,
                    W: data.width,
                    H: data.height,
                } });
            closePhotoEditor();
            onChange(nextValue);
            if (onBlur) {
                onBlur(nextValue);
            }
        }
    }, [value, onChange, onBlur, closePhotoEditor]);
    const clear = React.useCallback(() => {
        if (onChange) {
            onChange(null);
            if (onBlur) {
                onBlur(null);
            }
        }
    }, [onChange, onBlur]);
    return (React.createElement(Wrapper, null,
        photoEditorOpened && (React.createElement(MediaManagerPhotoEditor, { picture: value || {}, onClose: closePhotoEditor, onSubmit: photoEditorSubmit })),
        React.createElement(ImageContainer, { isImageSelected: !!value, disabled: !!disabled, error: !!error, onClick: disabled ? void 0 : openMediaManager },
            mediamanagerOpened ? (React.createElement(Loader, null)) : value ? (React.createElement(StyledMediaManagerImage, { naturalWidth: 0, naturalHeight: IMAGE_HEIGHT, picture: value, script: "media" })) : (React.createElement(NoImageControl, { faded: true, onClick: openMediaManager },
                React.createElement(Icon, { name: "image", color: theme.form.placeholderColor, size: 24 }),
                __('Vyberte obrázok'))),
            disabled && React.createElement(Disabled, null)),
        !!value && !disabled && (React.createElement(Controls, null,
            React.createElement(Control, { onClick: openMediaManager },
                React.createElement(Icon, { name: "add-circle-outline", color: theme.color.fadedText, size: 16 }),
                __('Nový obrázok')),
            React.createElement(Control, { onClick: openPhotoEditor },
                React.createElement(Icon, { name: "crop", color: theme.color.fadedText, size: 16 }),
                __('Upraviť')),
            React.createElement(Control, { onClick: clear },
                React.createElement(Icon, { name: "trash", color: theme.color.fadedText, size: 16 }),
                __('Odstrániť'))))));
};
export default withTheme(ImageControl);
