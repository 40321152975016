export const __ = (string) => string;

export function getProp(object, path, defaultValue = '') {
  if (path === undefined || path === null) return defaultValue;
  let value = object;
  if (typeof value === 'undefined' || value === null) {
    return defaultValue;
  }
  for (let i = 0; i < path.length; i++) {
    value = value[path[i]];
    if (typeof value === 'undefined' || value === null) {
      return defaultValue;
    }
  }
  return value;
}
