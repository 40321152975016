import { rem } from 'polished';
import 'react-datepicker/dist/react-datepicker.css';
import styled from '../Themes/styled-components';
const DatePickerWrapper = styled.div `
  width: 100%;

  .react-datepicker__time-list {
    padding: 0px;
  }

  .react-datepicker__input-container {
    width: 100%;
  }

  .react-datepicker-wrapper {
    width: 100%;
  }

  .react-datepicker-popper {
    z-index: 15;
    min-width: ${rem(320)};
  }

  .react-datepicker__close-icon::after {
    background-color: transparent;
    color: #6e6e6e;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: ${rem(15)};
  }

  .react-datepicker__close-icon {
    display: block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: ${rem(10)};
  }
`;
export default DatePickerWrapper;
export { default as Input } from '../Input';
