import { __rest } from "tslib";
import * as React from 'react';
import AsyncInputAutocomplete from '../../AsyncInputAutocomplete';
import InputAutocomplete from '../../InputAutocomplete';
import styled from '../../Themes/styled-components';
// import { smarttagsPropType } from './propTypes';
const Wrapper = styled.div ``;
class InputSmarttag extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: props.defaultInputValue,
        };
        this.handleChangeValue = this.handleChangeValue.bind(this);
        this.getItemLabel = this.getItemLabel.bind(this);
        this.handleSelectItem = this.handleSelectItem.bind(this);
    }
    getItemLabel(item) {
        const { title } = item;
        if (title && title !== '') {
            return title;
        }
        return item.key;
    }
    getItems(availableSmarttags) {
        const { value } = this.state;
        const [nameOfSmarttag, valueOfSmarttag] = value.split(':');
        if (typeof valueOfSmarttag !== 'undefined') {
            // smarttag value is being typed  (second part of smarttag)
            // we should filter available smarttag values
            const smarttagName = availableSmarttags.find((smarttag) => smarttag.key === nameOfSmarttag);
            if (smarttagName) {
                const values = smarttagName.values.reduce((prev, smarttag) => {
                    if (smarttag.key
                        .toLocaleLowerCase()
                        .indexOf(valueOfSmarttag.toLocaleLowerCase()) >= 0) {
                        return [...prev, smarttag];
                    }
                    return prev;
                }, []);
                if (values.length === 1) {
                    if (values[0].key === valueOfSmarttag) {
                        return [];
                    }
                }
                return values;
            }
            return [];
        }
        // typing of smarttag name (first part of smarttag)
        // we should filter available smarttags
        return availableSmarttags.reduce((prev, smarttag) => {
            if (smarttag.key
                .toLocaleLowerCase()
                .indexOf(nameOfSmarttag.toLocaleLowerCase()) >= 0) {
                return [...prev, smarttag];
            }
            return prev;
        }, []);
    }
    /**
     * @param {string} value
     */
    handleChangeValue(value) {
        this.setState({ value });
    }
    handleSelectItem(item) {
        const { value } = this.state;
        const { onAdd, readOnly } = this.props;
        if (readOnly) {
            return false;
        }
        const [nameOfSmarttag, valueOfSmarttag] = value.split(':');
        if (item === null) {
            // nothing selected
            if (typeof valueOfSmarttag !== 'undefined' &&
                valueOfSmarttag.length > 0) {
                onAdd({
                    key: nameOfSmarttag.trim(),
                    values: [{ key: valueOfSmarttag.trim() }],
                });
                this.setState({ value: '' });
                return true;
            }
            return false;
        }
        if (typeof valueOfSmarttag !== 'undefined') {
            onAdd({
                key: nameOfSmarttag,
                values: [{ key: item.key }],
            });
            this.setState({ value: '' });
            return true;
        }
        this.setState({ value: `${item.key}:` });
        return false;
    }
    render() {
        const { value } = this.state;
        const _a = this.props, { getSmarttagsKeys, getSmarttagsValues, placeholder, readOnly, disabled, onAdd, availableSmarttags } = _a, restProps = __rest(_a, ["getSmarttagsKeys", "getSmarttagsValues", "placeholder", "readOnly", "disabled", "onAdd", "availableSmarttags"]);
        const smarttagKey = value.split(':')[0];
        const currentValue = value.split(':')[1];
        const insertValue = value.includes(':');
        const currentGetFunc = insertValue
            ? (valueOfSmarttag) => this.props.getSmarttagsValues(smarttagKey, valueOfSmarttag.split(':')[1])
            : getSmarttagsKeys;
        return (React.createElement(Wrapper, null, getSmarttagsKeys && getSmarttagsValues ? (React.createElement(AsyncInputAutocomplete, Object.assign({ placeholder: placeholder, value: value, onChange: this.handleChangeValue, onSelectItem: this.handleSelectItem, getItemLabel: this.getItemLabel, getItems: currentGetFunc, readOnly: readOnly, valid: !!currentValue, cache: true, submitAllowed: true, "data-testid": "InputSmarttag-Input" }, restProps))) : (React.createElement(InputAutocomplete, Object.assign({ placeholder: placeholder, value: value, onChange: this.handleChangeValue, getItemLabel: this.getItemLabel, items: this.getItems(this.props.availableSmarttags), onSelectItem: this.handleSelectItem, valid: !!currentValue, readOnly: readOnly, disabled: disabled, submitAllowed: true, "data-testid": "InputSmarttag-Input" }, restProps)))));
    }
}
InputSmarttag.defaultProps = {
    onAdd: () => { },
    getSmarttagsKeys: null,
    getSmarttagsValues: null,
    availableSmarttags: [],
    placeholder: '',
    readOnly: false,
    defaultInputValue: '',
    disabled: false,
};
// InputSmarttag.propTypes = {
//   availableSmarttags: smarttagsPropType,
//   onAdd: PropTypes.func,
//   getSmarttagsKeys: PropTypes.func,
//   getSmarttagsValues: PropTypes.func,
//   placeholder: PropTypes.string,
//   readOnly: PropTypes.bool,
//   defaultInputValue: PropTypes.string,
//   disabled: PropTypes.bool,
// };
export default InputSmarttag;
