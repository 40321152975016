import { rem } from 'polished';
import * as React from 'react';
import OriginalLoader from '../../Loader';
import styled from '../../Themes/styled-components';
const StyledLoader = styled.div `
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 0 ${rem(12)};
`;
const InputLoading = (props) => (React.createElement(StyledLoader, { className: props.className },
    React.createElement(OriginalLoader, null)));
export default styled(InputLoading) ``;
