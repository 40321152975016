import { __rest } from "tslib";
import * as React from 'react';
import styled from 'styled-components';
import config from '../config';
import Image from '../Image';
const Img = styled.img `
  max-width: 100%;
`;
const TRANSFORMATION_DELIMINETER = '-';
export const getMediaManagerUrl = (picture, width, height, script = 'resize', webp = false) => {
    if (!picture) {
        return '';
    }
    const transformOperations = [];
    if (picture.dimensions) {
        const { X, Y, W, H } = picture.dimensions;
        transformOperations.push(`${X}x${Y}x${W}x${H}`);
    }
    transformOperations.push(`${width}x${height}x${script}`);
    const size = transformOperations.join(TRANSFORMATION_DELIMINETER);
    let url = '';
    if (picture.media_url) {
        url = picture.media_url.replace('{size}', size);
    }
    else if (picture.uri) {
        url = `${config.MEDIAMANAGER_URL}/images/${size}/${picture.uri}`;
    }
    if (url && webp && !url.endsWith('.webp')) {
        url = `${url}.webp`;
    }
    return url;
};
export const generateSourcesForMmImage = (picture, naturalWidth, naturalHeight, sources, script, webp = false) => {
    const originalRatio = naturalWidth > 0 && naturalHeight > 0 ? naturalWidth / naturalHeight : 0;
    return (sources || []).map(item => {
        const pic = Object.assign(Object.assign({}, picture), { dimensions: item.dimensions || picture.dimensions });
        const src = getMediaManagerUrl(pic, item.naturalWidth, originalRatio > 0 ? Math.round(item.naturalWidth / originalRatio) : 0, script, webp);
        return {
            srcSet: `${src}`,
            media: item.media,
            type: webp ? 'image/webp' : '',
        };
    });
};
class MediaManagerImage extends React.Component {
    render() {
        var _a;
        const _b = this.props, { picture, script, naturalHeight, naturalWidth, webp, sources = [] } = _b, restProps = __rest(_b, ["picture", "script", "naturalHeight", "naturalWidth", "webp", "sources"]);
        // Ak je webp, renderujeme ako <picture> so <source> tagmi a nativnym <img> tagom.
        // Pre svg negenerujeme ziadne sources.
        if (webp) {
            let heightParsed = restProps.height;
            // append units
            if (heightParsed && !Number.isNaN(Number(heightParsed))) {
                heightParsed = `${heightParsed}px`;
            }
            let widthParsed = restProps.width;
            // append units
            if (widthParsed && !Number.isNaN(Number(widthParsed))) {
                widthParsed = `${widthParsed}px`;
            }
            if ((_a = picture.public_url) === null || _a === void 0 ? void 0 : _a.endsWith('.svg')) {
                const src = getMediaManagerUrl(picture, naturalWidth, naturalHeight, script);
                return (React.createElement("picture", { className: restProps.className },
                    React.createElement(Img, { alt: restProps.alt, src: src })));
            }
            else {
                const fallbackSrc = getMediaManagerUrl(picture, naturalWidth, naturalHeight, script);
                // musim vygenerovat default sources pre zakladny obrazok
                const matchedSource = (sources || []).find(item => item.naturalWidth === naturalWidth);
                let preparedSources = [];
                if (!matchedSource) {
                    preparedSources.push({
                        naturalWidth,
                    }, ...sources);
                }
                else {
                    preparedSources.push(...sources);
                }
                const sourcesWebp = generateSourcesForMmImage(picture, naturalWidth, naturalHeight, preparedSources, script, webp);
                // negenerujeme fallback sources tagy, ak ziadne sources nemame definovane.
                // v takom pripade sa vygeneruje jeden <source> pre webp a v <img> tagu
                // bude fallback URL.
                let sourcesFallback = [];
                if (sources.length > 0) {
                    sourcesFallback = generateSourcesForMmImage(picture, naturalWidth, naturalHeight, preparedSources, script);
                }
                return (React.createElement("picture", { className: restProps.className },
                    [...sourcesWebp, ...sourcesFallback].map((sourceItem, idx) => (React.createElement("source", Object.assign({ key: `${sourceItem.srcSet}-${idx}` }, sourceItem)))),
                    React.createElement(Img, { alt: restProps.alt, src: fallbackSrc, height: heightParsed, width: widthParsed })));
            }
        }
        const src = getMediaManagerUrl(picture, naturalWidth, naturalHeight, script, webp);
        return React.createElement(Image, Object.assign({}, restProps, { src: src || '' }));
    }
}
export default MediaManagerImage;
