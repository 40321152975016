import { __rest } from "tslib";
import { rem } from 'polished';
import * as React from 'react';
import { ContentLoader } from '../Loader';
import styled, { css } from '../Themes/styled-components';
const MIN_HEIGHT = 16;
const Wrapper = styled('div') `
  overflow: hidden;
  display: inline-block;
  max-width: 100%;
  ${({ width, height }) => css `
    width: ${width};
    height: ${height};
  `};
`;
const Loader = styled(ContentLoader) `
  height: 100%;
  width: 100%;
  min-height: ${MIN_HEIGHT}px;
  min-width: ${MIN_HEIGHT}px;
`;
const NoImage = styled('div') `
  height: 100%;
  width: 100%;
  min-height: ${MIN_HEIGHT}px;
  min-width: ${MIN_HEIGHT}px;
  ${({ theme }) => css `
    box-shadow: inset 0 0 ${rem(0)} ${rem(2)} ${theme.separatorColor};
  `}

  &:after,
  &:before {
    content: '';
    position: absolute;
    transform: rotate(45deg);
    left: 0;
    right: 0;
    height: ${rem(2)};
    top: 50%;
    background: ${({ theme }) => theme.separatorColor};
  }

  &:before {
    transform: rotate(-45deg);
  }

  position: relative;
  vertical-align: middle;
`;
const Img = styled.img `
  max-width: 100%;
`;
class Image extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isFetching: false,
            error: false,
        };
        this.imgEl = null;
        this.init = (src) => {
            if (this.imgEl) {
                // remove ol' listeners
                this.imgEl.removeEventListener('load', this.handleLoad);
                this.imgEl.removeEventListener('error', this.handleError);
            }
            if (!src) {
                return;
            }
            if (process.env.NODE_ENV === 'test') {
                this.setState({ isFetching: false });
            }
            else {
                this.setState({ isFetching: true });
            }
            if (this.imgEl) {
                this.imgEl.src = src;
                // add new listeners
                if (this.imgEl.complete) {
                    this.setState({
                        isFetching: false,
                        error: false,
                    });
                }
                else {
                    this.imgEl.addEventListener('load', this.handleLoad);
                }
                this.imgEl.addEventListener('error', this.handleError);
            }
        };
        this.handleLoad = () => {
            this.setState({
                isFetching: false,
                error: false,
            });
        };
        this.handleError = () => {
            const { fallbackSrc } = this.props;
            if (this.imgEl && fallbackSrc && this.imgEl.src !== fallbackSrc) {
                this.init(fallbackSrc);
            }
            else {
                this.setState({
                    isFetching: false,
                    error: true,
                });
            }
        };
        if (typeof document !== 'undefined') {
            this.imgEl = document.createElement('img');
        }
    }
    componentDidUpdate(prevProps) {
        if (prevProps.src !== this.props.src ||
            prevProps.fallbackSrc !== this.props.fallbackSrc) {
            this.init(this.props.src);
        }
    }
    componentDidMount() {
        this.init(this.props.src);
    }
    componentWillUnmount() {
        if (this.imgEl) {
            this.imgEl.removeEventListener('load', this.handleLoad);
            this.imgEl.removeEventListener('error', this.handleError);
        }
    }
    render() {
        const _a = this.props, { src, alt, height, width, fallbackSrc } = _a, restProps = __rest(_a, ["src", "alt", "height", "width", "fallbackSrc"]);
        const { isFetching, error } = this.state;
        let heightParsed = height;
        // append units
        if (heightParsed && !Number.isNaN(Number(heightParsed))) {
            heightParsed = `${heightParsed}px`;
        }
        let widthParsed = width;
        // append units
        if (widthParsed && !Number.isNaN(Number(widthParsed))) {
            widthParsed = `${widthParsed}px`;
        }
        if (isFetching) {
            return (React.createElement(Wrapper, Object.assign({ title: alt, height: heightParsed, width: widthParsed }, restProps),
                React.createElement(Loader, null)));
        }
        if (error || (!src && !fallbackSrc)) {
            return (React.createElement(Wrapper, Object.assign({ title: alt, height: heightParsed, width: widthParsed }, restProps),
                React.createElement(NoImage, null)));
        }
        return (React.createElement(Img, Object.assign({ alt: alt, src: this.imgEl ? this.imgEl.src : src, height: heightParsed, width: widthParsed }, restProps)));
    }
}
export default Image;
