import Button from '../../ui/Button';
import Modal, { ModalContent } from '../../ui/Modal';
import { mb } from '../../ui/Themes/utilities';
import { rem } from 'polished';
import PropTypes from 'prop-types';
import moment from 'moment';
import * as React from 'react';
import { slide as Slide } from 'react-burger-menu';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { getProp } from '../../utilities';
import styled, { css, withTheme } from 'styled-components';
import Api from '../../Api';
import Logo from '../../Assets/logo_kuriereu.png';
import { unauthorize } from '../../Containers/ApplicationWrapper/actions';
import { getMyUserProfile } from '../../Containers/Me/actions';
import { __ } from '../../utilities';

const Wrapper = styled.div`
  height: ${rem(70)};
  width: 100%;
  border-bottom: 1px solid #eee;
  background: white;
`;
const Brand = styled.img`
  height: 80%;
  padding-left: ${rem(15)};
`;
const ResponsiveMenu = styled.div`
  display: block;
  a:focus {
    outline: none;
  }
  ${mb('s')} {
    display: none;
  }
`;
const Items = styled.div`
  display: none;
  flex-direction: row;
  height: 100%;
  ${mb('s')} {
    display: flex;
  }
`;
const ResponsiveItem = styled.div`
  padding: ${rem(10)} ${rem(15)};
  height: 100%;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: ${rem(13)};
`;
const Item = styled.div`
  padding: ${rem(10)} ${rem(15)};
  height: 100%;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: #333;
  font-weight: bold;
  font-size: ${rem(13)};
  ${({ active }) => {
    if (active) {
      return css`
        background: ${({ theme }) => theme.color.primary} !important;
        color: white;
      `;
    }
    return css``;
  }}
  &:hover {
    background: #eee;
    cursor: pointer;
  }
`;
const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  align-items: center;
  max-width: ${rem(1000)};
  margin: auto;
  display: flex;
`;

class Menu extends React.Component {
  state = {
    menuOpened: false,
    isSubmittingGDPR: false,
  };
  items = [
    {
      to: '/',
      label: __('Domov'),
    },
    {
      to: '/order',
      label: __('Pridať zásielku'),
      role: 'client',
    },
    {
      to: '/clients',
      label: __('Zoznam klientov'),
      role: 'driver',
    },
    {
      to: '/clients',
      label: __('Zoznam klientov'),
      role: 'spediter',
    },
    {
      to: '/me/profile',
      label: __('Upraviť profil'),
    },
    {
      onClick: () => {
        this.logout();
      },
      label: __('Odhlásiť sa'),
    },
  ];
  logout = () => {
    const { dispatch, username, token } = this.props;
    dispatch(unauthorize(username, token));
    this.props.history.push('/');
  };

  handleStateChange = (state) => {
    this.setState({ menuOpened: state.isOpen });
  };

  submitGDPR = async () => {
    try {
      this.setState({
        isSubmittingGDPR: true,
      });
      await Api.meGdprUpdate({ data: { gdpr: true } });
      this.props.dispatch(getMyUserProfile());
    } catch (e) {
      alert(__('Súhlas sa nepodarilo potvrdiť.'));
    } finally {
      this.setState({
        isSubmittingGDPR: false,
      });
    }
  };

  renderItems = () => {
    const { pathname } = this.props;
    return this.items.map((item) => {
      if (item.role && item.role !== this.props.role) {
        return '';
      }
      if (
        moment().isAfter(moment('2024-12-05')) &&
        moment().isBefore(moment('2024-12-26')) &&
        this.props.role === 'client' &&
        item.to === '/order'
      ) {
        return '';
      }
      if (item.to) {
        return (
          <Link to={item.to}>
            <Item active={pathname === item.to}>{item.label}</Item>
          </Link>
        );
      }
      return <Item onClick={item.onClick}>{item.label}</Item>;
    });
  };

  renderResponsiveItems = () => {
    const { pathname } = this.props;
    return this.items.map((item) => {
      if (item.to) {
        return (
          <Link
            className="menu-item"
            to={item.to}
            onClick={() => {
              this.setState({
                menuOpened: false,
              });
            }}
          >
            <ResponsiveItem active={pathname === item.to}>
              {item.label}
            </ResponsiveItem>
          </Link>
        );
      }
      return (
        <ResponsiveItem className="menu-item" onClick={item.onClick}>
          {item.label}
        </ResponsiveItem>
      );
    });
  };

  render() {
    const styles = {
      bmBurgerButton: {
        position: 'absolute',
        width: '36px',
        height: '30px',
        right: '20px',
        top: '20px',
      },
      bmBurgerBars: {
        background: '#373a47',
      },
      bmBurgerBarsHover: {
        background: '#a90000',
      },
      bmCrossButton: {
        height: '24px',
        width: '24px',
      },
      bmCross: {
        background: '#fff',
      },
      bmMenuWrap: {
        position: 'fixed',
        height: '100%',
        top: 0,
        // width: '100%'
      },
      bmMenu: {
        background: this.props.theme.color.primary,
        padding: '2.5em 1.5em 0',
        fontSize: '1.15em',
      },
      bmMorphShape: {
        fill: '#373a47',
      },
      bmItemList: {
        color: '#b8b7ad',
        display: 'flex',
        flexDirection: 'column',
      },
      bmItem: {
        display: 'inline-block',
        color: '#fff',
      },
      bmOverlay: {
        background: 'rgba(0, 0, 0, 0.3)',
      },
    };

    const isGdprAccepted =
      this.props.userInfo && !!this.props.userInfo.gdprAcceptedOn;

    return (
      <Wrapper>
        {!this.props.isFetchingUserInfo &&
          this.props.userInfo &&
          !isGdprAccepted && (
            <Modal centered isOpen handleClose={() => {}}>
              <ModalContent>
                {__('Dobrý deň,')}
                <br />
                <br />
                {__(
                  'Pre pokračovanie využívania našich služieb prostredníctvom tohto systému je potrebné, aby ste súhlasili s podmienkami spracúvania osobných údajov, ktoré si môžete',
                )}
                &nbsp;
                <a href="/assets/gdpr.docx">{__('stiahnuť na tomto odkaze')}</a>
                <br />
                <br />
                <Button
                  loading={this.state.isSubmittingGDPR}
                  onClick={this.submitGDPR}
                  primary
                >
                  {__('Súhlasím so spracovaním osobných údajov')}
                </Button>
              </ModalContent>
            </Modal>
          )}
        <Container>
          <Link style={{ height: '100%' }} to="/">
            <Brand src={Logo} />
          </Link>
          <Items>{this.renderItems()}</Items>
          <ResponsiveMenu>
            <Slide
              right
              styles={styles}
              isOpen={this.state.menuOpened}
              onStateChange={this.handleStateChange}
            >
              {this.renderResponsiveItems()}
            </Slide>
          </ResponsiveMenu>
        </Container>
      </Wrapper>
    );
  }
}

const mapStateToProps = (state) => ({
  username: state.application.user.username,
  userInfo: state.me.user.data,
  isFetchingUserInfo: state.me.user.isFetching,
  token: state.application.user.token,
  role: getProp(state, ['me', 'user', 'data', 'role']),
});

Menu.propTypes = {
  username: PropTypes.string.isRequired,
  token: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  pathname: PropTypes.string.isRequired,
};

export default connect(mapStateToProps)(withRouter(withTheme(Menu)));
