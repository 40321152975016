import * as PropTypes from 'prop-types';
import * as React from 'react';
import CheckboxRedux from '../Checkbox/redux-form';
import ColorControlRedux from '../ColorControl/redux-form';
import ColorPickerRedux from '../ColorPicker/redux-form';
import { DatePicker as DatePickerRedux, DateTimePicker as DateTimePickerRedux, TimePicker as TimePickerRedux, } from '../DatePickers/redux-form';
import ImageControlRedux from '../ImageControl/redux-form';
import InputRedux from '../Input/redux-form';
import InputAutocompleteRedux from '../InputAutocomplete/redux-form';
import RadioRedux from '../Radio/redux-form';
import SelectRedux from '../Select/redux-form';
import SelectAutocompleteRedux from '../SelectAutocomplete/redux-form';
import SmarttagControlRedux from '../SmarttagsControl/SmarttagControl/redux-form';
import SwitchRedux from '../Switch/redux-form';
import TextareaRedux from '../Textarea/redux-form';
import TheSelectRedux from '../TheSelect/redux-form';
import TheSelectSimpleRedux from '../TheSelectSimple/redux-form';
import FormField from './index';
const DEFAULT_COMPONENT_MAP_REDUX = {
    autocomplete: InputAutocompleteRedux,
    checkbox: CheckboxRedux,
    color: ColorPickerRedux,
    colorInput: ColorControlRedux,
    date: DatePickerRedux,
    dateTime: DateTimePickerRedux,
    default: InputRedux,
    image: ImageControlRedux,
    radio: RadioRedux,
    select: SelectRedux,
    selectautocomplete: SelectAutocompleteRedux,
    smarttag: SmarttagControlRedux,
    switch: SwitchRedux,
    textarea: TextareaRedux,
    theselect: TheSelectRedux,
    theselectsimple: TheSelectSimpleRedux,
    time: TimePickerRedux,
};
const FormFieldRedux = (props) => {
    const { meta: { touched, error }, componentMap = DEFAULT_COMPONENT_MAP_REDUX, } = props;
    return (React.createElement(FormField, Object.assign({}, props, { componentMap: componentMap, error: touched && error ? error : null })));
};
FormFieldRedux.propTypes = {
    meta: PropTypes.shape({
        touched: PropTypes.bool,
        error: PropTypes.string,
    }).isRequired,
    componentMap: PropTypes.shape({}),
};
FormFieldRedux.defaultProps = {
    componentMap: DEFAULT_COMPONENT_MAP_REDUX,
};
export default FormFieldRedux;
