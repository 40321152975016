import { createSelector } from 'reselect';
import { getProp } from '../../utilities';

const applicationDomainSelector = (state) => state.application;

const userDomainSelector = createSelector(
  applicationDomainSelector,
  (application) => application.user,
);

export const usernameSelector = createSelector(
  userDomainSelector,
  (user) => user.username,
);

export const accessTokenSelector = createSelector(
  userDomainSelector,
  (user) => user.token,
);

export const isUserLoggedInSelector = createSelector(
  userDomainSelector,
  (user) =>
    !!(getProp(user, ['username'], false) && getProp(user, ['token'], false)) ||
    false,
);

export const userIsFetchingSelector = createSelector(
  userDomainSelector,
  (user) => getProp(user, ['isFetching']),
);

export const breadcrumbsSelector = createSelector(
  applicationDomainSelector,
  (app) => app.breadcrumbs,
);

export const aclSelector = createSelector(
  applicationDomainSelector,
  (app) => app.acl,
);
