import { lighten, rem } from 'polished';
import * as React from 'react';
import { Collapse } from 'react-collapse';
import Loader from '../Loader';
import styled, { css, withTheme } from '../Themes/styled-components';
import { createSizer } from '../utilities';
const getPaddingSize = createSizer({
    xxxs: 4,
    xxs: 6,
    xs: 8,
    s: 10,
    m: 12,
    l: 16,
    xl: 20,
    xxl: 24,
    xxxl: 32,
}, 12);
const Wrapper = styled('div') `
  .ReactCollapse--collapse {
    transition: height 250ms cubic-bezier(0.4, 0, 0.2, 1);
  }
  .ReactCollapse--content {
    display: flex;
    flex-direction: column;
  }
  position: relative;
  padding: ${({ paddingSize }) => rem(getPaddingSize(paddingSize || 0))};
  border-radius: ${({ theme }) => theme.largeBorderRadius};
  margin-bottom: ${({ theme }) => rem(theme.grid.gutterWidth)};
  ${props => {
    if (props.danger) {
        return css `
        background: ${({ theme }) => lighten(0.37, theme.color.danger)};
        box-shadow: 0px ${rem(1)} ${rem(5)} 0 rgba(0, 0, 0, 0.1);
      `;
    }
    else if (props.success) {
        return css `
        background: ${({ theme }) => lighten(0.37, theme.color.success)};
        box-shadow: 0px ${rem(1)} ${rem(5)} 0 rgba(0, 0, 0, 0.1);
      `;
    }
    else if (props.warning) {
        return css `
        background: ${({ theme }) => lighten(0.46, theme.color.warning)};
        box-shadow: 0px ${rem(1)} ${rem(5)} 0 rgba(0, 0, 0, 0.1);
      `;
    }
    else if (props.primary) {
        return css `
        background: ${({ theme }) => lighten(0.37, theme.color.primary)};
        box-shadow: 0px ${rem(1)} ${rem(5)} 0 rgba(0, 0, 0, 0.1);
      `;
    }
    else if (props.raised) {
        return css `
        background: #fff;
        box-shadow: 0px ${rem(1)} ${rem(5)} 0 rgba(0, 0, 0, 0.1);
      `;
    }
    else if (props.secondary) {
        return css `
        background: #f9f9f9;
      `;
    }
    else {
        return css `
        background: transparent;
      `;
    }
}};
  ${props => props.noBottomGutter &&
    css `
      margin-bottom: 0;
    `};
`;
const LoaderWrapper = styled('div') `
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.7);
`;
class Segment extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            collapsed: !!props.header &&
                !props.header.props.toggleCollapse &&
                !!props.header.props.isCollapsed,
        };
    }
    render() {
        const { children, raised, secondary, noBottomGutter, header, loading, theme, className, danger, success, warning, primary, paddingSize, } = this.props;
        const { collapsed } = this.state;
        const internalCollapsed = header && !!header.props.toggleCollapse
            ? !!header.props.isCollapsed
            : collapsed;
        return (React.createElement(Wrapper, { danger: danger, success: success, warning: warning, primary: primary, raised: raised, secondary: secondary, noBottomGutter: noBottomGutter, paddingSize: paddingSize, className: className },
            loading && (React.createElement(LoaderWrapper, null,
                React.createElement(Loader, { color: theme.color.primary, size: "l" }))),
            !!header && !!header.props.collapsible && (React.createElement(React.Fragment, null,
                React.cloneElement(this.props.header, {
                    isCollapsed: internalCollapsed,
                    toggleCollapse: (state) => {
                        if (header.props.toggleCollapse) {
                            header.props.toggleCollapse(state);
                        }
                        else {
                            this.setState({
                                collapsed: state,
                            });
                        }
                    },
                }),
                React.createElement(Collapse, { isOpened: !internalCollapsed },
                    React.createElement(React.Fragment, null, children)))),
            !!header && !header.props.collapsible && (React.createElement(React.Fragment, null,
                React.cloneElement(this.props.header),
                children)),
            !header && children));
    }
}
export default withTheme(Segment);
