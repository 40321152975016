import { __rest } from "tslib";
import { rem } from 'polished';
import React from 'react';
import FormGroupContext from '../FormGroup/FormGroupContext';
import InfoTooltip from '../InfoTooltip';
import styled, { css, withTheme } from '../Themes/styled-components';
export const Wrapper = styled.div `
  display: flex;
  align-items: center;
`;
const LabelWrapper = styled.label `
  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${({ theme }) => theme.form.labelColor};
  font-weight: bold;
  font-size: ${rem(12)};
  ${({ required }) => required &&
    css `
      &::after {
        content: '*';
        color: ${({ theme }) => theme.color.danger};
        margin-left: ${rem(3)};
      }
    `}
`;
const InfoWrapper = styled.div `
  margin-left: ${rem(6)};
  display: flex;
`;
const Label = (_a) => {
    var { children, className, htmlFor, required, info, theme } = _a, other = __rest(_a, ["children", "className", "htmlFor", "required", "info", "theme"]);
    const { id } = React.useContext(FormGroupContext);
    return (React.createElement(Wrapper, null,
        React.createElement(LabelWrapper, Object.assign({ required: required, htmlFor: htmlFor || id, className: className }, other), children),
        info && (React.createElement(InfoWrapper, null,
            React.createElement(InfoTooltip, { content: info, size: 14, color: theme.form.labelColor })))));
};
export default withTheme(Label);
