import { __rest } from "tslib";
import { rem } from 'polished';
import * as React from 'react';
import AsyncSelect from '../AsyncSelect';
import Checkbox from '../Checkbox';
import ColorControl from '../ColorControl';
import ColorPicker from '../ColorPicker';
import { DatePicker, DateTimePicker, TimePicker } from '../DatePickers';
import FormGroup from '../FormGroup';
import ImageControl from '../ImageControl';
import Input from '../Input';
import InputAutocomplete from '../InputAutocomplete';
import Label from '../Label/Label';
import Radio from '../Radio';
import Select from '../Select';
import SelectAutocomplete from '../SelectAutocomplete';
import SmarttagControl from '../SmarttagsControl/SmarttagControl';
import Switch from '../Switch';
import Textarea from '../Textarea';
import styled from '../Themes/styled-components';
import TheSelect from '../TheSelect';
import TheSelectSimple from '../TheSelectSimple';
const DEFAULT_COMPONENT_MAP = {
    autocomplete: InputAutocomplete,
    checkbox: Checkbox,
    color: ColorPicker,
    colorInput: ColorControl,
    date: DatePicker,
    dateTime: DateTimePicker,
    default: Input,
    image: ImageControl,
    radio: Radio,
    select: Select,
    selectautocomplete: SelectAutocomplete,
    smarttag: SmarttagControl,
    switch: Switch,
    textarea: Textarea,
    time: TimePicker,
    theselect: TheSelect,
    theselectsimple: TheSelectSimple,
    asyncselect: AsyncSelect,
};
const ErrorText = styled.div `
  font-size: ${rem(11)};
  color: ${({ theme }) => theme.color.danger};
  position: absolute;
  margin-top: ${rem(3)};
  left: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
`;
const RelativeWrapper = styled.div `
  position: relative;
  padding-bottom: ${rem(10)};
`;
const Counter = styled(ErrorText) `
  color: ${({ theme }) => theme.textColor};
`;
const C = (_a) => {
    var { error, type = 'default', label, required, className, componentMap = DEFAULT_COMPONENT_MAP, info, counter = {} } = _a, restProps = __rest(_a, ["error", "type", "label", "required", "className", "componentMap", "info", "counter"]);
    const [internalCounter, setInternalCounter] = React.useState({
        characters: 0,
        words: 0,
    });
    React.useEffect(() => {
        const initValue = restProps.input ? restProps.input.value : restProps.value;
        if (initValue) {
            setCharactersAndWordsCount(initValue);
        }
    }, [restProps.value, restProps.input]);
    const setCharactersAndWordsCount = (value) => {
        if (typeof value === 'string') {
            const text = value.trim().split(/\s+/);
            setInternalCounter({
                characters: text.join(' ').length,
                words: text.filter(i => i).length,
            });
        }
    };
    const formatWordsCounter = () => {
        let counterItems = [];
        if (counter.words) {
            counterItems.push(`${internalCounter.words} slov`);
        }
        if (counter.characters) {
            counterItems.push(`${internalCounter.characters} znakov`);
        }
        return counterItems.join(', ');
    };
    const Component = componentMap[type] || componentMap.default;
    const withCounter = counter.characters || counter.words;
    if (type === 'checkbox' || type === 'radio') {
        return (React.createElement(FormGroup, { className: className },
            React.createElement(RelativeWrapper, null,
                React.createElement(Label, { required: required, info: info },
                    React.createElement(Component, Object.assign({ error: error }, restProps)),
                    " ",
                    label),
                !!error && React.createElement(ErrorText, { title: error }, error))));
    }
    return (React.createElement(FormGroup, { className: className },
        label && (React.createElement(Label, { required: required, info: info }, label)),
        React.createElement(RelativeWrapper, null,
            React.createElement(Component, Object.assign({ error: error }, restProps)),
            !!error && React.createElement(ErrorText, { title: error }, error),
            withCounter &&
                !error &&
                (type === 'default' || type === 'textarea') && (React.createElement(Counter, null, formatWordsCounter())))));
};
export default C;
