import { rem } from 'polished';
import * as React from 'react';
import TooltipLite from 'react-tooltip-lite';
import { createGlobalStyle } from '../Themes/styled-components';
const Styles = createGlobalStyle `
  .react-tooltip-lite {
    border-radius: ${({ theme }) => rem(theme.tooltip.borderRadius)};
    background: ${({ theme }) => theme.tooltip.backgroundColor};
    color: ${({ theme }) => theme.tooltip.textColor};
    font-size: ${rem(14)};
    * {
      color: ${({ theme }) => theme.tooltip.textColor};
    }
  }

  .react-tooltip-lite-arrow {
    border-color: ${({ theme }) => theme.tooltip.backgroundColor};
  }
`;
const Tooltip = ({ content, children }) => {
    return (React.createElement(React.Fragment, null,
        React.createElement(Styles, null),
        React.createElement(TooltipLite, { hoverDelay: 0, content: content, arrowSize: 6, distance: 8, padding: `${rem(12)} ${rem(16)}`, styles: { display: 'inline-flex' } }, children)));
};
export default Tooltip;
