import React from 'react';
import { IsolatedCheckBox } from '.';
import Icon from '../Icon';
import { Td, Tr } from '../Table';
import styled from '../Themes/styled-components';
import { emptyFn } from '../utilities';
const TdHandle = styled(Td) `
  cursor: grab;
`;
const TdPointer = styled(Td) `
  cursor: pointer;
`;
const TrStyled = styled(Tr) `
  transition: box-shadow 0.4s;
  box-shadow: none;
  &.slip-reordering {
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
    > td {
      background-color: #ddd !important;
    }
  }
`;
const BasicTableRow = ({ onClickRow, row, rowIdx, onReorder, renderRow, onSelectRow, isSelectable, isSelected, rowStyle, }) => {
    const handleClickRow = onClickRow &&
        ((event) => {
            onClickRow(row, rowIdx, event);
        });
    return (React.createElement(TrStyled, { onClick: (onClickRow ? handleClickRow : void 0), style: rowStyle ? rowStyle(row, rowIdx) : undefined },
        onReorder && (React.createElement(TdHandle, { className: "drag-handle-cell" },
            React.createElement(Icon, { name: "drag-handle" }))),
        isSelectable && (React.createElement(TdPointer, { onClick: (e) => {
                e.preventDefault();
                e.stopPropagation();
                onSelectRow && onSelectRow(row, rowIdx);
            } },
            React.createElement(IsolatedCheckBox, { checked: !!isSelected, onChange: emptyFn }))),
        renderRow(row, rowIdx).map((item, idx) => (React.createElement(Td, { key: idx }, item)))));
};
export default React.memo(BasicTableRow);
