import React from 'react';
import at from '../utilities/at';
import removeAccents from '../utilities/removeAccents';
import { __ } from '../utilities';
import { CREATE_OPTION_VALUE } from './Options';
export function isOptionSelected(option, value) {
    if (Array.isArray(value)) {
        return !!value.find(v => {
            return v.value === option.value;
        });
    }
    else if (value) {
        return value.value === option.value;
    }
    return false;
}
export function getDefaultCreateOption(inputValue) {
    return {
        value: CREATE_OPTION_VALUE,
        label: `${__('Vytvoriť novú možnosť')} "${inputValue}"`,
    };
}
export function containsExactMatch(options, inputValue) {
    return options.findIndex(option => String(option.value) === inputValue) > -1;
}
export const defaultFilterFunction = (option, inputValue) => {
    const haystack = `${removeAccents(String(option.label)).toLowerCase()} ${removeAccents(String(option.value)).toLowerCase()}`;
    return haystack.indexOf(removeAccents(inputValue).toLowerCase()) > -1;
};
export const defaultFilterOptions = (options, inputValue) => {
    return options.filter(option => defaultFilterFunction(option, inputValue));
};
function isMultiple(value, multiple) {
    return multiple;
}
export function useTheSelectOptions(items, formatOption, value, deps, { multiple = false, } = {}) {
    const [options, setOptions] = React.useState([]);
    const [optionsByValue, setOptionsByValue] = React.useState({});
    React.useEffect(() => {
        const newOptionsByValue = {};
        const newOptions = items.map(unformattedOption => {
            const formattedOption = formatOption(unformattedOption);
            newOptionsByValue[String(formattedOption.value)] = formattedOption;
            return formattedOption;
        });
        setOptions(newOptions);
        setOptionsByValue(newOptionsByValue);
    }, deps); // eslint-disable-line react-hooks/exhaustive-deps
    const selectedOption = React.useMemo(() => {
        if (isMultiple(value, !!multiple)) {
            return at(optionsByValue, value.map(String));
        }
        const stringValue = String(value);
        return stringValue in optionsByValue ? optionsByValue[stringValue] : null;
    }, [optionsByValue, value, multiple]);
    return [options, selectedOption, optionsByValue];
}
