import * as React from 'react';
import styled from '../../Themes/styled-components';
const Content = styled.div `
  display: flex;
  flex-direction: column;
  flex: 1 0 0;
  height: 100%;
  width: 100%;
`;
const Top = styled.div `
  z-index: 1;
`;
const Bottom = styled.div `
  z-index: 1;
`;
const MainWrapper = styled.div `
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  flex: 1 0 0;
`;
const Main = styled.div `
  min-height: 100%;
  display: flex;
  flex-direction: column;
`;
const ScrollLayout = ({ topFixed, children, bottomFixed, className, }) => (React.createElement(Content, { className: className },
    React.createElement(Top, null, topFixed),
    React.createElement(MainWrapper, null,
        React.createElement(Main, null, children)),
    React.createElement(Bottom, null, bottomFixed)));
export default ScrollLayout;
