import { rem } from 'polished';
import * as React from 'react';
import styled, { css } from '../../Themes/styled-components';
const Wrapper = styled('div') `
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 6;
  max-height: ${rem(300)};
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  width: 100%;
  top: 100%;
  left: 0;
  right: 0;
  border: 1px solid ${({ theme }) => theme.separatorColor};
  border-radius: ${({ theme }) => theme.largeBorderRadius};
  ${({ down }) => !down &&
    css `
      top: auto;
      bottom: 100%;
    `}
  ${({ hidden }) => hidden &&
    css `
      visibility: hidden;
    `}
`;
function isWholeElementVisible(elm) {
    var rect = elm.getBoundingClientRect();
    var viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight);
    return !(rect.top < 0 || rect.bottom - viewHeight >= 0);
}
export default React.forwardRef(function (props, ref) {
    const [resolvingPosition, setResolvingPosition] = React.useState(true);
    const [isExpandingDownward, setIsExpandingDownward] = React.useState(true);
    const wrapperEl = React.useRef(null);
    React.useEffect(() => {
        if (wrapperEl && wrapperEl.current) {
            setIsExpandingDownward(isWholeElementVisible(wrapperEl.current));
            setResolvingPosition(false);
        }
    }, []);
    return (React.createElement(Wrapper, { down: isExpandingDownward, hidden: resolvingPosition, ref: (e) => {
            ref.current = e;
            wrapperEl.current = e;
        } }, props.children));
});
