import { rem } from 'polished';
import * as React from 'react';
import styled, { withTheme } from '../Themes/styled-components';
import Header from '../Header';
import Icon from '../Icon';
import Segment from '../Segment';
import { css } from '../Themes/styled-components';
const Wrapper = styled('div') `
  font-size: ${rem(13)};
  ${({ danger, success, warning, primary }) => {
    if (danger) {
        return css `
        * {
          color: ${({ theme }) => theme.color.danger};
        }
      `;
    }
    else if (success) {
        return css `
        * {
          color: ${({ theme }) => theme.color.success};
        }
      `;
    }
    else if (warning) {
        return css `
        * {
          color: ${({ theme }) => theme.color.warning};
        }
      `;
    }
    else if (primary) {
        return css `
        * {
          color: ${({ theme }) => theme.color.primary};
        }
      `;
    }
    return css ``;
}}
`;
const InnerWrapper = styled('div') `
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const IconWrapper = styled('div') `
  display: flex;
  flex-shrink: 0;
  padding: ${({ theme }) => `0 ${rem(theme.grid.gutterWidth * 1.5)} 0 ${rem(theme.grid.gutterWidth / 2)}`};
`;
const NonShrinkableIcon = styled(Icon) `
  flex-shrink: 0;
`;
const CloseWrapper = styled('button') `
  /* resets button styles */
  padding: 0;
  border: none;
  font: inherit;
  background-color: transparent;
  text-align: inherit;
  display: flex;

  flex-shrink: 0;
  cursor: pointer;
`;
class Message extends React.PureComponent {
    constructor() {
        super(...arguments);
        this.getColor = () => {
            const { danger, success, warning, primary, theme } = this.props;
            if (danger) {
                return theme.color.danger;
            }
            else if (success) {
                return theme.color.success;
            }
            else if (warning) {
                return theme.color.warning;
            }
            else if (primary) {
                return theme.color.primary;
            }
            return theme.color.base;
        };
        this.getIcon = () => {
            const { danger, success, warning, primary } = this.props;
            if (success) {
                return (React.createElement(IconWrapper, null,
                    React.createElement(NonShrinkableIcon, { size: "m", name: "check-circle", color: this.getColor() })));
            }
            else if (danger) {
                return (React.createElement(IconWrapper, null,
                    React.createElement(NonShrinkableIcon, { size: "m", name: "remove-circle", color: this.getColor() })));
            }
            else if (warning) {
                return (React.createElement(IconWrapper, null,
                    React.createElement(NonShrinkableIcon, { size: "m", name: "error", color: this.getColor() })));
            }
            else if (primary) {
                return (React.createElement(IconWrapper, null,
                    React.createElement(NonShrinkableIcon, { size: "m", name: "info", color: this.getColor() })));
            }
            return null;
        };
    }
    render() {
        const { danger, success, warning, primary, title, onDispose } = this.props;
        const segmentProps = {
            danger,
            primary,
            success,
            warning,
        };
        return (React.createElement(Wrapper, { danger: danger, success: success, warning: warning, primary: primary },
            React.createElement(Segment, Object.assign({}, segmentProps),
                React.createElement(InnerWrapper, null,
                    this.getIcon(),
                    React.createElement(Header, { size: "xs", noBottomGutter: true, subtitle: this.props.children }, title),
                    !!onDispose && (React.createElement(CloseWrapper, { key: "close", onClick: onDispose },
                        React.createElement(NonShrinkableIcon, { name: "close", color: this.getColor() })))))));
    }
}
Message.defaultProps = {
    danger: false,
    success: false,
    primary: false,
    warning: false,
};
export default withTheme(Message);
