import * as React from 'react';
import Checkbox from '../Checkbox';
import Icon from '../Icon';
import { Table, Tbody, Th, Thead, Tr } from '../Table';
import styled, { css, withTheme } from '../Themes/styled-components';
import { emptyFn } from '../utilities';
import BasicTableRow from './BasicTableRow';
const isBrowser = typeof window !== 'undefined';
const Slip = isBrowser ? require('slipjs') : undefined;
const StyledTh = styled(Th) `
  ${({ onClick }) => onClick &&
    css `
      cursor: pointer;
    `}
`;
const ThContent = styled('div') `
  display: flex;
`;
const ThPointer = styled(Th) `
  cursor: pointer;
`;
export const IsolatedCheckBox = styled(Checkbox) `
  margin: 0;
`;
const BasicTable = ({ columns, rows, onReorder, theme, onSort, renderRow, rowKey, onClickRow, onSelect, selected, rowStyle, }) => {
    const uniq = React.useRef(0);
    const slipjsInstance = React.useRef(null);
    const tbodyEl = React.useRef(null);
    const selectedRef = React.useRef(selected || {});
    selectedRef.current = selected || {};
    const handleSelectRow = React.useCallback((row, rowIdx) => {
        if (onSelect) {
            const key = rowKey ? row[rowKey] : rowIdx;
            onSelect(Object.assign(Object.assign({}, selectedRef.current), { [key]: !selectedRef.current[key] }));
        }
    }, [onSelect, rowKey]);
    const anyRowSelected = !!selected && Object.keys(selected).some(id => selected[id]);
    const handleSelectAllRows = React.useCallback(() => {
        if (onSelect) {
            const newSelected = anyRowSelected
                ? {}
                : rows.reduce((acc, row, rowIdx) => {
                    const key = rowKey ? row[rowKey] : rowIdx;
                    acc[key] = true;
                    return acc;
                }, {});
            onSelect(newSelected);
        }
    }, [anyRowSelected, onSelect, rows, rowKey]);
    React.useEffect(() => {
        if (!rowKey) {
            console.warn('Using BasicTable without defined rowKey. Table operations might not work as intended.');
        }
    }, [rowKey]);
    React.useEffect(() => {
        const _tbodyEl = tbodyEl.current;
        function beforereorder(e) {
            if (e.target.closest('td.drag-handle-cell') === null) {
                e.preventDefault();
            }
        }
        function beforewait(e) {
            e.preventDefault();
        }
        function beforeswipe(e) {
            e.preventDefault();
        }
        function reorder(e) {
            if (onReorder) {
                const nextRows = [...rows];
                nextRows.splice(e.detail.originalIndex, 1);
                nextRows.splice(e.detail.spliceIndex, 0, rows[e.detail.originalIndex]);
                e.target.parentNode.insertBefore(e.target, e.detail.insertBefore);
                onReorder(nextRows);
            }
            return false;
        }
        if (_tbodyEl && onReorder) {
            _tbodyEl.addEventListener('slip:beforereorder', beforereorder, false);
            _tbodyEl.addEventListener('slip:beforeswipe', beforeswipe, false);
            _tbodyEl.addEventListener('slip:beforewait', beforewait, false);
            _tbodyEl.addEventListener('slip:reorder', reorder, false);
            slipjsInstance.current = new Slip(_tbodyEl);
        }
        return () => {
            if (_tbodyEl && onReorder) {
                _tbodyEl.removeEventListener('slip:beforereorder', beforereorder);
                _tbodyEl.removeEventListener('slip:beforeswipe', beforeswipe);
                _tbodyEl.removeEventListener('slip:beforewait', beforewait);
                _tbodyEl.removeEventListener('slip:reorder', reorder);
                slipjsInstance.current.detach();
            }
        };
    }, [onReorder, rows]);
    const renderSort = (header) => {
        if (!header.sortable) {
            return null;
        }
        if (!header.sort) {
            return React.createElement(Icon, { name: "triangle-up-down", color: theme.color.fadedText });
        }
        if (header.sort === 'asc') {
            return React.createElement(Icon, { name: "triangle-up" });
        }
        if (header.sort === 'desc') {
            return React.createElement(Icon, { name: "triangle-down" });
        }
        return null;
    };
    const nextSort = (prevSort) => {
        if (!prevSort) {
            return 'asc';
        }
        if (prevSort === 'asc') {
            return 'desc';
        }
        return undefined;
    };
    const renderColumn = (col, index) => {
        return (React.createElement(StyledTh, { width: col.width, key: index, onClick: col.sortable && typeof onSort === 'function'
                ? () => {
                    onSort(col, nextSort(col.sort));
                }
                : undefined },
            React.createElement(ThContent, null,
                col.header,
                renderSort(col))));
    };
    const _renderRow = React.useCallback((row, rowIdx) => {
        const isRowSelected = !!(selected && selected[rowKey ? row[rowKey] : rowIdx]);
        return (React.createElement(BasicTableRow, { key: rowKey ? row[rowKey] : ++uniq.current, onClickRow: onClickRow, renderRow: renderRow, isSelectable: !!(onSelect || selected), isSelected: isRowSelected, onReorder: onReorder, row: row, rowIdx: rowIdx, onSelectRow: handleSelectRow, rowStyle: rowStyle }));
    }, [
        onClickRow,
        onReorder,
        renderRow,
        rowKey,
        selected,
        onSelect,
        handleSelectRow,
        rowStyle,
    ]);
    return (React.createElement(Table, null,
        React.createElement(Thead, null,
            React.createElement(Tr, null,
                onReorder && React.createElement(Th, { width: 1 }),
                onSelect && selected ? (React.createElement(ThPointer, { width: 1, onClick: (e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        handleSelectAllRows();
                    } },
                    React.createElement(IsolatedCheckBox, { checked: anyRowSelected, onChange: emptyFn }))) : selected ? (React.createElement(Th, { width: 1 })) : null,
                columns.map(renderColumn))),
        React.createElement(Tbody, { ref: tbodyEl }, rows.map(_renderRow))));
};
export default withTheme(BasicTable);
