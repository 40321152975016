import { rem } from 'polished';
import * as React from 'react';
import { Wrapper as Label } from '../Label/Label';
import styled from '../Themes/styled-components';
import FormGroupContext from './FormGroupContext';
const uniqueId = require('lodash.uniqueid');
const Wrapper = styled.div `
  margin-bottom: ${({ theme }) => rem(theme.formGroup.bottomMargin)};
  > ${Label} {
    margin-bottom: ${({ theme }) => rem(theme.formGroup.labelGutter)};
  }
`;
class FormGroup extends React.PureComponent {
    constructor() {
        super(...arguments);
        this.state = {
            id: uniqueId(),
        };
    }
    render() {
        const { children } = this.props;
        return (React.createElement(FormGroupContext.Provider, { value: this.state },
            React.createElement(Wrapper, null, children)));
    }
}
export default FormGroup;
