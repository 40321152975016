import { __rest } from "tslib";
import { rem } from 'polished';
import React from 'react';
import Icon from '../../Icon';
import { getBorderFromProps } from '../../Input/utils';
import Loader from '../../Loader';
import styled, { css, withTheme } from '../../Themes/styled-components';
import AutosizeInput from '../components/AutosizeInput';
import Label from '../components/Label';
import InputValue from './InputValue';
const Wrapper = styled('div') `
  display: flex;
  align-items: center;
  width: 100%;
  flex: 1 0 0;
  border: 0;
  font-size: ${({ theme }) => rem(theme.input.fontSize)};
  ${({ theme }) => css `
    padding: 0 ${rem(theme.input.horizontalPadding)} 0
      ${rem(theme.input.horizontalPadding - 2)};
  `}
  ${({ multiple, disabled }) => !multiple &&
    disabled &&
    css `
      padding-left: ${({ theme }) => rem(theme.input.horizontalPadding)};
    `}
  min-height: ${({ theme }) => rem(theme.input.height)};
  color: ${({ theme }) => theme.textColor};
  font-family: ${({ theme }) => theme.fontFamily};
  outline: 0;
  border: ${props => getBorderFromProps(props)};
  border-radius: ${({ theme }) => theme.largeBorderRadius};
  background-color: ${({ theme }) => theme.input.backgroundColor};
  box-shadow: ${({ theme }) => theme.input.shadow};
  ${({ disabled }) => disabled &&
    css `
      -webkit-text-fill-color: ${({ theme }) => theme.input.disabledTextColor};
      color: ${({ theme }) => theme.input.disabledTextColor};
      background-color: ${({ theme }) => theme.input.disabledBackground};
      cursor: ${({ theme }) => theme.input.disabledCursor};
    `}
`;
const Values = styled.div `
  padding: ${({ theme }) => rem(theme.select.padding)} 0;
  margin-right: auto;
  text-overflow: ellipsis;
  overflow: hidden;
  display: flex;
  ${({ isMultiple }) => isMultiple &&
    css `
      flex-wrap: wrap;
    `}
`;
const StyledInput = styled(AutosizeInput) `
  border: none;
  outline: none;
  font-size: ${({ theme }) => rem(theme.input.fontSize)};
  background-color: ${({ theme }) => theme.input.backgroundColor};
`;
const ActionItem = css `
  padding: 0 ${rem(4)};
  min-width: ${rem(24)};
  :last-child {
    margin-right: ${rem(-4)};
  }
`;
const InputLoader = styled(Loader) `
  ${ActionItem};
`;
const IconBtn = styled('button') `
  border: none;
  background: none;
  cursor: pointer;
  padding: 0;
  font-size: 0;
  ${ActionItem};
  ${({ disabled }) => disabled &&
    css `
      cursor: ${({ theme }) => theme.input.disabledCursor};
    `}
`;
const SingleValue = styled('div') `
  display: inline-flex;
  align-items: center;
  font-size: ${({ theme }) => rem(theme.input.fontSize)};
  min-width: 0;
`;
const TriangleBtn = withTheme((props) => {
    const { disabled, theme } = props;
    return (React.createElement(IconBtn, Object.assign({ type: "button" }, props, { disabled: !!disabled }),
        React.createElement(Icon, { name: "triangle-down", color: disabled ? theme.input.disabledTextColor : theme.textColor })));
});
const SelectInput = (_a) => {
    var { value, onChange, multiple, inputValue, onChangeInput, loading, focused, disabled, inputEl, isTouchDevice, error, placeholder, clearable, tags = [], onChangeTags = () => { } } = _a, restProps = __rest(_a, ["value", "onChange", "multiple", "inputValue", "onChangeInput", "loading", "focused", "disabled", "inputEl", "isTouchDevice", "error", "placeholder", "clearable", "tags", "onChangeTags"]);
    const noValue = Array.isArray(value) ? value.length === 0 : !value;
    const input = disabled ? null : (React.createElement(StyledInput, Object.assign({}, restProps, { "data-testid": "TheSelect-input", value: inputValue, onChange: onChangeInput, placeholder: noValue ? placeholder : '', inputRef: inputEl, autoComplete: "off" })));
    return (React.createElement(Wrapper, { "data-testid": "TheSelect-InputWrapper", disabled: !!disabled, focused: focused, readOnly: focused, error: !!error, multiple: !!multiple, onMouseDown: (e) => {
            e.preventDefault();
            if (!focused && inputEl && inputEl.current) {
                inputEl.current.focus();
            }
        } },
        React.createElement(Values, { isMultiple: !!multiple },
            tags.map(tag => (React.createElement(InputValue, { key: tag.value, value: tag, disabled: !!disabled, onRemove: disabled || isTouchDevice
                    ? undefined
                    : () => {
                        onChangeTags(tags.filter(t => t.value !== tag.value));
                    } }))),
            !multiple && input,
            Array.isArray(value)
                ? value.map(val => (React.createElement(InputValue, { key: val.value === null ? '$null' : val.value, value: val, disabled: !!disabled, onRemove: disabled || isTouchDevice
                        ? undefined
                        : () => {
                            onChange(value.filter(v => v.value !== val.value));
                        } })))
                : !inputValue &&
                    value && (React.createElement(SingleValue, null,
                    React.createElement(Label, { label: value.label, icon: value.icon, labelSize: 16 }))),
            multiple && input),
        clearable && !disabled && !noValue && (React.createElement(IconBtn, { "data-testid": "TheSelect-IconRemove", onMouseDown: (e) => {
                e.stopPropagation();
            }, onClick: (e) => {
                e.preventDefault();
                e.stopPropagation();
                onChange(multiple ? [] : null);
                onChangeInput('');
            } },
            React.createElement(Icon, { name: "close" }))),
        loading ? (React.createElement(InputLoader, null)) : (React.createElement(TriangleBtn, { disabled: !!disabled, tabIndex: -1 }))));
};
export default SelectInput;
