import { __rest } from "tslib";
import { rem } from 'polished';
import * as React from 'react';
import getProp from '../utilities/getProp';
import FormGroupContext from '../FormGroup/FormGroupContext';
import { getBorderFromProps } from '../Input/utils';
import styled from '../Themes/styled-components';
const Select = styled('select') `
  width: 100%;
  height: ${({ theme }) => rem(theme.input.height)};
  border-radius: ${({ theme }) => rem(theme.input.borderRadius)};
  font-size: ${({ theme }) => rem(theme.input.fontSize)};
  padding: 0 ${({ theme }) => rem(theme.input.horizontalPadding)};
  color: ${({ theme }) => theme.textColor};
  font-family: ${({ theme }) => theme.fontFamily};
  outline: 0;
  background-color: ${({ theme }) => theme.input.backgroundColor};
  box-shadow: ${({ theme }) => theme.input.shadow};
  border: ${props => getBorderFromProps(props)};
  &::placeholder {
    color: ${({ theme }) => theme.form.placeholderColor};
  }
  &:read-only {
    cursor: ${({ theme }) => theme.input.readOnlyCursor};
  }
  &:disabled {
    -webkit-text-fill-color: ${({ theme }) => theme.input.disabledTextColor};
    background-color: ${({ theme }) => theme.input.disabledBackground};
    cursor: ${({ theme }) => theme.input.disabledCursor};
  }
  &:focus {
    border: ${props => getBorderFromProps(Object.assign(Object.assign({}, props), { focused: true }))};
  }

  padding-right: ${rem(21)};
`;
const Wrapper = styled.div `
  position: relative;
`;
class CompSelect extends React.PureComponent {
    constructor(props) {
        super(props);
        this.iterateOptions = (options, value) => {
            let exists = false;
            if (Array.isArray(options)) {
                exists = options.some(option => {
                    return this.iterateOptions(option, value) === true;
                });
            }
            else if (getProp(options, ['props', 'value']) === value) {
                exists = true;
            }
            return exists;
        };
        this.renderOptions = () => {
            const { children, value, createUnknownOption } = this.props;
            if (!createUnknownOption || !value) {
                return children;
            }
            let option = null;
            const exists = this.iterateOptions(children, value);
            if (!exists) {
                option = React.createElement("option", { value: value }, value);
            }
            return [option, ...children];
        };
        console.warn('Select is DEPRECATED. Use TheSelect instead.');
    }
    render() {
        const _a = this.props, { className, children } = _a, restProps = __rest(_a, ["className", "children"]);
        return (React.createElement(FormGroupContext.Consumer, null, value => (React.createElement(Wrapper, { className: className },
            React.createElement(Select, Object.assign({}, restProps, { ref: this.props.inputRef, id: this.props.id || value.id }), this.renderOptions())))));
    }
}
CompSelect.defaultProps = {
    className: '',
    id: null,
    inputRef: () => { },
    createUnknownOption: true,
};
export default CompSelect;
