import * as React from 'react';
import Media from 'react-media';
import EventStack from '../EventStack';
import FormGroupContext from '../FormGroup/FormGroupContext';
import styled from '../Themes/styled-components';
import { isBrowser } from '../utilities';
import Input from './Input';
import Options, { CREATE_OPTION_VALUE } from './Options';
import OptionsModal from './OptionsModal';
import { containsExactMatch, defaultFilterOptions, getDefaultCreateOption, } from './utils';
const ContainerWrapper = styled.div `
  position: relative;
  width: 100%;
`;
export default function (props) {
    const inputEl = React.useRef(null);
    const [isTouchDevice, setIsTouchDevice] = React.useState(false);
    const [isModalOpened, setIsModalOpened] = React.useState(false);
    const [inputValue, setInputValue] = React.useState('');
    const [focused, setFocused] = React.useState(false);
    const [focusIdx, setFocusIdx] = React.useState(-1);
    const formGroupContext = React.useContext(FormGroupContext);
    const { options = [], disabled, onFocus, onBlur, multiple, value = multiple ? [] : null, onChange, createNewOption, filterOptions = defaultFilterOptions, loading, onChangeInput, error, placeholder, renderLabel, clearable = true, tags, onChangeTags, } = props;
    React.useEffect(() => {
        if (isTouchDevice && focused) {
            setIsModalOpened(true);
        }
    }, [focused, isTouchDevice]);
    const filteredOptions = React.useMemo(() => {
        return filterOptions === null
            ? options
            : filterOptions(options, inputValue);
    }, [options, inputValue, filterOptions]);
    const allOptions = React.useMemo(() => {
        return createNewOption &&
            inputValue &&
            !containsExactMatch(filteredOptions, inputValue)
            ? [...filteredOptions, getDefaultCreateOption(inputValue)]
            : filteredOptions;
    }, [filteredOptions, inputValue, createNewOption]);
    React.useEffect(() => {
        if (focused) {
            if (!Array.isArray(value) && value) {
                setFocusIdx(allOptions.findIndex(o => o.value === value.value));
            }
        }
    }, [focused, allOptions, value]);
    // handle disabled change (close options if select is disabled)
    React.useEffect(() => {
        if (disabled) {
            setFocused(false);
        }
    }, [disabled]);
    const noop = React.useCallback(() => { }, []);
    function handleBlur() {
        EventStack.remove(document.body, 'keydown', noop);
        if (onBlur) {
            onBlur(inputEl);
        }
        setInputValue('');
        setFocused(false);
    }
    function handleFocus(e) {
        EventStack.add(document.body, 'keydown', noop);
        if (isTouchDevice) {
            setIsModalOpened(true);
        }
        else {
            if (onFocus) {
                onFocus(e);
            }
            setFocused(true);
            setFocusIdx(0);
        }
    }
    function handleSelectOption(selectedOption) {
        let selected = selectedOption;
        if (createNewOption &&
            selectedOption &&
            selectedOption.value === CREATE_OPTION_VALUE) {
            selected = createNewOption(inputValue);
        }
        if (multiple) {
            if (Array.isArray(value)) {
                const idx = value.findIndex(val => {
                    return val.value === selected.value;
                });
                if (idx === -1) {
                    onChange([...value, selected]);
                }
                else {
                    onChange([...value.slice(0, idx), ...value.slice(idx + 1)]);
                }
            }
        }
        else {
            onChange(selected);
            setTimeout(() => {
                if (inputEl && inputEl.current) {
                    inputEl.current.blur();
                }
            });
        }
        setInputValue('');
    }
    function handleKeyDown(event) {
        if (event.key === 'ArrowDown') {
            event.preventDefault();
            setFocusIdx((focusIdx + 1) % allOptions.length);
        }
        else if (event.key === 'ArrowUp') {
            event.preventDefault();
            setFocusIdx((focusIdx + allOptions.length - 1) % allOptions.length);
        }
        else if (event.key === 'Enter') {
            event.preventDefault();
            handleSelectOption(allOptions[focusIdx]);
        }
        else if (event.key === 'Escape') {
            if (inputEl && inputEl.current) {
                inputEl.current.blur();
            }
        }
    }
    function handleChangeInput(value) {
        setInputValue(value);
        if (onChangeInput) {
            onChangeInput(value);
        }
    }
    return (React.createElement(React.Fragment, null,
        isBrowser() && (React.createElement(Media, { query: "(pointer: coarse)", onChange: matches => setIsTouchDevice(matches) })),
        React.createElement(ContainerWrapper, null,
            React.createElement(Input, { value: value, onChange: newValue => onChange(newValue), inputValue: inputValue, onChangeInput: handleChangeInput, onFocus: handleFocus, onBlur: handleBlur, disabled: disabled, onKeyDown: handleKeyDown, inputEl: inputEl, focused: focused, tags: tags, onChangeTags: onChangeTags, multiple: multiple, loading: loading, isTouchDevice: isTouchDevice, error: error, id: formGroupContext.id, placeholder: placeholder, clearable: clearable }),
            focused && (React.createElement(Options, { createNewOption: createNewOption, inputValue: inputValue, loading: loading, options: filteredOptions, focusedIdx: focusIdx, onSelectOption: handleSelectOption, multiple: multiple, value: value, renderLabel: renderLabel }))),
        isTouchDevice && (React.createElement(OptionsModal, { options: filteredOptions, value: value, onSelectOption: handleSelectOption, onChange: onChange, opened: isModalOpened, multiple: multiple, inputValue: inputValue, onChangeInput: handleChangeInput, createNewOption: createNewOption, loading: loading, renderLabel: renderLabel, onClose: () => {
                setInputValue('');
                setIsModalOpened(false);
            } }))));
}
