import { __rest } from "tslib";
import { rem } from 'polished';
import * as React from 'react';
import styled from '../Themes/styled-components';
import { mb } from '../Themes/utilities';
export const Row = styled('div') `
  display: flex;
  flex-wrap: wrap;
  margin: 0 ${({ theme }) => rem(-theme.grid.gutterWidth / 2)};
`;
const Column = styled('div') `
  padding: 0 ${({ theme }) => rem(theme.grid.gutterWidth / 2)};
  width: ${props => props.xs}%;
  ${mb('s')} {
    width: ${props => props.s}%;
  }
  ${mb('m')} {
    width: ${props => props.m}%;
  }
  ${mb('l')} {
    width: ${props => props.l}%;
  }
  ${mb('xl')} {
    width: ${props => props.xl}%;
  }
`;
const NUMBER_OF_COLUMNS = 12;
const C = React.memo((props) => {
    const { children, xs = NUMBER_OF_COLUMNS } = props, restProps = __rest(props, ["children", "xs"]);
    function getAvailableSize(sizeName) {
        const sizesInOrder = ['xs', 's', 'm', 'l', 'xl'];
        const currentIndex = sizesInOrder.findIndex(item => {
            return item === sizeName;
        });
        let availableSize = null;
        for (let i = currentIndex; i >= 0; i--) {
            if (props[sizesInOrder[i]]) {
                availableSize = props[sizesInOrder[i]];
                break;
            }
        }
        return (100 / NUMBER_OF_COLUMNS) * availableSize;
    }
    return (React.createElement(Column, Object.assign({}, restProps, { xs: (100 / NUMBER_OF_COLUMNS) * xs, s: getAvailableSize('s'), m: getAvailableSize('m'), l: getAvailableSize('l'), xl: getAvailableSize('xl') }), children));
});
C.defaultProps = {
    xs: NUMBER_OF_COLUMNS,
};
export default C;
