import { __rest } from "tslib";
import * as PropTypes from 'prop-types';
import * as React from 'react';
import removeAccents from '../utilities/removeAccents';
import getProp from '../utilities/getProp';
import InputAutocomplete from '../InputAutocomplete';
import InputAutocompleteRedux from '../InputAutocomplete/redux-form';
class AutocompleteSelect extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {};
        this.setValue = (props = this.props) => {
            const { items, name, value } = props;
            if (value) {
                const item = items.find((i) => i.value === value);
                if (item) {
                    this.setState({
                        autocomplete: Object.assign(Object.assign({}, this.state.autocomplete), { [name]: item.label }),
                    });
                }
                else {
                    this.setState({
                        autocomplete: Object.assign(Object.assign({}, this.state.autocomplete), { [name]: value }),
                    });
                }
            }
            else {
                this.setState({
                    autocomplete: Object.assign(Object.assign({}, this.state.autocomplete), { [name]: value }),
                });
            }
        };
        console.warn('AutocompleteSelect is DEPRECATED. Use TheSelect instead.');
    }
    componentDidMount() {
        this.setValue();
    }
    /**
     * Soon to be deprecated in favor of TheSelect
     */
    UNSAFE_componentWillReceiveProps(nextProps, nextState) {
        if (this.props.value !== nextProps.value ||
            this.props.items.length !== nextProps.items.length ||
            !getProp(nextState, ['autocomplete', nextProps.name])) {
            this.setValue(nextProps);
        }
    }
    render() {
        const _a = this.props, { name, disabled, items, onChange } = _a, restProps = __rest(_a, ["name", "disabled", "items", "onChange"]);
        const Component = this.props.isRedux
            ? InputAutocompleteRedux
            : InputAutocomplete;
        return (React.createElement(Component, Object.assign({}, restProps, { name: name, value: getProp(this.state, ['autocomplete', name], ''), disabled: disabled, submitAllowed: false, onSelectItem: (item) => {
                this.setState({
                    autocomplete: Object.assign(Object.assign({}, this.state.autocomplete), { [name]: item.label }),
                }, () => {
                    onChange(item.value);
                });
            }, onChange: (value) => {
                this.setState({
                    autocomplete: Object.assign(Object.assign({}, this.state.autocomplete), { [name]: value }),
                });
            }, items: items.reduce((acc, i) => {
                const value = getProp(this.state, ['autocomplete', name], '');
                const regex = new RegExp(removeAccents(value));
                if (!value || removeAccents(i.label).match(regex)) {
                    return [
                        ...acc,
                        {
                            label: i.label,
                            value: i.value,
                        },
                    ];
                }
                return acc;
            }, []), getItemLabel: (e) => e.label })));
    }
}
AutocompleteSelect.propTypes = {
    onChange: PropTypes.func,
    name: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
            .isRequired,
        value: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
            PropTypes.bool,
        ]).isRequired,
    })).isRequired,
    disabled: PropTypes.bool,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
    ]),
    isRedux: PropTypes.bool,
};
AutocompleteSelect.defaultProps = {
    disabled: false,
    value: null,
    onChange: () => { },
    isRedux: false,
};
export default AutocompleteSelect;
